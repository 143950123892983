import { Box, Button, Flex, Spacer, Spinner, Text, useDisclosure } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { PaymentElement } from '@stripe/react-stripe-js'
import { useQuery } from '@tanstack/react-query'
import { Alert, CheckMarkIcon, useWandaToast } from '@wanda-space/noelle'
import { usePaymentElement } from 'hooks/usePaymentElement'
import { AuthenticatedNavMenu } from 'layout/AuthenticatedNav/AuthenticatedNavMenu'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { serializeError } from 'utils/errors'

import { InvoiceBox } from './InvoiceBox'
import { PaymentError } from './PaymentError'
import { PaymentMethod } from './PaymentMethod'
import { type PaymentDetailsProps, PaymentType } from './types'

export const PaymentDetails = ({
  error,
  onSuccess,
  buttonLoading,
  disableButton,
  disableOfferInvoiceOption = false,
  options,
  getPaymentIntentConfirmationDetails,
  beforePayment,
  paymentButtonLabel,
}: PaymentDetailsProps) => {
  const [isAddingCard, setIsAddingCard] = useState(false)
  const [selectedPaymentType, setselectedPaymentType] = useState<PaymentType | undefined>(
    disableOfferInvoiceOption ? PaymentType.PAYMENT_METHOD : undefined
  )
  const { formatMessage } = useIntl()
  const {
    paymentError,
    paymentMethodsResult,
    paymentLoading,
    handleConfirmPayment,
    defaultPaymentMethod,
    hasPaymentSucceded,
    intentId,
    handleConfirmPaymentByPaymentMethod,
  } = usePaymentElement({
    options,
    setupPaymentIntent: getPaymentIntentConfirmationDetails,
  })
  const toast = useWandaToast()
  const showPaymentElementsDisclosure = useDisclosure()
  const { isInitialLoading: isSucessCallBackLoading } = useQuery(
    ['payment-success', intentId],
    () => onSuccess(intentId),
    {
      enabled: hasPaymentSucceded && Boolean(intentId),
    }
  )

  const emptyPaymentMethods =
    paymentMethodsResult.isSuccess && paymentMethodsResult.data.length === 0

  const offerInvoiceOption = !disableOfferInvoiceOption && emptyPaymentMethods

  const cardSelected = selectedPaymentType === PaymentType.PAYMENT_METHOD

  if (disableOfferInvoiceOption && emptyPaymentMethods && !showPaymentElementsDisclosure.isOpen) {
    showPaymentElementsDisclosure.onOpen()
  }

  if (!selectedPaymentType && paymentMethodsResult.isSuccess && !emptyPaymentMethods) {
    setselectedPaymentType(PaymentType.PAYMENT_METHOD)
  }

  const handlePaymentComplete = async () => {
    try {
      setIsAddingCard(true)
      if (!selectedPaymentType) {
        throw new Error('Payment Type is not selected')
      }

      if (beforePayment) {
        beforePayment()
      }

      const shouldSkipCardSetup =
        selectedPaymentType === PaymentType.INVOICE ||
        (selectedPaymentType === PaymentType.PAYMENT_METHOD &&
          defaultPaymentMethod?.id &&
          !showPaymentElementsDisclosure.isOpen)

      if (shouldSkipCardSetup && options.mode === 'setup') {
        return await onSuccess()
      } else if (shouldSkipCardSetup && options.mode === 'payment' && defaultPaymentMethod?.id) {
        return await handleConfirmPaymentByPaymentMethod(defaultPaymentMethod.id)
      }
      await handleConfirmPayment()
    } catch (error) {
      toast({
        variant: 'subtle',
        status: 'error',
        description: formatMessage({
          id: serializeError(error).localizationKey,
        }),
      })
      captureException(error)
    } finally {
      setIsAddingCard(false)
    }
  }

  const getPaymentButtonLabelBySelectedPaymentType = () => {
    return selectedPaymentType === PaymentType.INVOICE
      ? formatMessage({ id: 'word.pay.invoice' })
      : formatMessage({ id: 'word.pay.card' })
  }

  return (
    <>
      {paymentMethodsResult.isLoading ? (
        <Spinner />
      ) : (
        <>
          <Text m="2" fontSize={'lg'} fontWeight={'medium'}>
            {formatMessage({ id: 'payment.information.header' })}
          </Text>
          <Box>
            <Button
              disabled={paymentLoading || isSucessCallBackLoading}
              as={Box}
              data-testid="payment-option"
              p="6"
              border="1px solid"
              borderColor={`${cardSelected ? 'purple.600' : 'gray.200'}`}
              borderTopRadius="12"
              borderBottomRadius={`${!offerInvoiceOption ? '12' : '0'}`}
              justifyContent="left"
              onClick={(event) => {
                event.stopPropagation()
                showPaymentElementsDisclosure.onOpen()
                setselectedPaymentType(PaymentType.PAYMENT_METHOD)
              }}
              height="auto"
              width="100%"
              whiteSpace="normal"
              backgroundColor={`${cardSelected ? 'purple.50' : 'white'}`}
              _hover={{
                background: cardSelected ? 'purple.100' : 'gray.50',
              }}
              _selected={{
                background: cardSelected ? 'purple.200' : 'gray.50',
              }}
            >
              <Flex width="100%" justifyItems="flex-start">
                <Flex flexDirection="column" width="100%">
                  <Flex justifyContent="space-between" alignItems="center" mb={2}>
                    <Text fontSize="xl" fontWeight="500">
                      {formatMessage({
                        id: 'payment.choose.paymentMethod.title',
                      })}
                    </Text>
                    <CheckMarkIcon
                      textAlign="end"
                      color={cardSelected ? 'purple.700' : 'gray.200'}
                      width="6"
                      height="6"
                    />
                  </Flex>
                  {defaultPaymentMethod && !showPaymentElementsDisclosure.isOpen ? (
                    <PaymentMethod paymentMethod={defaultPaymentMethod} />
                  ) : null}
                  {showPaymentElementsDisclosure.isOpen ? (
                    <>
                      <Text mt="2" mb="4" fontSize={'lg'}>
                        {formatMessage({
                          id: 'payment.choose.attachment.info',
                        })}
                      </Text>
                      <PaymentElement
                        options={{
                          fields: {
                            billingDetails: {
                              address: {
                                country: 'never',
                                postalCode: 'never',
                              },
                            },
                          },
                          layout: { type: 'tabs', defaultCollapsed: false },
                        }}
                      />
                    </>
                  ) : (
                    <Text
                      data-testid="add-payment-method"
                      fontSize="lg"
                      fontWeight={400}
                      lineHeight="6"
                      textDecoration="underline"
                      tabIndex={0}
                      // biome-ignore lint/a11y/useSemanticElements: <Chakra...>
                      role="button"
                      mt={2}
                    >
                      {formatMessage({
                        id: defaultPaymentMethod
                          ? 'payment.change.paymentMethod'
                          : 'payment.add.paymentMethod',
                      })}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Button>
            {offerInvoiceOption && (
              <InvoiceBox
                selected={selectedPaymentType}
                onSelect={(value) => {
                  showPaymentElementsDisclosure.onClose()
                  setselectedPaymentType(value)
                }}
              />
            )}
          </Box>

          <Flex mt="5" display="block">
            {error && (
              <Box mb="4">
                <Alert
                  id="error-above-cta"
                  dismissible={false}
                  status="error"
                  text={formatMessage({ id: error })}
                />
              </Box>
            )}
            {paymentError ? <PaymentError error={paymentError} /> : null}
            <Spacer />
            <Button
              data-testid="continue-button"
              backgroundColor={'purple.400'}
              opacity={!selectedPaymentType || disableButton ? '40%' : '100%'}
              isDisabled={!selectedPaymentType || disableButton}
              size="lg"
              onClick={handlePaymentComplete}
              width="100%"
              isLoading={isAddingCard || buttonLoading || paymentMethodsResult.isLoading}
            >
              {paymentButtonLabel
                ? paymentButtonLabel
                : getPaymentButtonLabelBySelectedPaymentType()}
            </Button>
            <Text my="4" textAlign="center" color="gray.500">
              {formatMessage({ id: 'safe.payment.with.stripe' })}
            </Text>
          </Flex>
        </>
      )}
    </>
  )
}
