import { SupportedCountries } from '@wanda-space/noelle'
import { OrderType, ServiceLevelType } from '@wanda-space/types'
import { SelectTransportOrWarehouseVisitBase } from 'components/SelectTransportOrWarehouseVisitBase'
import { useContactInfoData } from 'components/contact-info/hooks'
import { useAppSelector } from 'hooks/useAppSelector'
import type { Stepable } from 'interfaces'
import React from 'react'
import { useAppDispatch } from 'reduxStore'
import {
  currentUserAddressSelector,
  setOrderType,
  setServiceLevelType as setServiceLevel,
  updateAddress,
} from 'reduxStore/ducks/returnFlow/reducers'

export function SelectTransportOrWarehouseVisit({ onNextStep }: Stepable) {
  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.user.user)
  const orderType = useAppSelector((state) => state.returnFlow.orderType)
  const orderAddress = useAppSelector(currentUserAddressSelector)
  const { contactInfo } = useContactInfoData(orderAddress)

  return (
    <SelectTransportOrWarehouseVisitBase
      selectedOrderType={orderType}
      taasOrderType={OrderType.DELIVERY}
      warehouseVisitOrderType={OrderType.CUSTOMER_COLLECTS}
      onSelect={({ orderType, serviceLevel, warehouse }) => {
        dispatch(setOrderType(orderType))
        dispatch(setServiceLevel(serviceLevel))
        if (warehouse) {
          dispatch(
            updateAddress({
              extraDeliveryInfo: '',
              firstName: contactInfo?.firstName || '',
              lastName: contactInfo?.lastName || '',
              email: contactInfo?.email || user?.email || '',
              phoneNumber: contactInfo?.phoneNumber || '',
              contactLess: undefined,
              shortStorage: false,
              ...warehouse.address,
              countryCode: warehouse.address.countryCode as SupportedCountries,
            })
          )
        } else {
          dispatch(
            updateAddress({
              extraDeliveryInfo: '',
              firstName: '',
              lastName: '',
              email: '',
              phoneNumber: '',
              contactLess: undefined,
              shortStorage: false,
              city: '',
              postalCode: '',
              street: '',
              countryCode: SupportedCountries.NO,
            })
          )
        }
      }}
      onNextStep={onNextStep}
    />
  )
}
