import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SliceNames } from 'reduxStore/constants'

export interface BidFlowState {
  amount: number
}

export const initialState: BidFlowState = {
  amount: 0,
}

export const bidFlowSlice = createSlice({
  name: SliceNames.BID_FLOW,
  initialState: initialState,
  reducers: {
    setBidAmount: (state, action: PayloadAction<number>) => {
      state.amount = action.payload
    },
    resetAll() {
      return initialState
    },
  },
})

export const { setBidAmount, resetAll } = bidFlowSlice.actions
export const bidFlow = bidFlowSlice.reducer
