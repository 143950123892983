import { FieldNames } from 'components/contact-info/types'
import { getEnv } from 'env'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useAuth } from './useAuth'

export const useHideFields = (hideAddressFields = false): FieldNames[] => {
  const { isAuthenticated } = useAuth()
  const [searchParams] = useSearchParams()
  const passwordless = searchParams.has('passwordless')

  return useMemo(() => {
    const fields: FieldNames[] = []
    if (isAuthenticated) {
      fields.push(FieldNames.EMAIL)
    } else {
      if (getEnv('APP_ENVIRONMENT') === 'prd') {
        fields.push(FieldNames.PHONE_NUMBER)
      } else {
        if (passwordless) {
          fields.push(FieldNames.PHONE_NUMBER)
        } else {
          fields.push(FieldNames.EMAIL)
        }
      }
    }
    if (hideAddressFields) {
      fields.push(
        FieldNames.STREET,
        FieldNames.POSTCODE,
        FieldNames.CITY,
        FieldNames.FLOOR_NUMBER,
        FieldNames.ELEVATOR,
        FieldNames.ADDRESS_COMMENT
      )
    }
    return fields
  }, [isAuthenticated, hideAddressFields, passwordless])
}
