import { Box, Flex, Heading } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import {
  DynamicIcon,
  type DynamicIconName,
  ItemWrapper,
  SelectCard,
  Spinner,
} from '@wanda-space/noelle'
import { ListingAddonType } from '@wanda-space/types'
import type { ListingAddonServiceOrderlinesRequest } from '@wanda-space/types'
import { useAppSelector } from 'hooks/useAppSelector'
import { useListingAddonServiceOrderlines } from 'hooks/useListingAddonServiceOrderlines'
import { useEffect, useMemo } from 'react'
import React from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'reduxStore'
import {
  getListingFromStorageState,
  getListingWithPickupFromState,
} from 'reduxStore/ducks/sell/selectors'
import type { ListingForPickup, ListingForStorage } from 'reduxStore/ducks/sell/sellFlow'
import { pickupActions, storageActions } from 'reduxStore/ducks/sell/sellFlow'
import { sanitizeStripeAmount } from 'utils'

interface SelectListingAddonsBaseProps {
  isPickup?: boolean
  /** If true, display only option to select photo option */
  onlyPhoto?: boolean
}

/*
import React from 'react'
import React from 'react'
*/

/**
 * For reuse in both Pickup and Storage flows, and for both create listing steps (picture addon option)
 * and listing addons step.
 */
export const SelectListingAddonsBase = ({
  isPickup,
  onlyPhoto = false,
}: SelectListingAddonsBaseProps) => {
  const dispatch = useAppDispatch()
  const relevantActions = isPickup ? pickupActions : storageActions
  const listingDetails: ListingForPickup | ListingForStorage = (() => {
    if (isPickup) {
      return useAppSelector(getListingWithPickupFromState) as ListingForPickup
    } else {
      return useAppSelector(getListingFromStorageState) as ListingForStorage
    }
  })()

  /**
   * Since the redux store's `storageItems` differs between the two types of listing flows,
   * and neither is compatible with the required structure for `storageItems` in
   * ListingAddonServiceOrderlinesRequest, we need to normalize the data structure first.
   */
  const storageItems: ListingAddonServiceOrderlinesRequest['storageItems'] = useMemo(() => {
    if (isPickup) {
      return (listingDetails as ListingForPickup).storageItems.ids.map((id) => {
        const entity = (listingDetails as ListingForPickup).storageItems.entities[id]
        if (entity?.storageProduct.id && entity?.name) {
          return {
            id: entity.storageProduct.id,
            type: entity.type,
            name: entity.name,
            OpsType: entity.storageProduct.metadata.opsType,
          }
        }
        const error = new Error('Missing required fields in storage items (ListingForPickup)')
        captureException(error)
        throw error
      })
    } else {
      return (listingDetails as ListingForStorage).storageItems.map((sItem) => {
        if (sItem.id && sItem.name) {
          return {
            id: sItem.id,
            type: sItem.type,
            name: sItem.name,
            OpsType: sItem.opsType,
          }
        }
        const error = new Error('Missing required fields in storage items (ListingForStorage)')
        captureException(error)
        throw error
      })
    }
  }, [listingDetails, isPickup])

  const listingOL = useListingAddonServiceOrderlines(storageItems)

  const relevantAddonOLs = useMemo(() => {
    return listingOL.listingAddonOrderlinesNormalized || undefined
  }, [listingOL.listingAddonOrderlinesNormalized, listingOL.isSuccess, isPickup])

  useEffect(() => {
    if (relevantAddonOLs) {
      dispatch(relevantActions.setListingInitialInfo(relevantAddonOLs))
    }
  }, [relevantAddonOLs])

  const { formatMessage } = useIntl()

  if (listingOL.isLoading) {
    return <Spinner />
  }

  if (listingOL.isError) {
    captureException(listingOL.error)
    throw listingOL.error
  }

  if (!relevantAddonOLs || !listingOL.isSuccess) {
    // For debugging / developing. This is not supposed to happen (expect isSucess and data when !isLoading).
    const error = new Error('SelectListing > Missing data in listingOL')
    captureException(error)
    throw error
  }

  /*
  // Keep for debugging / developing
  console.groupCollapsed(
    'SelectListingAddonsBase (%s%s), total: ',
    isPickup ? 'Pickup' : 'Storage',
    onlyPhoto ? ', onlyPhoto' : '',
    listingDetails.uiState.selectedAddons_totalPrice
  )
  console.log('listingDetails', listingDetails)
  console.log('storageItems', storageItems)
  console.log('listingAddonOrderlines', listingOL.listingAddonOrderlinesNormalized)
  console.table(listingDetails.uiState.selectedAddons)
  console.groupEnd()
  */

  // Helper function to get the price of a product, taking into account any discount
  const getPrice = (type: ListingAddonType): number =>
    relevantAddonOLs.pricesByListingAddonType[type] || 0

  const onChoiceClick = (type: ListingAddonType, isSelected: boolean) => {
    const price = getPrice(type)
    // Whether the user has made a selection for this addon before (radio logic with no default)
    const isFirstClick4addon = listingDetails.uiState.selectedAddons[type] === undefined
    // Diff in for total price if the user selects or deselects the addon
    const priceChange = isSelected ? price : isFirstClick4addon ? 0 : -price
    dispatch(relevantActions.setListingAddonSelected({ type, isSelected, priceChange }))
  }

  if (onlyPhoto) {
    const photoAddonProd =
      (relevantAddonOLs.count &&
        relevantAddonOLs.byListingAddonType[ListingAddonType.STUDIO_PHOTO]) ||
      null

    const isChecked = !!listingDetails.uiState.selectedAddons[ListingAddonType.STUDIO_PHOTO]

    return (
      <>
        {photoAddonProd && (
          <ItemWrapper
            contentProps={{
              name: formatMessage({ id: `${photoAddonProd.localizationKey}.name` }),
              description: formatMessage({
                id: `${photoAddonProd.localizationKey}.description_short`,
              }),
              tag: `${photoAddonProd.currency.toUpperCase()} ${sanitizeStripeAmount(
                getPrice(ListingAddonType.STUDIO_PHOTO)
              )}`,
            }}
            img={<DynamicIcon iconName={photoAddonProd.iconName as DynamicIconName} />}
            onClick={() => onChoiceClick(ListingAddonType.STUDIO_PHOTO, !isChecked)}
            withCheckbox
            isChecked={isChecked}
          />
        )}
      </>
    )
  }

  return (
    <>
      {relevantAddonOLs.count &&
        relevantAddonOLs.asSortedArray.map((addonProduct) => {
          const listingAddonType = addonProduct.metadata.listingAddonType as ListingAddonType
          const price2use = getPrice(listingAddonType)

          return (
            <Flex direction="column" rowGap={3} key={addonProduct.id}>
              <Heading as="h3" noOfLines={1} fontSize="large" fontWeight="medium" mt={2}>
                <Box as="span" flex="1" textAlign="left">
                  {addonProduct?.iconName && (
                    <DynamicIcon iconName={addonProduct.iconName as DynamicIconName} mr={2} />
                  )}
                  {formatMessage({ id: `${addonProduct.localizationKey}.name` })}
                </Box>
              </Heading>

              <SelectCard
                badges={[
                  {
                    bg: 'purple.200',
                    title: `${addonProduct.currency.toUpperCase()} ${sanitizeStripeAmount(
                      price2use
                    )}`,
                  },
                ]}
                key={`${addonProduct.id}_yes`}
                active={listingDetails.uiState.selectedAddons[listingAddonType] === true}
                description={formatMessage({
                  id: `${addonProduct.localizationKey}.yes.description`,
                })}
                onClick={() =>
                  !listingDetails.uiState.selectedAddons[listingAddonType] &&
                  onChoiceClick(listingAddonType, true)
                }
                title={formatMessage({ id: 'word.yes_please' })}
                showCheckMark
                data-test-type="addon-yes"
              />

              <SelectCard
                mb={6}
                key={`${addonProduct.id}_no`}
                active={listingDetails.uiState.selectedAddons[listingAddonType] === false}
                description={formatMessage({
                  id: `${addonProduct.localizationKey}.no.description`,
                })}
                onClick={() =>
                  (listingDetails.uiState.selectedAddons[listingAddonType] === undefined ||
                    listingDetails.uiState.selectedAddons[listingAddonType]) &&
                  onChoiceClick(listingAddonType, false)
                }
                title={formatMessage({ id: 'word.no_thanks' })}
                showCheckMark
                data-test-type="addon-no"
              />
            </Flex>
          )
        })}
    </>
  )
}
