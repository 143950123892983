import type { FlowStep, NextCallback } from 'interfaces'
import { isNil } from 'ramda'
import React from 'react'
import { useLocation } from 'react-router'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import { PrivateRoute } from './RouteGuards/PrivateRoute'

interface Proptypes {
  steps: FlowStep[]
  onNextStep: NextCallback
  nextStep: FlowStep
  currentStep: FlowStep
  flowRootPath: string
  fallbackElement?: React.ReactNode | null
}

export const FlowStepRoutes = ({
  steps,
  onNextStep,
  nextStep,
  flowRootPath,
  currentStep,
  fallbackElement,
}: Proptypes) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  return (
    <Routes>
      {steps.map(({ element: Element, path, requireLogin, nested, elementProps }) => {
        const props = {
          onNextStep,
          nextPath: nextStep ? `${flowRootPath}/${nextStep.path}` : undefined,
          ...elementProps,
        }

        return (
          <React.Fragment key={path}>
            {requireLogin === true ? (
              <Route element={<PrivateRoute />}>
                <Route path={path} element={<Element {...props} />} />
              </Route>
            ) : (
              <Route path={path} element={<Element {...props} />} />
            )}
            {nested && (requireLogin === true || nested?.requireLogin === true) ? (
              <Route element={<PrivateRoute />}>
                <Route
                  path={nested.path}
                  element={
                    <nested.element onNextStep={() => navigate(currentStep.path + search)} />
                  }
                />
              </Route>
            ) : null}
            {nested && (isNil(requireLogin) || requireLogin === false) ? (
              <Route
                path={nested.path}
                element={<nested.element onNextStep={() => navigate(currentStep.path + search)} />}
              />
            ) : null}
          </React.Fragment>
        )
      })}
      <Route
        key={`${flowRootPath}-fallback`}
        element={
          fallbackElement ? fallbackElement : <Navigate to={steps[0].path + search} replace />
        }
        path="*"
      />
    </Routes>
  )
}
