import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import {
  ChevronRightIcon,
  type DynamicIconName,
  EmptyStateBox,
  ItemWrapper,
} from '@wanda-space/noelle'
import serviceStars from '@wanda-space/noelle/illustrations/service-stars.svg'
import React, { useRef } from 'react'
import { useIntl } from 'react-intl'

import type { ItemResponseDto } from '@wanda-space/types'
import type { OrderLineWithFullProductAndDiscount, Product } from 'api-client'
import { ItemImage } from '../../../components/Item/Image/ItemImage'
import { sanitizeStripeAmount } from '../../../utils'
export interface ServiceOrdersBasketProps {
  isOpen: boolean
  onClose: () => void
  onEditClick: (index: number) => void
  serviceOrderLines: OrderLineWithFullProductAndDiscount[]
  storageItemIdStorageProductIndex: Record<string, Product | ItemResponseDto['storageProduct']>
}

export const ServiceOrdersBasket = ({
  isOpen,
  onClose,
  onEditClick,
  serviceOrderLines,
  storageItemIdStorageProductIndex,
}: ServiceOrdersBasketProps) => {
  const btnRef = useRef(null)
  const { formatMessage } = useIntl()
  return (
    <Modal onClose={onClose} finalFocusRef={btnRef} isOpen={isOpen} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{formatMessage({ id: 'phrase.service.reviewYourOrder' })}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <Flex direction="column" rowGap={4}>
            {serviceOrderLines.map((sol, index) => {
              const storageProduct = sol.item?.id
                ? storageItemIdStorageProductIndex[sol.item.id]
                : undefined
              return (
                <ItemWrapper
                  key={index}
                  img={<ItemImage iconName={storageProduct?.iconName as DynamicIconName} />}
                  moreActionButton={<ChevronRightIcon w="6" h="6" />}
                  onClick={() => onEditClick(index)}
                  contentProps={{
                    name: sol.item!.name!,
                    description: sol.comment,
                    tag: `${sol.product.currency.toUpperCase()} ${sanitizeStripeAmount(
                      sol.product.price
                    )}`,
                  }}
                />
              )
            })}
            {serviceOrderLines.length === 0 && (
              <EmptyStateBox
                mb={4}
                icon={<img src={serviceStars} alt="" />}
                text={<Text>{formatMessage({ id: 'service.orders.emptyState.title' })}</Text>}
              />
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
