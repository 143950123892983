import { Flex, FormControl, FormLabel, Skeleton, useToken } from '@chakra-ui/react'
import React, { type ReactNode } from 'react'
import { useIntl } from 'react-intl'

export const AddressSkeleton = () => {
  const { formatMessage } = useIntl()
  const [gray200] = useToken('colors', ['gray.200'])
  const InputSkeleton = ({ children }: { children?: ReactNode }) => (
    <Skeleton
      borderRadius="md"
      height="10"
      startColor="white"
      color="gray.400"
      display="flex"
      p="5"
      alignItems="center"
      endColor="gray.200"
      speed={0.3}
      boxShadow={`inset 0 0 0px 1px ${gray200}`}
    >
      {children}
    </Skeleton>
  )

  return (
    <>
      <FormControl mb="4">
        <FormLabel>{formatMessage({ id: 'address.lookup.label' })}</FormLabel>
        <InputSkeleton>{formatMessage({ id: 'address.lookup.placeholder' })}</InputSkeleton>
      </FormControl>
      <FormControl mb="4">
        <FormLabel>{formatMessage({ id: 'word.street' })}</FormLabel>
        <InputSkeleton />
      </FormControl>
      <Flex mb="4" gap="4">
        <FormControl>
          <FormLabel>{formatMessage({ id: 'word.zip' })}</FormLabel>
          <InputSkeleton />
        </FormControl>
        <FormControl>
          <FormLabel>{formatMessage({ id: 'word.city' })}</FormLabel>
          <InputSkeleton />
        </FormControl>
      </Flex>
    </>
  )
}
