import type { ItemResponseDto } from '@wanda-space/types'
import { fetchItems } from 'api-client'
import type { Dispatch } from 'react'
import type { RootState } from 'reduxStore/index'

export enum Actions {
  GET_ITEMS = 'GET_ITEMS',
  SET_ITEMS = 'SET_ITEMS',
  SET_ITEMS_ERROR = 'SET_ITEMS_ERROR',
  CLEAR_SELECTED_ITEMS = 'CLEAR_SELECTED_ITEMS',
  CLEAR_ITEMS = 'CLEAR_ITEMS',
  TOGGLE_SELECTED_ITEMS = 'TOGGLE_SELECTED_ITEMS',
  TOGGLE_SELECTED_ADD_ON_ITEMS = 'TOGGLE_SELECTED_ADD_ON_ITEMS',
  REMOVE_ITEM = 'REMOVE_ITEM',
  ADD_ITEM = 'ADD_ITEM',
  SET_ITEM = 'SET_ITEM',
  PATCH_ITEM = 'PATCH_ITEM',
}

export type Action =
  | { type: Actions.GET_ITEMS }
  | { type: Actions.SET_ITEMS_ERROR; error: string }
  | { type: Actions.SET_ITEMS; payload: ItemResponseDto[] }
  | { type: Actions.SET_ITEM; payload: ItemResponseDto | undefined }
  | { type: Actions.TOGGLE_SELECTED_ADD_ON_ITEMS; payload: string[] }
  | { type: Actions.CLEAR_SELECTED_ITEMS }
  | { type: Actions.CLEAR_ITEMS }
  | { type: Actions.TOGGLE_SELECTED_ITEMS; payload: string[] }
  | { type: Actions.REMOVE_ITEM; payload: string }
  | { type: Actions.ADD_ITEM; payload: ItemResponseDto }
  | { type: Actions.PATCH_ITEM; payload: string[] }

export const addItem = (item: ItemResponseDto) => (dispatch: Dispatch<Action>) =>
  dispatch({ type: Actions.ADD_ITEM, payload: item })

export const setItems = (items: ItemResponseDto[]) => () => {
  return { type: Actions.SET_ITEMS, payload: items }
}

export const getItems = (page = 0, itemsPerPage = 1000) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({ type: Actions.GET_ITEMS })
      const data = await fetchItems({
        page,
        itemsPerPage,
      })

      dispatch({
        type: Actions.SET_ITEMS,
        payload: data,
      })
    } catch (error: unknown) {
      dispatch({ type: Actions.SET_ITEMS_ERROR, error: `${error}` })
    }
  }
}

export const removeItem = (id: string) => (dispatch: Dispatch<Action>) =>
  dispatch({ type: Actions.REMOVE_ITEM, payload: id })

export const toggleSelectedItems =
  (...ids: string[]) =>
  (dispatch: Dispatch<Action>) =>
    dispatch({ type: Actions.TOGGLE_SELECTED_ITEMS, payload: ids })

export const toggleSelectedAddOnItems =
  (...ids: string[]) =>
  (dispatch: Dispatch<Action>) =>
    dispatch({ type: Actions.TOGGLE_SELECTED_ADD_ON_ITEMS, payload: ids })

export const clearSelectedItems = () => (dispatch: Dispatch<Action>) =>
  dispatch({ type: Actions.CLEAR_SELECTED_ITEMS })

export const clearItems = () => (dispatch: Dispatch<Action>) =>
  dispatch({ type: Actions.CLEAR_ITEMS })

export const getItem = (id: string) => {
  return (dispatch: Dispatch<Action>, getState: () => { item: RootState['item'] }) => {
    const { storedItems } = getState().item

    try {
      const oneItem = storedItems.filter((item: { id: string }) => item.id === id).pop()

      dispatch({
        type: Actions.SET_ITEM,
        payload: oneItem,
      })
    } catch (error) {
      dispatch({
        type: Actions.SET_ITEM,
        payload: undefined,
      })
    }
  }
}
