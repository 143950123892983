import { captureException } from '@sentry/react'
import type { OrderLinePayload, OrderLineWithFullProductAndDiscount } from 'api-client/lib/types'
import { SelectCardSkeleton } from 'components/SelectCardSkeleton'
import { SelectPacking } from 'components/SelectPacking'
import { useAppSelector } from 'hooks/useAppSelector'
import { usePacking } from 'hooks/usePacking'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import type { Stepable } from 'interfaces'
import { omit } from 'ramda'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'reduxStore'
import { itemPayloadSelectors, setPacking } from 'reduxStore/ducks/storage/storageFlow'

export const Packing = ({ onNextStep }: Stepable) => {
  const items = useAppSelector(itemPayloadSelectors.selectAll)
  const itemPayload = items.map((item) => ({
    ...omit(['storageProduct'], item),
    productId: item.storageProduct.id,
  }))
  const products = useProductsAndCategories().addOnProducts
  const packingOrderlines = useAppSelector((state) => state.storageFlow.orderLines.packing)
  const [isPacking, setIsPacking] = useState(!!packingOrderlines.length)
  const dispatch = useAppDispatch()

  const { formatMessage } = useIntl()

  const { packingQuote, packingPrice, packingLoading } = usePacking({
    items: itemPayload,
  })

  const mapToOrderline = (orderLines: OrderLinePayload[]) => {
    const list: OrderLineWithFullProductAndDiscount[] = []
    orderLines.forEach((i) => {
      const prod = products?.find((j) => j.id === i.productId)
      if (prod) {
        list.push({
          item: i.item as OrderLineWithFullProductAndDiscount['item'],
          product: prod,
          quantity: i.quantity,
        })
      }
    })

    return list
  }

  const handleClickOnNextStep = () => {
    onNextStep()
  }

  const handlePackingChange = (packing: boolean) => {
    setIsPacking(packing)
    if (packing) {
      if (!packingQuote) {
        throw new Error('Missing packing packingQuote')
      }
      dispatch(setPacking(mapToOrderline(packingQuote.orderlines)))
    } else {
      dispatch(setPacking([]))
    }
  }

  useEffect(() => {
    if (packingQuote && !packingLoading) {
      setIsPacking(true)
      dispatch(setPacking(mapToOrderline(packingQuote.orderlines)))
    }
  }, [packingQuote, packingLoading])

  if (packingLoading)
    return (
      <>
        <SelectCardSkeleton />
        <SelectCardSkeleton />
        <SelectCardSkeleton />
      </>
    )

  if (items.every(({ storageProduct }) => !storageProduct.metadata.packing)) {
    onNextStep({ replace: true })
    return null
  }

  if (!packingQuote) {
    captureException(new Error('Missing packing packingQuote'))
    onNextStep()
    return null
  }

  return (
    <SelectPacking
      onSubmit={() => {
        handleClickOnNextStep()
      }}
      isPacking={isPacking}
      onPackingChange={handlePackingChange}
      isLoading={packingLoading}
      packingOrderlines={packingQuote.orderlines}
      packingPrice={packingPrice || '0'}
      buttonText={formatMessage({ id: 'word.continue' })}
    />
  )
}
