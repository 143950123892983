import { useQuery } from '@tanstack/react-query'
import type { ItemResponseDto } from '@wanda-space/types'
import { fetchItems } from 'api-client/lib/routes/items'
import type { User } from 'interfaces/user'

export const reactQueryKeyFetchItems = 'fetchItems'

export function useItems(user?: User, options?: { enabled?: boolean }) {
  return useQuery<ItemResponseDto[]>(
    [reactQueryKeyFetchItems, user],
    () => fetchItems({ page: 0, itemsPerPage: 2000 }),
    options
  )
}
