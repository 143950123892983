import type { Address, Warehouse } from '@wanda-space/ops-types'
import type { SupportedCities } from '@wanda-space/types'
import { request } from '../api-client'

import { opsBaseUrl as baseUrl } from '../config'

export type WarehouseWithAddress = Warehouse & { address: Address }

export function getWarehouseForVisit({ serviceArea }: { serviceArea: SupportedCities }) {
  return request<WarehouseWithAddress>({
    method: 'GET',
    url: '/v1/warehouses/visit/',
    baseUrl,
    skipAuth: true,
    params: { serviceArea },
  })
}
