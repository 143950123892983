import type { EntityState } from '@reduxjs/toolkit'
import type { UseQueryResult } from '@tanstack/react-query'
import type {
  ListingAddonType,
  ListingBidResponseDto,
  ListingResponseDto,
  ListingStorageItemResponseDto,
} from '@wanda-space/types'
// @ts-ignore
import { CreateListingRequestBodyDto } from '@wanda-space/types'
import type { ListingServiceFees } from 'api-client'
import type { ItemPayloadWithProduct } from 'reduxStore/commonMappers'
import type {
  ListingForPickup,
  ListingForStorage,
  ListingFormValues,
} from 'reduxStore/ducks/sell/sellFlow'
import type { ItemPayloadWithProductAndListingData } from 'routes/BuySellFlow/common/ListingPreview'
import type { ListingImageReference } from 'routes/BuySellFlow/common/hooks'
import { listingToPrice } from 'utils'
import type { PriceWrapper } from './price'

export enum ListingBuyType {
  STORAGE = 'STORAGE',
  DELIVERY = 'DELIVERY',
}

export interface BuyListingRequest {
  id: string
}

export interface AddonServiceCompletionStatus {
  listingAddonType?: ListingAddonType
  isComplete: boolean
  localizationKey: string
}

/**
 * Representing a listing for preview purposes.
 *
 * This is a remnant of older code, which we haven't had time to refactor yet
 * (there are way too many types and interfaces relating to listings, a normalization / consolidation would be good).
 *
 * ### Other relevant types and interfaces:
 * - {@link CreateListingRequestBodyDto}
 * - {@link ListingForPickup}
 * - {@link ListingForStorage}
 * - {@link ListingResponseDto}
 * - ....
 */
export interface ListingForPreview
  extends Omit<ListingFormValues, 'images'>,
    Partial<Omit<ListingResponseDto, 'storageItems' | keyof ListingFormValues>> {
  storageItems:
    | ListingResponseDto['storageItems']
    | ItemPayloadWithProductAndListingData[]
    | ListingStorageItemResponseDto
    | EntityState<ItemPayloadWithProduct>
  inspectedByWanda?: boolean
  addons: ListingAddonType[]
  price: PriceWrapper
  coupon?: string
  description?: string
  images?: string[]
  /** To display the status of selected addons, even for preview */
  addonServicesCompletionStatus: AddonServiceCompletionStatus[]
}

/**
 * This is a rather ugly hack to convert a `ListingResponseDto` object to a `ListingForPreview` object.
 * Should be removed as soon as we have had time to refactor the different types and interfaces related to listings.
 * @see {@link ListingResponseDto}
 * @see {@link ListingForPreview}
 */
export const listingResponseDto2ListingForPreview = (
  input: ListingResponseDto
): ListingForPreview => {
  const EDI = input.extraDescriptiveInfo ?? undefined
  const EDI4ListingForPreview = {
    brand: EDI?.brand ?? '',
    model: EDI?.model ?? '',
    material: EDI?.material ?? '',
    year: EDI?.year ?? '',
    colour: EDI?.colour ?? '',
    comment: EDI?.comment ?? '',
  }

  const addons: ListingAddonType[] = []

  const addonServicesCompletionStatus = (input.services || []).map((service) => {
    const ret = {
      isComplete: service.isComplete,
      localizationKey: service.product.localizationKey,
    } as AddonServiceCompletionStatus
    const addonType = service.product.metadata?.listingAddonType as ListingAddonType
    if (addonType) {
      addons.push(addonType)
      ret.listingAddonType = addonType
    }
    return ret
  })

  return {
    ...input,
    storageItems: [],
    addons,
    price: listingToPrice(input),
    coupon: undefined,
    description: input.description ?? undefined,
    dimensionHeight: input.dimensionHeight ?? undefined,
    dimensionWidth: input.dimensionWidth ?? undefined,
    dimensionLength: input.dimensionLength ?? undefined,
    dimensionFreeText: input.dimensionFreeText ?? undefined,
    extraDescriptiveInfo: EDI4ListingForPreview,
    addonServicesCompletionStatus,
  }
}

/**
 * A conditional type that determines the type of `storageItems` based on the boolean parameter `T`.
 *
 * @template T - A boolean type parameter.
 * @typeParam T - If `T` is `true`, the type will be `ListingForPickup['storageItems']`.
 *               If `T` is `false`, the type will be `ListingForStorage['storageItems']`.
 */
export type StorageItemsType<T extends boolean> = T extends true
  ? ListingForPickup['storageItems']
  : ListingForStorage['storageItems']

/**
 * Represents a listing for preview with a conditional type for storage items.
 *
 * @template T - A boolean that determines the type of `storageItems` (true for pickup, false for storage).
 */
export type ListingForPreviewType<T extends boolean> = Omit<ListingForPreview, 'storageItems'> & {
  storageItems: StorageItemsType<T>
}

interface ListingPreviewBaseInterface {
  handleBuy?: () => void
  handleBid?: () => void
}

export interface ListingPublished extends ListingPreviewBaseInterface {
  simpleId: string
  listing?: never
  selectedItems?: never
  listingConfig?: never
  listingDetails?: never
  biddingDetails?: never
}

export interface ListingPublishedWithDetails extends ListingPreviewBaseInterface {
  simpleId?: never
  listingDetails: {
    listingResult: UseQueryResult<ListingResponseDto>
    listingItemsResult: UseQueryResult<ListingStorageItemResponseDto>
    listingImages: UseQueryResult<ListingImageReference[]>
  }
  listing?: never
  selectedItems?: never
  listingConfig?: never
  biddingDetails?: ListingBidResponseDto
}

export interface ListingNotPublished extends ListingPreviewBaseInterface {
  simpleId?: never
  listing: ListingForPreview
  onListingLoaded?: never
  selectedItems: ItemPayloadWithProductAndListingData[]
  listingConfig: ListingServiceFees
  lastEdited?: never
  listingDetails?: never
  biddingDetails?: never
}
