import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { CloseIcon } from '@wanda-space/noelle'
import type { ApiError } from 'api-client/lib/api-client'
import { FormLabelMaxChars } from 'components/Forms/FormLabelMaxChars'
import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'

export interface Props {
  initialValue: string
  onClose: () => void
  onSubmit: (value: string) => Promise<void>
  isOpen: boolean
}

export const ChangeItemNameModal = ({ initialValue, onClose, onSubmit, isOpen }: Props) => {
  const { formatMessage } = useIntl()
  const [value, setValue] = useState<string>(initialValue)
  const ref = useRef<HTMLDivElement>(null)
  const updateNameMutation = useMutation(['updateItemName'], async (value: string) =>
    onSubmit(value)
  )

  return (
    <div ref={ref}>
      <Modal isOpen={isOpen} onClose={onClose} portalProps={{ containerRef: ref }}>
        <ModalOverlay />
        <ModalContent width="85%" borderRadius="3xl">
          <ModalHeader borderRadius={8}>
            <Text>{formatMessage({ id: 'item.change.name.title' })}</Text>
            <IconButton
              aria-label="close button"
              icon={<CloseIcon w="4" h="4" />}
              onClick={onClose}
              backgroundColor="purple.200"
              size="sm"
              position="absolute"
              top="2"
              right="2"
            />
          </ModalHeader>
          <ModalBody>
            <Box>
              <FormControl isInvalid={updateNameMutation.isError}>
                <Text mb={4}>{formatMessage({ id: 'item.change.name.description' })}</Text>
                <Textarea
                  isInvalid={value.length > 45}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
                <FormErrorMessage ml="2">
                  {formatMessage({
                    id:
                      (updateNameMutation.error as ApiError)?.localizationKey() ?? 'error.general',
                  })}
                </FormErrorMessage>
              </FormControl>
              <FormLabelMaxChars maxCharacters={45} />

              <ModalFooter pl="unset" pr="unset">
                <Button
                  isDisabled={value.length > 45}
                  data-testid="submit-item-button"
                  type="submit"
                  colorScheme="purple"
                  width="100%"
                  borderRadius="lg"
                  onClick={() => updateNameMutation.mutate(value)}
                >
                  {formatMessage({ id: 'word.update' })}
                </Button>
              </ModalFooter>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}
