import type {
  PaymentIntentResult,
  StripeCardNumberElement,
  StripeElementsOptions,
  StripeError,
} from '@stripe/stripe-js'
import type { UseQueryResult } from '@tanstack/react-query'
import type { SetupIntentResponseDto } from '@wanda-space/types'
import type { CustomerPaymentMethod } from 'api-client'
import type {
  AuthenticationError,
  UnknownPaymentError,
} from 'components/PaymentOptions/PaymentError'

export type HookInput = {
  onSuccess?: (paymentMethodId?: string) => Promise<void>
  getPaymentIntentConfirmationDetails?: () => Promise<SetupIntentResponseDto>
}

export type UsePayment = (input: HookInput) => {
  paymentLoading: boolean
  paymentError: StripeError | UnknownPaymentError | AuthenticationError | undefined
  handleOneTimePayment: () => Promise<PaymentIntentResult | undefined>
  paymentMethodsResult: UseQueryResult<CustomerPaymentMethod[]>
  defaultPaymentMethod?: CustomerPaymentMethod
  addNewPaymentMethod: (card: StripeCardNumberElement) => Promise<string | undefined>
  isPaymentReady: boolean
}

export interface PaymentContentProps {
  error: string | undefined
  onSuccess: (paymentMethodId?: string | undefined) => Promise<void>
  buttonLoading: boolean
  disableButton: boolean
  disableOfferInvoiceOption?: boolean
  getPaymentIntentConfirmationDetails?: () => Promise<SetupIntentResponseDto>
  options?: StripeElementsOptions
  beforePayment?: () => void
  paymentButtonLabel?: string
}

export type PaymentWrapperProps = Omit<PaymentContentProps, 'paymentMethods'> & {
  buttonLoading?: boolean
  disableButton?: boolean
}

export enum PaymentType {
  INVOICE = 'INVOICE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
}

export interface PaymentDetailsProps extends Omit<PaymentContentProps, 'options'> {
  options: StripeElementsOptions
}
