import React from 'react'

import { useIntl } from 'react-intl'

import type { Stepable } from 'interfaces'

import { DynamicIconName, FlowDisclaimer as WandaFlowDisclaimer } from '@wanda-space/noelle'

import { Link, Text } from '@chakra-ui/react'
import { linkFormatter } from 'utils/linkFormatter'

export const FlowDisclaimer = ({ onNextStep }: Stepable) => {
  const { formatMessage } = useIntl()
  const disclaimers = [
    {
      id: 'disclaimer.protect.box',
      icon: DynamicIconName.boxAcceptance,
    },
    {
      id: 'disclaimer.no.glass',
      icon: DynamicIconName.brokenGlass,
    },
    {
      id: 'disclaimer.no.food.or.drink',
      icon: DynamicIconName.burgerDrink,
    },
    {
      id: 'disclaimer.no.denied.item',
      icon: DynamicIconName.hexagonStop,
    },
    {
      id: 'disclaimer.no.heavy.item',
      icon: DynamicIconName.weightScale,
    },
  ]
  return (
    <WandaFlowDisclaimer
      onBtnClick={onNextStep}
      containerWidth="100%"
      minHeight="100%"
      title={formatMessage({ id: 'disclaimer.title' })}
      description={formatMessage({ id: 'disclaimer.description' })}
      bullets={disclaimers.map(({ id, icon }) => ({
        id,
        text: formatMessage({ id }),
        icon,
      }))}
      btnLabel={formatMessage({ id: 'phrase.accept' })}
      furtherInfo={
        <Link
          href={linkFormatter(formatMessage, { navigation: 'terms' })}
          isExternal
          textDecoration="underline"
        >
          {formatMessage({ id: 'phrase.know.more' })}
        </Link>
      }
    >
      <>
        <Text fontWeight="semibold">{formatMessage({ id: 'disclaimer.take.pictures' })}</Text>
        <Text>{formatMessage({ id: 'disclaimer.present.receipt.on.damage' })}</Text>
      </>
    </WandaFlowDisclaimer>
  )
}
