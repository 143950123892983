import type { PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js'
import type {
  ConnectAccountLinkResponseDto,
  ConnectAccountResponseDto,
  SetupIntentResponseDto,
  StripeSubscriptionDto,
} from '@wanda-space/types'

import { request } from '../api-client'
import type { CustomerPaymentMethod, InvoiceDto } from '../types'

export async function attachPaymentMethod(paymentMethodId: string): Promise<StripePaymentMethod> {
  return request({
    method: 'POST',
    url: `subscription/payment-method/${paymentMethodId}`,
    body: {},
  })
}

export async function getPaymentMethods(): Promise<CustomerPaymentMethod[]> {
  return request({
    method: 'GET',
    url: 'stripe/paymentMethod',
  })
}

export async function createSetupIntent(): Promise<SetupIntentResponseDto> {
  return request({
    method: 'POST',
    url: 'subscription/setup-intents',
    body: null,
  })
}

export async function fetchInvoices(): Promise<InvoiceDto[]> {
  return request({
    method: 'GET',
    url: 'subscription/invoices',
  })
}

export async function fetchSubscriptionTotal(): Promise<number> {
  return request({
    method: 'GET',
    url: 'subscription/total',
  })
}

export async function fetchSubscription() {
  return request<StripeSubscriptionDto>({
    method: 'GET',
    url: 'subscription',
  })
}

export async function fetchConnectAccount() {
  return request<ConnectAccountResponseDto>({
    method: 'GET',
    url: 'subscription/connect-account',
  })
}

export async function createConnectAccountLink() {
  return request<ConnectAccountLinkResponseDto>({
    method: 'POST',
    url: 'subscription/connect-account-link',
    body: {},
  })
}

export async function fetchExpressAccountLoginLink() {
  return request<{ link: string }>({
    method: 'GET',
    url: 'subscription/express-dashboard-link',
  })
}
