import { Box, Button, Center, Divider, Flex, HStack, Heading, Text, VStack } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  CheckMarkIcon,
  type DynamicIconName,
  EmptyStateFurnitureIllustration,
  FileShareIcon,
  ImagePreviewMode,
  ImageViewer,
  ItemWrapper,
  Notification,
  Spinner,
  StorageClockIcon,
} from '@wanda-space/noelle'
import {
  type ItemResponseDto,
  type ListingResponseDto,
  ListingStatus,
  type ListingStorageItemResponseDto,
  OrderContext,
  OrderType,
  UUID,
} from '@wanda-space/types'
import { downloadListingImages } from 'api-client'
import { ApiError } from 'api-client/lib/api-client'
import { getProductCategoriesByCountry } from 'api-client/lib/routes/product'
import { ItemImage } from 'components/Item/Image/ItemImage'
import { Routes } from 'consts'
import { useDateLocale } from 'contexts/Intl'
import { useActiveUserLocation } from 'hooks/useActiveUserLocation'
import { useAppSelector } from 'hooks/useAppSelector'
import { useTaas } from 'hooks/useTaas'
import {
  type ListingForPreview,
  type ListingNotPublished,
  type ListingPublished,
  type ListingPublishedWithDetails,
  listingResponseDto2ListingForPreview,
} from 'interfaces/listing'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link, Navigate } from 'react-router-dom'
import type { ItemPayloadWithProduct } from 'reduxStore/commonMappers'
import { getFormattedDuration, getListingItemImageUrl } from 'utils'
import { downloadFile } from 'utils/download'
import { getItemPayloadsFromItems, getListingItemsFromItem } from 'utils/item'
import { add, listingToPrice, sanitizeAmount } from 'utils/price-utils'
import { productToPrice } from 'utils/product-utils'
import { formatServiceArea } from 'utils/serviceArea'

import { BuyInformationBox } from '../Buy/BuyInformationBox'
import { useListingPreviewDetails } from './hooks'

export type ItemPayloadWithProductAndListingData = ItemPayloadWithProduct & {
  listingName: ListingResponseDto['name']
}

export const ListingPreview = ({
  simpleId,
  listing: listingFromProps,
  listingConfig,
  selectedItems,
  handleBuy,
  handleBid,
  listingDetails,
  biddingDetails,
}: ListingPublished | ListingNotPublished | ListingPublishedWithDetails) => {
  const { formatMessage, formatNumber } = useIntl()
  const locale = useDateLocale()

  const listingPreviewDetails = useListingPreviewDetails(simpleId || '', {
    enableCondition: !listingDetails && Boolean(simpleId),
  })

  let listing: ListingForPreview | undefined = undefined

  const downloadZippedListingImagesMutation = useMutation(
    async () => {
      const listingId = listing?.id
      if (listingId) {
        return downloadListingImages(listingId)
      }
    },
    {
      onSuccess: (zippedImages) => {
        if (zippedImages) {
          downloadFile(listing?.name ?? 'downloads', zippedImages, '.zip')
        }
      },
      onError: (error) => {
        captureException(error)
      },
    }
  )

  let listingItems: (ItemPayloadWithProduct | ListingStorageItemResponseDto[number])[] = []
  let images: string[]
  const isLoading =
    listingPreviewDetails.listingResult.isInitialLoading ||
    listingPreviewDetails.listingItemsResult.isInitialLoading
  let isImageLoading = true
  const isDownLoadable = simpleId || listingDetails?.listingResult.data?.simpleId

  if (simpleId) {
    const currentListing = listingPreviewDetails.listingResult.data
    if (currentListing) {
      listing = listingResponseDto2ListingForPreview(currentListing)
    }
    listingItems = listingPreviewDetails.listingItemsResult.data ?? []
    images = listingPreviewDetails.listingImages.data
      ? listingPreviewDetails.listingImages.data.map((imageRef) => imageRef.imageUrl)
      : []
    isImageLoading = listingPreviewDetails.listingImages.isInitialLoading
  } else if (listingFromProps) {
    const { images: listingImages = [], ...rest } = listingFromProps
    listing = rest
    listingItems = selectedItems.map(getListingItemsFromItem)
    images = listingImages
    isImageLoading = false
  } else if (listingDetails) {
    const currentListing = listingDetails.listingResult.data
    if (currentListing) {
      listing = listingResponseDto2ListingForPreview(currentListing)
    }
    listingItems = listingDetails.listingItemsResult.data ?? []
    images = listingDetails.listingImages.data
      ? listingDetails.listingImages.data.map((imageRef) => imageRef.imageUrl)
      : []
    isImageLoading = listingDetails.listingImages.isLoading
  } else {
    throw new Error('listing preview prop is missing')
  }

  const { country, serviceArea } = useActiveUserLocation()

  const { data: categories } = useQuery(
    ['product-categories', country],
    async () => getProductCategoriesByCountry(country, { itemsPerPage: 1000 }),
    { enabled: Boolean(listingItems.length) && !isLoading, suspense: true }
  )

  const itemCategories = [
    ...new Set(
      listingItems.flatMap(
        (item) =>
          categories?.items.find((category) => item.storageProduct.categoryId === category.id)
            ?.localizationKey ?? []
      )
    ),
  ]

  const categoriesInListing = new Set(itemCategories)

  const listingCountry = listing?.country ?? listingItems[0]?.storageProduct?.country ?? country

  const orderContextOverride = listingItems.every(({ orderContext }) => !orderContext)
    ? OrderContext.LISTING
    : undefined

  const isBuyableListing =
    listing && (listing.status === undefined || listing.status === ListingStatus.ACTIVE)

  const { curbSideTotalCost } = useTaas({
    storageItems: getItemPayloadsFromItems(listingItems, orderContextOverride),
    orderType: OrderType.DELIVERY,
    floorNumber: 0,
    enabledCondition: Boolean(listingItems.length > 0 && listingCountry && isBuyableListing),
    countryCode: listingCountry,
  })

  const curbsideFee = Number(curbSideTotalCost)

  const getItemCategory = (
    item: ListingStorageItemResponseDto[number] | ItemResponseDto | ItemPayloadWithProduct
  ) => categories?.items?.find((category) => category.id === item.storageProduct.categoryId)

  if (listingPreviewDetails.listingResult.error) {
    const error = listingPreviewDetails.listingItemsResult.error
    if (error && error instanceof ApiError && error.status() !== 404) {
      return <Navigate to={Routes.ListingNotFound} />
    }
  }

  if (!listing) {
    return <Spinner />
  }
  const storagePrice = add(
    ...listingItems.map(({ storageProduct }) => productToPrice(storageProduct))
  )

  const serviceFeePercent = listing?.buySideCut ?? listingConfig?.buySideCut
  const listingPrice = listing.price.amount ?? 0
  const serviceFee = serviceFeePercent && (serviceFeePercent / 100) * listingPrice

  const expiryTime = biddingDetails
    ? getFormattedDuration(new Date(), new Date(biddingDetails.expiryTime), locale)
    : undefined

  const inspectedByWanda = listing.inspectedByWanda || false

  return (
    <>
      {biddingDetails && listing.id ? (
        <Box mb={4}>
          <Notification
            id="bid-success-id"
            type="success"
            dismissible={false}
            wide
            text={
              <VStack as="span">
                <HStack as="span">
                  <CheckMarkIcon />
                  <Text as="span">{formatMessage({ id: 'phrase.bid.sent' })}</Text>
                </HStack>
                <Text as="span">
                  {formatMessage(
                    { id: 'phrase.bid.details.text' },
                    {
                      amount: sanitizeAmount(biddingDetails.amount),
                      currency: biddingDetails.currency,
                      name:
                        listing.ownerName?.firstName && listing.ownerName?.lastName
                          ? `${listing.ownerName.firstName} ${listing.ownerName.lastName}`
                          : undefined,
                      remainingTime: expiryTime,
                    }
                  )}
                </Text>
                <Button
                  type="button"
                  borderRadius="8"
                  variant="solid"
                  size="lg"
                  colorScheme="purple"
                  as={Link}
                  leftIcon={<StorageClockIcon w="5" h="5" />}
                  width="100%"
                  to={Routes.ViewBid(listing.id, biddingDetails.id)}
                >
                  {formatMessage({ id: 'goto.bid' })}
                </Button>
              </VStack>
            }
          />
        </Box>
      ) : null}
      <Box>
        {images.length > 0 && isImageLoading && <Spinner />}
        {images.length > 0 && !isImageLoading && (
          <Box>
            <ImageViewer
              imageUrls={images}
              previewMode={ImagePreviewMode.slider}
              src={images[0]}
              imageProps={{
                height: '400',
                width: 'auto',
                objectFit: 'contain',
              }}
            />
            {isDownLoadable && (
              <Box textAlign="center">
                <Button
                  size="sm"
                  mr="1"
                  as="a"
                  onClick={() => {
                    images.map(async (image, idx) => {
                      try {
                        const timeout = images.length * 3 // why timeout? Safari prevents multiple click events done in a short span of time so all files are not downloaded
                        const res = await fetch(image)
                        const blob = await res.blob()
                        setTimeout(() => {
                          if (listing?.name) {
                            downloadFile(listing.name, blob, `-${idx}`)
                          }
                        }, timeout * idx)
                      } catch (err) {
                        captureException(err)
                      }
                    })
                  }}
                >
                  {formatMessage({ id: 'word.download.all' })}
                  <FileShareIcon ml="2" w="4" h="4" transform="rotate(180deg)" />
                </Button>
                <Button
                  size="sm"
                  px="4"
                  isLoading={downloadZippedListingImagesMutation.isLoading}
                  onClick={() => downloadZippedListingImagesMutation.mutate()}
                >
                  {formatMessage({ id: 'word.download.all.zipped' })}
                  <FileShareIcon ml="2" w="4" h="4" transform="rotate(180deg)" />
                </Button>
              </Box>
            )}
          </Box>
        )}
        {images.length === 0 && (
          <Box position="relative">
            <Flex
              borderRadius="lg"
              border="1px solid"
              borderColor="purple.200"
              backgroundColor="purple.50"
              h={52}
              alignItems="center"
              justifyContent="center"
            >
              <EmptyStateFurnitureIllustration width="100%" />
            </Flex>
          </Box>
        )}
        <Box mt={4} mb={6}>
          <Text fontSize="3xl">{listing.name}</Text>
          {listing.description && (
            <Text fontSize="lg" opacity="65%" whiteSpace="pre-wrap">
              {listing.description}
            </Text>
          )}
        </Box>
      </Box>

      {isBuyableListing ? (
        <BuyInformationBox
          listingPrice={listingPrice}
          serviceFee={serviceFee}
          locale={locale}
          availableInServiceArea={listing.city ?? serviceArea}
          curbsideFee={curbsideFee}
          storagePrice={storagePrice}
          handleBuy={handleBuy}
          handleBid={handleBid}
          conditionType={listing.conditionType}
          dimensionLength={listing.dimensionLength}
          dimensionHeight={listing.dimensionHeight}
          dimensionWidth={listing.dimensionWidth}
          dimensionFreeText={listing.dimensionFreeText}
          extraDescriptiveInfo={listing.extraDescriptiveInfo}
          category={Array.from(categoriesInListing)}
          opsState={listing.opsState}
          inspectedByWanda={inspectedByWanda}
          addonServicesCompletionStatus={listing.addonServicesCompletionStatus}
        />
      ) : (
        <Box
          backgroundColor="brown.200"
          border="1px solid"
          borderColor="blackAlpha.200"
          p={6}
          fontSize="lg"
          borderRadius="2xl"
          width="100%"
        >
          {listing.status === ListingStatus.WAITING_PAYMENT ||
          listing.status === ListingStatus.SOLD ||
          listing.status === ListingStatus.INACTIVE ? (
            <Flex
              borderRadius="xl"
              py={4}
              mb={4}
              backgroundColor="brown.50"
              justifyContent="center"
            >
              {listing.status === ListingStatus.SOLD ||
              listing.status === ListingStatus.WAITING_PAYMENT ? (
                <Heading fontSize="xl">{formatMessage({ id: 'info.buy.sell.sold' })}</Heading>
              ) : (
                <Heading fontSize="xl">{formatMessage({ id: 'info.buy.sell.inactive' })}</Heading>
              )}
            </Flex>
          ) : null}
          <Flex justifyContent="space-between">
            <Box>
              <Text fontSize="sm" color="neutralGray.700" casing="uppercase">
                {formatMessage({ id: 'info.buy.sell.price' })}
              </Text>
              <Text as="h2" fontWeight="medium" color="neutralGray.800" fontSize="4xl">
                {formatNumber(listingPrice + (serviceFee ?? 0))},-
              </Text>
            </Box>
            <Center height="47px">
              <Divider orientation="vertical" />
            </Center>
            <Box>
              <Text fontSize="sm" color="neutralGray.700" casing="uppercase">
                {formatMessage({ id: 'info.buy.sell.city' })}
              </Text>
              <Text as="h2" fontWeight="medium" color="neutralGray.800" fontSize="medium">
                {formatServiceArea(listing.city ?? serviceArea)}
              </Text>
            </Box>
          </Flex>
        </Box>
      )}
      {listing.ownerName?.firstName && listing.ownerName?.lastName && (
        <Box my={6}>
          <Text fontWeight="medium">{formatMessage({ id: 'buy.listing.seller.name' })}</Text>
          <Text>{`${listing.ownerName?.firstName} ${listing.ownerName?.lastName}`}</Text>
        </Box>
      )}

      <Box>
        <Text fontWeight="medium" mt="4">
          {formatMessage({ id: 'info.buy.sell.items.for.sale' })}
        </Text>
        <Text color="neutralGray.800">
          {formatMessage({ id: 'info.buy.sell.items.for.sale.description' })}
        </Text>
        <Flex
          direction={['column', 'row']}
          flexWrap={['initial', 'wrap']}
          justifyContent="space-between"
          mb={2}
        >
          {listingItems.map((listingItem) => {
            const listingItemImageSrc =
              listingItem.image && UUID.safeParse(listingItem.image).success && listing?.id
                ? getListingItemImageUrl({
                    listingId: listing.id,
                    itemId: listingItem.id,
                    imageId: listingItem.image,
                  })
                : listingItem.image

            return (
              <ItemWrapper
                mx="-3"
                disableHover
                key={listingItem.id}
                contentProps={{
                  name: listingItem.name,
                }}
                img={
                  listing ? (
                    <ItemImage
                      src={listingItemImageSrc ?? undefined}
                      iconName={getItemCategory(listingItem)?.iconName as DynamicIconName}
                    />
                  ) : undefined
                }
              />
            )
          })}
        </Flex>
      </Box>
    </>
  )
}
