import { Box, Button, Spinner as ChakraSpinner, Flex, Image } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import {
  DynamicIcon,
  DynamicIconName,
  ImagePreviewMode,
  ImageViewer,
  TrashIcon,
} from '@wanda-space/noelle'
import type { ItemImageResponseDto, SupportedCountries } from '@wanda-space/types'
import { getImageDetailByImageId, getProductCategoriesByCountry } from 'api-client'
import React, { useMemo } from 'react'

interface Props {
  imageId?: string
  itemId?: string
  src: string
  onRemoveImage?: (img: string | undefined) => void
  spinnerSize?: 'small' | 'big'
  canRemove?: boolean
  imageUrls?: string[]
}

export const RemovableImage = ({
  imageId,
  src,
  onRemoveImage,
  canRemove = true,
  imageUrls = [],
}: Props) => {
  return (
    <>
      {src && (
        <Box position="relative">
          <ImageViewer
            src={src}
            imageUrls={imageUrls}
            previewMode={ImagePreviewMode.modal}
            imageInactiveProps={{
              borderRadius: 8,
            }}
          />
          {canRemove && onRemoveImage && (
            <Button
              type="button"
              onClick={() => onRemoveImage(imageId)}
              style={{
                padding: 'unset',
                height: '44px',
                width: '44px',
                position: 'absolute',
                bottom: '6px',
                right: '6px',
              }}
            >
              <TrashIcon />
            </Button>
          )}
        </Box>
      )}
    </>
  )
}

interface ItemImageCommonProps {
  inactive?: boolean
}

interface ItemImageByItemId extends ItemImageCommonProps {
  countryCode: SupportedCountries
  categoryId: string
}
interface ItemImageByImageId extends ItemImageCommonProps {
  itemId: string
  imageId: string
  getImageDetail?: (itemId: string, imageId: string) => Promise<any>
}

interface ItemImageByUrl extends ItemImageCommonProps {
  src: string
}

interface ItemImageByIcon extends ItemImageCommonProps {
  iconName?: DynamicIconName
}

export type ItemImageProps = Partial<
  ItemImageByImageId & ItemImageByUrl & ItemImageByIcon & ItemImageByItemId
>

function isPropsWithImageId(props: ItemImageProps): props is ItemImageByImageId {
  const values = props as ItemImageByImageId
  return Boolean(values.itemId && values.imageId)
}

function isPropsWithSource(props: ItemImageProps): props is ItemImageByUrl {
  return Boolean((props as ItemImageByUrl).src)
}

function isPropsWithCountryCode(props: ItemImageProps): props is ItemImageByItemId {
  const values = props as ItemImageByItemId
  return Boolean(values.countryCode && values.categoryId)
}

export const ItemImage = (props: ItemImageProps) => {
  const { inactive } = props
  const propsWithItemImageId = props as ItemImageByImageId
  const propsWithItemIdAndCountryCode = props as ItemImageByItemId

  const imageQuery = useQuery<ItemImageResponseDto>(
    ['fetchItemImage', propsWithItemImageId.itemId, propsWithItemImageId.imageId],
    () =>
      (propsWithItemImageId.getImageDetail ?? getImageDetailByImageId)(
        propsWithItemImageId.itemId,
        propsWithItemImageId.imageId
      ),
    { enabled: isPropsWithImageId(propsWithItemImageId) }
  )

  const categoryQuery = useQuery(
    ['fetchProductCategories', propsWithItemIdAndCountryCode.countryCode],
    () =>
      getProductCategoriesByCountry(propsWithItemIdAndCountryCode.countryCode, {
        itemsPerPage: 1000,
      }),
    { enabled: isPropsWithCountryCode(props) }
  )

  const category = useMemo(() => {
    return categoryQuery.data?.items.find(
      (category) => category.id === propsWithItemIdAndCountryCode.categoryId
    )
  }, [categoryQuery.data, propsWithItemIdAndCountryCode.categoryId])

  let finalSrc: string | undefined = undefined

  if (imageQuery.isInitialLoading || categoryQuery.isInitialLoading) {
    return <ChakraSpinner data-testid="spinner" />
  }

  if (isPropsWithSource(props)) {
    finalSrc = props.src
  } else if (isPropsWithImageId(props)) {
    finalSrc = imageQuery.data?.url
  }

  if (finalSrc) {
    return (
      <Image
        src={finalSrc}
        height="100%"
        width="100%"
        objectFit="cover"
        borderRadius="10px"
        outline="1px solid"
        outlineColor="ctaBlack.200"
        data-testid="image"
      />
    )
  }

  const { iconName = (category?.iconName ?? DynamicIconName.box) as DynamicIconName } =
    props as ItemImageByIcon

  return (
    <Flex
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      borderRadius="10px"
      backgroundColor={inactive ? 'gray.200' : 'purple.200'}
      data-testid="icon"
    >
      <DynamicIcon
        iconName={iconName}
        color={inactive ? 'gray.700' : 'purple.700'}
        height={7}
        width={7}
      />
    </Flex>
  )
}
