import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { SmallArrowUpIcon } from '@wanda-space/noelle'
import { useDocumentTitle } from 'hooks'
import type { NextCallback } from 'interfaces'
import React from 'react'
import { useIntl } from 'react-intl'

interface FlowIntroductionProps {
  onNextStep?: NextCallback
  pageTitleKey: string
  pageType: 'sell' | 'buy' | 'storage' | 'service' | 'square.meter'
  stepNumber: number
  nextBtnDataTestId?: string
  containerWidth?: string
  emojis: string[]
  titleKey: string
  subtitleKey?: string
  readMoreLink?: string
  bgColor?: string
  emojisContainerBgColor?: string
}

const FlowIntroduction = ({
  onNextStep,
  pageTitleKey,
  pageType,
  stepNumber,
  nextBtnDataTestId = 'intro-to-next-step',
  /* What is the purpose of containerWidth, when it wraps around another container that is set to 400px */
  containerWidth,
  emojis,
  titleKey,
  subtitleKey,
  readMoreLink,
  bgColor = 'purple.50',
  emojisContainerBgColor = 'purple.200',
}: FlowIntroductionProps) => {
  const { formatMessage } = useIntl()

  // This is a bit ugly, but this is the way to go with Chakra for assuring emojis not to overflow width...
  const gap = '4'
  const innerContainerWidth = '400px'
  const emojisContainerHeight = `calc((${innerContainerWidth} - ((${emojis.length} + 1) * var(--chakra-space-${gap}))) / ${emojis.length})`

  useDocumentTitle(formatMessage({ id: pageTitleKey }))

  return (
    <Container
      width={containerWidth || 'calc(100vw - 1.5rem)'}
      minHeight="calc(100vh - 5.3rem)"
      maxWidth="none"
      borderRadius={10}
      bg={bgColor}
    >
      <Container width={innerContainerWidth} maxWidth="100%">
        <Heading fontSize={20} textAlign="center" fontWeight="bold">
          {formatMessage({ id: titleKey })}
        </Heading>
        <Text my="2" textAlign="center">
          {formatMessage({ id: subtitleKey })}
        </Text>
        <Box h={emojisContainerHeight} my="7" width="100%" sx={{ 'container-type': 'size' }}>
          <Flex position="absolute" width="100%" left="0" justifyContent="center" gap={gap}>
            {emojis.map((e) => (
              <Flex
                flexShrink="0"
                fontSize="60cqh"
                justifyContent="center"
                alignItems="center"
                h="100cqh"
                w="100cqh"
                bg={emojisContainerBgColor}
                borderRadius="20"
                key={e}
              >
                {e}
              </Flex>
            ))}
          </Flex>
        </Box>
        <UnorderedList>
          {Array.from(Array(stepNumber), (_, i) => i + 1).map((step) => (
            <ListItem
              position="relative"
              paddingBottom="4"
              key={step}
              _after={{
                position: 'absolute',
                left: '-1.5rem',
                content: '"."',
                color: 'purple.600',
                top: '-0.6rem',
                fontSize: '5rem',
                lineHeight: 0,
              }}
              _notLast={{
                _before: {
                  position: 'absolute',
                  left: '-.95rem',
                  content: '""',
                  top: '1rem',
                  height: '100%',
                  borderLeft: '1px solid',
                  borderColor: 'gray.200',
                },
              }}
              listStyleType="none"
            >
              {formatMessage({ id: `${pageType}.introduction.step${step}` })}
            </ListItem>
          ))}
        </UnorderedList>
        {onNextStep && (
          <Button
            data-testid={nextBtnDataTestId}
            mt="4"
            size="lg"
            w="100%"
            colorScheme="purple"
            onClick={() => onNextStep()}
          >
            {formatMessage({ id: 'word.settIgang' })}
            <SmallArrowUpIcon ml="3" w="3" h="3" transform="rotate(90deg)" />
          </Button>
        )}
        {readMoreLink && (
          <Flex width="100%" my={4} justifyContent="center">
            <Link
              target="_blank"
              referrerPolicy="no-referrer"
              textDecoration="underline"
              href={readMoreLink}
            >
              {formatMessage({ id: 'word.iWantToKnowMore' })}
            </Link>
          </Flex>
        )}
      </Container>
    </Container>
  )
}

export { FlowIntroduction }
