import { Button, Container, Text } from '@chakra-ui/react'
import { useAppSelector } from 'hooks/useAppSelector'
import type { Stepable } from 'interfaces'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
  getListingFromStorageState,
  getListingWithPickupFromState,
} from 'reduxStore/ducks/sell/selectors'
import type { ListingForPickup, ListingForStorage } from 'reduxStore/ducks/sell/sellFlow'
import { SelectListingAddonsBase } from './SelectListingAddonsBase'

interface AddListingAddonsElementProps {
  isPickup?: boolean
}

export const AddListingAddons = ({
  onNextStep,
  isPickup,
}: Stepable & AddListingAddonsElementProps) => {
  const listingDetails: ListingForPickup | ListingForStorage = (() => {
    if (isPickup) {
      return useAppSelector(getListingWithPickupFromState) as ListingForPickup
    } else {
      return useAppSelector(getListingFromStorageState) as ListingForStorage
    }
  })()

  const { formatMessage } = useIntl()

  const handleGoToPreview = () => {
    onNextStep()
  }

  const isFullAddonSelectionMade = useMemo(() => {
    const addonsChoicesMade = Object.keys(listingDetails.uiState.selectedAddons).length
    return addonsChoicesMade > 0 && addonsChoicesMade === listingDetails.uiState.addonsAvailableNo
  }, [listingDetails.uiState.selectedAddons, listingDetails.uiState.addonsAvailableNo])

  return (
    <Container pt={0}>
      <SelectListingAddonsBase isPickup={isPickup} />

      {!isFullAddonSelectionMade && (
        <Text my="4" textAlign="center" color="gray.500">
          {formatMessage({ id: 'product.listing.addons_step.general_need_select_before_next' })}
        </Text>
      )}

      <Button
        mt={2}
        variant="solid"
        backgroundColor="purple.400"
        w="100%"
        onClick={handleGoToPreview}
        data-testid="continue-button"
        isDisabled={!isFullAddonSelectionMade}
      >
        <Text>{formatMessage({ id: 'action.listing.preview' })}</Text>
      </Button>
    </Container>
  )
}
