import {
  Box,
  Center,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  ArrowRightIcon,
  type Country,
  CountryMenuWithLokalise,
  HandPalmIcon,
} from '@wanda-space/noelle'
import type { SupportedCities, SupportedCountries } from '@wanda-space/types'
import { Routes } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import { usePostalCodes } from 'hooks/usePostalCodes'
import React, { type ChangeEventHandler, useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useIntl } from 'react-intl'
import {
  Link as ReactRouterLink,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useAppDispatch } from 'reduxStore'
import { setPostalCode as setPostalCodeRedux } from 'reduxStore/ducks/ui'
import { patchAddress } from 'reduxStore/ducks/user/user'

enum PostalCodeFormat {
  NO = '0000',
  SE = '00000',
}

interface Proptypes {
  onSubmit?: () => void
}

export const PostalCodeForm = ({ onSubmit }: Proptypes) => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const [params] = useSearchParams()
  const country = useAppSelector((state) => state.ui.country)
  const user = useAppSelector((state) => state.user.user)
  const ui = useAppSelector((state) => state.ui)
  const [selectedCountry, setSelectedCountry] = useState<SupportedCountries>(country)
  const { data: postalCodes, isLoading } = usePostalCodes({
    country: selectedCountry,
  })
  const [postalCode, setPostalCode] = useState<string>(
    ui.postalCode || params.get('postalCode') || ''
  )
  const [noServicePostalCode, setNoServiceAreaPostalCode] = useState<string | null>(null)
  const [disableSubmit, setDisableSubmit] = useState(true)

  useEffect(() => {
    if (isLoading) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(
        selectedCountry === 'SE'
          ? postalCode.split('').length !== 5
          : postalCode.split('').length !== 4
      )
    }
  }, [selectedCountry, postalCode, isLoading])

  const handleSubmit = async () => {
    TagManager.dataLayer({
      dataLayer: { event: 'postalCodeSearch', postalCodeSearch: postalCode },
    })
    if (postalCode) {
      const searchResult = postalCodes?.find(
        (p) => p.postalCode === postalCode && selectedCountry === p.country
      )
      if (searchResult) {
        dispatch(
          setPostalCodeRedux({
            postalCode: searchResult.postalCode,
            city: searchResult.city as SupportedCities,
            country: selectedCountry as SupportedCountries,
          })
        )
        if (user) {
          await dispatch(patchAddress({ postalCode: searchResult.postalCode })).unwrap()
        }

        if (onSubmit) {
          onSubmit()
        }
      } else {
        setNoServiceAreaPostalCode(postalCode)
      }
    }
  }

  const onCountrySelect = (country: Country) => {
    setSelectedCountry(country.countryCode)
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value
    const regex = /^\d*$/
    if (regex.test(value)) {
      setPostalCode(value)
    } else {
      event.preventDefault()
    }
  }

  const inputPadding = 2
  const menuButtonProps = { width: '100%' }
  const menuListProps = { zIndex: '11' }

  return (
    <Center>
      {noServicePostalCode === null ? (
        <div>
          <Center marginBottom={5}>
            <Heading textAlign="center" as="h5" fontWeight="medium" size="md">
              {formatMessage({ id: 'phrase.checkIfYouCanUseUs' })}
            </Heading>
          </Center>
          <Box width="90%" textAlign="center" color="gray.500" margin="auto" marginTop={5}>
            {formatMessage(
              { id: 'validate.postalcode.login.info' },
              {
                loginAnchor: (
                  <Link
                    textDecoration="underline"
                    as={ReactRouterLink}
                    to={{
                      pathname: Routes.Login,
                      search: `?${createSearchParams(params)}`,
                    }}
                  >
                    {formatMessage({ id: 'word.login' })}
                  </Link>
                ),
              }
            )}
          </Box>
          <Center py={2}>
            <VStack gap="2">
              <CountryMenuWithLokalise
                menuListProps={menuListProps}
                menuButtonProps={menuButtonProps}
                defaultValue={selectedCountry}
                onChange={onCountrySelect}
              />
              <InputGroup width="50" size="md">
                <Input
                  onKeyPress={(event) => {
                    if (event.key === 'Enter' && !disableSubmit) handleSubmit()
                  }}
                  p={inputPadding}
                  type="tel"
                  placeholder={PostalCodeFormat[selectedCountry]}
                  value={postalCode}
                  onChange={(event) => {
                    handleChange(event)
                  }}
                  boxSizing="unset"
                  fontSize="xl"
                  pattern="[0-9]*"
                  maxLength={selectedCountry === 'SE' ? 5 : 4}
                  data-testid="postal-code"
                  backgroundColor="gray.100"
                />
                <InputRightElement zIndex="1" right={inputPadding} top={inputPadding}>
                  <IconButton
                    isDisabled={disableSubmit}
                    size="lg"
                    borderRadius="md"
                    variant="solid"
                    colorScheme={disableSubmit ? 'neutralGray' : 'purple'}
                    aria-label={formatMessage({ id: 'word.submit' })}
                    onClick={handleSubmit}
                    data-testid="submit"
                  >
                    <ArrowRightIcon width="4" height="4" color="currentColor" />
                  </IconButton>
                </InputRightElement>
              </InputGroup>
            </VStack>
          </Center>
        </div>
      ) : (
        <div>
          <Center textAlign="center" flexDirection="column" marginBottom={5}>
            <Box mb="5">
              <HandPalmIcon width="3.3rem" height="3.3rem" />
            </Box>

            <Text fontWeight="medium" size="md">
              {formatMessage({ id: 'message.noServiceArea' }, { postCode: noServicePostalCode })}
            </Text>
            <Text mt="4" size="small" color="gray">
              {formatMessage(
                { id: 'phrase.selectOtherPostcode' },
                {
                  selectOtherPostcode: (
                    <Link
                      textDecoration="underline"
                      onClick={() => setNoServiceAreaPostalCode(null)}
                    >
                      {formatMessage({ id: 'word.selectOther' })}
                    </Link>
                  ),
                }
              )}
            </Text>
          </Center>
        </div>
      )}
    </Center>
  )
}
