import React from 'react'

import { Container } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { ProductTarget } from '@wanda-space/types'
import { useAppSelector } from 'hooks/useAppSelector'
import { useListingAddonServiceOrderlines } from 'hooks/useListingAddonServiceOrderlines'
import type { Stepable } from 'interfaces'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import { useAppDispatch } from 'reduxStore'
import { getListingForPreview } from 'reduxStore/ducks/sell/selectors'
import { storageActions } from 'reduxStore/ducks/sell/sellFlow'
import { type OneTimePaymentItem, PaymentStepBase } from 'routes/Common/PaymentStepBase'
import {
  add,
  byProductTarget,
  getDiscountFromPrice,
  getProductLocalizedName,
  orderlineToPrice,
} from 'utils'
import { serializeError } from 'utils/errors'
import { getRelatedOrderlinesByListingAddonType } from 'utils/listing'
import { createListingForStorageAndUploadImages } from './createListing'

export const PaymentAndSummary = ({ onNextStep, nextPath }: Stepable) => {
  const listingDetails = useAppSelector(getListingForPreview(false))
  const storageItems = listingDetails.storageItems
  const ui = useAppSelector((state) => state.ui)
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()

  const listingOrderlines = useListingAddonServiceOrderlines(storageItems, {
    enabled: true,
    couponCode: listingDetails.coupon,
  })

  const goToPublish = (id: string) => {
    dispatch(storageActions.resetAll())
    const nextPathWithSimpleId = nextPath && generatePath(nextPath, { simpleId: id })
    onNextStep({ nextPath: nextPathWithSimpleId, replace: true })
  }

  const listingCreationMutation = useMutation(
    ['createListingForStorage'],
    async () => createListingForStorageAndUploadImages(listingDetails, storageItems, dispatch),
    {
      onSuccess: (listing) => {
        goToPublish(listing.simpleId)
      },
    }
  )

  const relatedListingAddonOrderlines = getRelatedOrderlinesByListingAddonType(
    listingDetails.addons ?? [],
    listingOrderlines.listingAddonOrderlines
  )
  const totalListingAddonPrice = add(...relatedListingAddonOrderlines.map(orderlineToPrice))

  const oneTimeItems: OneTimePaymentItem[] = listingDetails.addons.flatMap((addon) => {
    const orderlines = getRelatedOrderlinesByListingAddonType(
      [addon],
      listingOrderlines.listingAddonOrderlines
    )
    const baseOrderline = orderlines.find(byProductTarget(ProductTarget.ORDER)) ?? orderlines[0]
    const totalPrice = add(...orderlines.map(orderlineToPrice))
    const totalDiscount = getDiscountFromPrice(totalPrice)

    return orderlines.length
      ? [
          {
            name: formatMessage({
              id: getProductLocalizedName(baseOrderline.product, formatMessage),
            }),
            price: totalPrice.amount,
            discount: totalDiscount?.amount,
            oldPrice: totalPrice.oldPrice,
          },
        ]
      : []
  })

  const isLoading = listingOrderlines.isLoading || listingCreationMutation.isLoading

  return (
    <Container>
      <PaymentStepBase
        error={serializeError(listingCreationMutation.error).localizationKey}
        hideYouPayNow
        onObosMembershipActivation={(discounts) => {}}
        country={ui.country}
        addCoupon={(discounts, coupon) =>
          dispatch(storageActions.applyDiscounts({ discounts, coupon }))
        }
        coupon={listingDetails.coupon}
        onNextStep={onNextStep}
        totalSubscriptionCost={0}
        amountOfStoredItems={0}
        disableOfferInvoiceOption={true}
        createOrder={async () => listingCreationMutation.mutate()}
        isSubmittingOrder={isLoading}
        hasActiveOrderLimitReached={isLoading}
        isTimeslotOpen={true}
        changeAddress={''}
        changeDate={''}
        totalTransactionPrice={0}
        oneTimePayment={{
          defaultCollapsed: isLoading,
          price: totalListingAddonPrice.amount,
          oneTimeServices: oneTimeItems,
          oldPrice: totalListingAddonPrice.oldPrice,
          items: oneTimeItems,
          isLoading: isLoading,
        }}
      />
    </Container>
  )
}
