import { Box, Button, Container, Flex, Heading, Text } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { type DynamicIconName, ItemWrapper } from '@wanda-space/noelle'
import type { ItemResponseDto } from '@wanda-space/types'
import { getImageDetailByImageId } from 'api-client'
import { getProductCategoriesByCountry } from 'api-client/lib/routes/product'
import { ItemImage } from 'components/Item/Image/ItemImage'
import { WANDA_LAST_ROUTE } from 'consts/LocalstorageKeys'
import { useDateLocale } from 'contexts/Intl'
import { format } from 'date-fns'
import { useDocumentTitle } from 'hooks'
import { useAppSelector } from 'hooks/useAppSelector'
import { useAuth } from 'hooks/useAuth'
import { useSellableItems } from 'hooks/useSellableItems'
import type { Stepable } from 'interfaces'
import React, { useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useAppDispatch } from 'reduxStore'
import { getItems } from 'reduxStore/ducks/item'
import {
  getListingFormState,
  getSelectedItemsFromStorageToSell,
} from 'reduxStore/ducks/sell/selectors'
import { storageActions } from 'reduxStore/ducks/sell/sellFlow'
import { getSupportedCurrencyForCountryCode } from 'utils'

const SelectItems = ({ onNextStep }: Stepable) => {
  const { formatMessage } = useIntl()
  const { isAuthenticated } = useAuth()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const locale = useDateLocale()

  useDocumentTitle(formatMessage({ id: 'phrase.select.items' }))

  const { storedItems } = useAppSelector((state) => state.item)
  const selectedItems = useAppSelector(getSelectedItemsFromStorageToSell)
  const user = useAppSelector((state) => state.user)
  const countryCode = user.user?.countryCode
  const mountRef = useRef(false)
  const listing = useAppSelector(getListingFormState(false))

  const { data: categories } = useQuery(
    ['fetchCategory', countryCode],
    () => getProductCategoriesByCountry(countryCode!),
    { enabled: Boolean(countryCode) }
  )

  const { data: sellableItemsResponse } = useSellableItems()

  const { sellableItems, nonSellableItems } = useMemo(() => {
    const sellableItems = sellableItemsResponse?.items ?? []
    const ownedItems = storedItems.filter(({ ownerId }) => ownerId === user.user?.id)
    const nonSellableItems = ownedItems.filter(
      (item) => !sellableItems.find(({ id }) => id === item.id)
    )

    return {
      sellableItems,
      nonSellableItems,
    }
  }, [sellableItemsResponse, storedItems, user])

  if (!mountRef.current) {
    if (!isAuthenticated) {
      localStorage.setItem(WANDA_LAST_ROUTE, location.pathname + location.search)
    } else {
      dispatch(getItems())
    }
    mountRef.current = true
  }

  const handleToggleSelectItems = (id: string) => {
    const selectedItem = selectedItems.find((item) => item.id === id)
    if (selectedItem) {
      const preservedItems = selectedItems.filter((item) => item.id !== id)
      dispatch(storageActions.toggleStorageItems(preservedItems))
    } else {
      const newSelectedItem = sellableItems.find((item) => item.id === id)
      if (selectedItems && newSelectedItem)
        dispatch(storageActions.toggleStorageItems([...selectedItems, newSelectedItem]))
    }
  }

  const getItemCategory = (item: ItemResponseDto) =>
    categories?.items?.find((category) => category.id === item.storageProduct?.categoryId)

  const handleNextStep = async () => {
    const itemImageUrls = (
      await Promise.all(
        selectedItems.map(async ({ id, image }) => {
          try {
            if (image) {
              const imageDetails = await getImageDetailByImageId(id, image)
              const imageBlob = await (await fetch(imageDetails.url)).blob()
              const imageDataURL = await new Promise<string>((resolve, reject) => {
                const fileReaderEventHandler = (event: ProgressEvent<FileReader>) => {
                  const result = event.target?.result
                  const error = event.target?.error
                  if (event.type === 'load' && result && typeof result === 'string') {
                    resolve(result)
                  }
                  if (error) {
                    reject(error)
                  }
                  reject(new Error(`Error in image ${image}`))
                }
                const fileReader = new FileReader()

                fileReader.readAsDataURL(imageBlob)

                fileReader.addEventListener('load', fileReaderEventHandler)
                fileReader.addEventListener('error', fileReaderEventHandler)
              })

              return imageDataURL
            }
            return ''
          } catch (error) {
            captureException(error)
            return ''
          }
        })
      )
    ).filter((imageUrl) => Boolean(imageUrl))

    dispatch(
      storageActions.updateListingFormValues({
        ...listing,
        price: {
          ...listing.price,
          currency: getSupportedCurrencyForCountryCode(selectedItems[0].countryCode ?? undefined),
        },
        images: itemImageUrls,
      })
    )

    onNextStep()
  }

  return (
    <Container pt={0}>
      <Heading fontSize="2xl" mb={6}>
        {formatMessage({ id: 'selling.selectItems.title' })}
      </Heading>
      <Text size="md" mb={6}>
        {formatMessage({ id: 'selling.selectItems.description' })}
      </Text>
      {sellableItems.length + nonSellableItems.length > 5 && (
        <Button
          onClick={handleNextStep}
          variant="solid"
          colorScheme="ctaBlack"
          size="lg"
          data-testid={
            isAuthenticated
              ? 'sell-go-to-create-listing-secondary'
              : 'sell-go-to-login-page-secondary'
          }
          isDisabled={isAuthenticated && selectedItems.length === 0}
          disabled={isAuthenticated && selectedItems.length === 0}
          w="100%"
          mb={6}
        >
          {isAuthenticated
            ? formatMessage({ id: 'word.continue' })
            : formatMessage({ id: 'login.toContinue' })}
        </Button>
      )}

      {isAuthenticated ? (
        <Flex justifyContent="center">
          {sellableItems.length === 0 && nonSellableItems.length === 0 ? (
            <Box mb={10} data-testid="select-items-empty-space">
              <Text mb={4}>{formatMessage({ id: 'selling.selectItems.emptySpace' })}</Text>
            </Box>
          ) : (
            <Box mb={10} minHeight="50px" w="100%">
              {sellableItems.map((item, i) => (
                <ItemWrapper
                  key={item.id}
                  img={
                    <ItemImage
                      {...(item.image
                        ? {
                            itemId: item.id,
                            imageId: item.image,
                          }
                        : {
                            iconName: getItemCategory(item)?.iconName as DynamicIconName,
                          })}
                    />
                  }
                  contentProps={{
                    name: item.name,
                    description: formatMessage(
                      { id: 'buy.sell.sellable.item' },
                      {
                        date: format(new Date(item.createdAt), 'dd/MM/YYY', {
                          locale,
                        }),
                      }
                    ),
                  }}
                  withCheckbox
                  isChecked={selectedItems.some(({ id }) => id === item.id)}
                  onClick={() => handleToggleSelectItems(item.id)}
                  dataTestId={`item-${i + 1}`}
                />
              ))}
              {nonSellableItems.map((item) => (
                <ItemWrapper
                  key={item.id}
                  img={
                    <ItemImage
                      {...(item.image
                        ? {
                            itemId: item.id,
                            imageId: item.image,
                          }
                        : {
                            iconName: getItemCategory(item)?.iconName as DynamicIconName,
                          })}
                    />
                  }
                  contentProps={{
                    name: item.name,
                    description: formatMessage({
                      id: 'buy.sell.non.sellable.item',
                    }),
                  }}
                  disabled
                  disableHover
                />
              ))}
            </Box>
          )}
        </Flex>
      ) : null}
      <Button
        onClick={handleNextStep}
        variant="solid"
        colorScheme="ctaBlack"
        size="lg"
        data-testid={isAuthenticated ? 'sell-go-to-create-listing' : 'sell-go-to-login-page'}
        isDisabled={isAuthenticated && selectedItems.length === 0}
        disabled={isAuthenticated && selectedItems.length === 0}
        w="100%"
      >
        {isAuthenticated
          ? formatMessage({ id: 'word.continue' })
          : formatMessage({ id: 'login.toContinue' })}
      </Button>
    </Container>
  )
}

export { SelectItems }
