import { Box, Wrap } from '@chakra-ui/react'
import { SelectCard, type SelectCardProps } from '@wanda-space/noelle'
import type { Product } from 'api-client'
import { AddonsAddressType } from 'consts'
import React from 'react'
import { useIntl } from 'react-intl'
import { formatPriceWrapper } from 'utils/price-utils'
import { productToPrice } from 'utils/product-utils'

export interface Props {
  addressType: AddonsAddressType
  onChange: (addressType: AddonsAddressType) => void
  flexProduct: Product | undefined
  oneCardProps?: Partial<SelectCardProps>
  flexCardProps?: Partial<SelectCardProps>
  isActive?: boolean
}

const SelectAddonsAddress = ({
  addressType,
  onChange,
  flexProduct,
  oneCardProps,
  flexCardProps,
  isActive,
}: Props) => {
  const { formatMessage } = useIntl()

  return (
    <Box width="100%">
      {!isActive && (
        <Wrap mb={6}>
          <SelectCard
            key={AddonsAddressType.ONE}
            title={formatMessage({ id: 'addons.oneAddress.title' })}
            description={formatMessage({ id: 'addons.oneAddress.description' })}
            active={addressType === AddonsAddressType.ONE}
            onClick={() => onChange(AddonsAddressType.ONE)}
            badge={formatMessage({ id: 'word.included' })}
            data-testid="one-address"
            showCheckMark
            {...oneCardProps}
          />
        </Wrap>
      )}
      {!!flexProduct && (
        <Wrap mb={6}>
          <SelectCard
            key={AddonsAddressType.FLEX}
            title={formatMessage({ id: `${flexProduct?.localizationKey}` })}
            description={formatMessage({ id: `${flexProduct?.localizationKey}.description` })}
            active={isActive || addressType === AddonsAddressType.FLEX}
            onClick={isActive ? undefined : () => onChange(AddonsAddressType.FLEX)}
            badge={
              isActive
                ? undefined
                : formatPriceWrapper(productToPrice(flexProduct), true, formatMessage)
            }
            badges={
              isActive
                ? [{ title: formatMessage({ id: 'word.active' }), bg: 'green.200' }]
                : undefined
            }
            openBtnText={formatMessage({ id: 'word.open' })}
            closeBtnText={formatMessage({ id: 'word.close' })}
            data-testid="flex-address"
            showCheckMark={!isActive}
            {...flexCardProps}
          />
        </Wrap>
      )}
    </Box>
  )
}

export { SelectAddonsAddress }
