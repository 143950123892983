import { Box, Button, Flex, Image, Link, Text, Textarea } from '@chakra-ui/react'
import {
  BuildingIcon,
  ChatTextIcon,
  GPSpinIcon,
  IconBulletPoint,
  MobileSignalIcon,
  UserSettingIcon,
} from '@wanda-space/noelle'
import mockMap from '@wanda-space/noelle/illustrations/mockMap.svg'
import type { OrderLineWithFullProductAndDiscount } from 'api-client'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { AnotherContactPerson } from './AnotherContactPerson'
import { ChangeAddressButton } from './ChangeAddressButton'
import {
  AddressRenderState,
  type ContactInfoData,
  type ContactInfoPageSubmitHandler,
  type ContactPerson,
  type UpdateAddressRenderState,
} from './types'
import { joinWithComma } from './utils'

interface ContactInfoCardProps {
  contactInfo: ContactInfoData
  defaultContactPerson?: ContactPerson
  onSubmit?: ContactInfoPageSubmitHandler
  isSubmitting?: boolean
  updateAddressRenderState?: UpdateAddressRenderState
  flexOneTimeOrderline?: OrderLineWithFullProductAndDiscount
  isCheckingFlexAddress?: boolean
  showContactPerson: boolean
  changeAddressButtonTextId?: string
}

export const ContactInfoCard = ({
  onSubmit,
  showContactPerson,
  contactInfo,
  updateAddressRenderState,
  defaultContactPerson,
  flexOneTimeOrderline,
  isCheckingFlexAddress = false,
  changeAddressButtonTextId = '',
}: ContactInfoCardProps) => {
  const { formatMessage } = useIntl()
  const {
    street,
    postalCode,
    firstName,
    lastName,
    addressComment: savedAddressComment,
    floorNumber,
    elevator,
    city,
    phoneNumber,
    countryCode,
  } = contactInfo
  const floor = `${formatMessage({ id: 'word.floor.no' })} ${floorNumber}`
  const [contactPerson, setContactPerson] = useState(
    defaultContactPerson || { name: '', phoneNumber: '' }
  )
  const [addressComment, setAddressComment] = useState('')

  const hasElevator = elevator
    ? formatMessage({ id: 'word.with.elevator' })
    : formatMessage({ id: 'carryingMethod.carrying.noElevator' })

  const fullName = `${firstName} ${lastName}`
  const fullAddress = joinWithComma([street, postalCode, city])
  const floorAndElevator = joinWithComma([floor, hasElevator])

  const onClickHandler = () => {
    if (!onSubmit) {
      throw new Error('onSubmit props missing.')
    }

    onSubmit({
      contactPerson,
      contactInfo: {
        ...contactInfo,
        addressComment,
      },
    })
  }

  return (
    <>
      <Box borderRadius="10" overflow="hidden" border="1px solid" borderColor="gray.100" mb="5">
        <Box bg="gray.100">
          <Image width="100%" src={mockMap} />
        </Box>
        <Flex p="5" direction="column" gap="4">
          <IconBulletPoint
            text={fullName}
            leftIcon={<UserSettingIcon w="5" h="5" color="gray.500" />}
          />
          <IconBulletPoint text={phoneNumber} leftIcon={<MobileSignalIcon color="gray.500" />} />
          <IconBulletPoint text={fullAddress} leftIcon={<GPSpinIcon color="gray.500" />} />
          <IconBulletPoint text={floorAndElevator} leftIcon={<BuildingIcon color="gray.400" />} />
          {typeof onSubmit === 'undefined' ? (
            <IconBulletPoint
              text={
                savedAddressComment
                  ? savedAddressComment
                  : formatMessage({ id: 'word.no.comments' })
              }
              leftIcon={<ChatTextIcon color="gray.400" />}
              data-testid="extraDeliveryInfo"
            />
          ) : (
            <Box>
              <Flex>
                <ChatTextIcon color="gray.400" />
                <Box w="90%" ml="4">
                  <Text>{formatMessage({ id: 'word.comments' })}</Text>
                  <Textarea
                    value={addressComment}
                    data-testid="extraDeliveryInfo"
                    onChange={(v) => setAddressComment(v.target.value)}
                  />
                </Box>
              </Flex>
              {savedAddressComment && (
                <Box color="gray.500" ml="10" fontSize="12" mt="2">
                  <Flex>
                    <Text fontWeight="medium">
                      {formatMessage({ id: 'word.saved.address.comment' })}:
                    </Text>
                    <Link
                      _hover={{ color: 'gray.600' }}
                      onClick={() => setAddressComment(savedAddressComment)}
                      as="button"
                      ml="3"
                      fontSize="12"
                      textDecoration="underline"
                    >
                      {formatMessage({ id: 'word.use.my.saved.address' })}
                    </Link>
                  </Flex>
                  <Text>{savedAddressComment}</Text>
                </Box>
              )}
            </Box>
          )}
        </Flex>
      </Box>
      {showContactPerson && (
        <AnotherContactPerson
          expanderType="button"
          phoneInputDefaultCountry={countryCode}
          contactPerson={contactPerson}
          updateContactPerson={setContactPerson}
          expandContainerProps={{
            borderRadius: 10,
            px: 6,
            pt: 3,
            pb: 1,
            border: '1px solid',
            borderColor: 'gray.100',
          }}
        />
      )}
      {updateAddressRenderState && (
        <ChangeAddressButton
          onClick={() => updateAddressRenderState(AddressRenderState.CHANGE_ORDER_ADDRESS)}
          isCheckingFlexAddress={isCheckingFlexAddress}
          changeAddressButtonTextId={changeAddressButtonTextId}
          flexOneTimeOrderline={flexOneTimeOrderline}
        />
      )}
      {onSubmit && (
        <Button
          width="100%"
          mt="5"
          onClick={onClickHandler}
          size="lg"
          variant="solid"
          colorScheme="ctaBlack"
          data-testid="continue-button"
        >
          {formatMessage({ id: 'word.continue' })}
        </Button>
      )}
    </>
  )
}
