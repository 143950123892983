import { Spinner } from '@chakra-ui/react'
import { EmptyStateBox, ReturnBoxIcon } from '@wanda-space/noelle'
import { CategoryLeadTime, type ItemResponseDto } from '@wanda-space/types'
import { ItemList, type ItemListProps } from 'components/Item/List/ItemList'
import { useAppSelector } from 'hooks/useAppSelector'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import { useTaasCategories } from 'hooks/useTaasCategories'
import type { Stepable } from 'interfaces'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'reduxStore'
import { getItems, getItemsAvailableForPickup } from 'reduxStore/ducks/item'
import { itemPayloadSelectors, setExistingItems } from 'reduxStore/ducks/storage/storageFlow'
import { countByItemType, updateItemStorageProducts } from 'utils/item'
import { thresholdMetMap } from 'utils/product-utils'

export const ExistingItems = ({ onNextStep }: Stepable) => {
  const dispatch = useAppDispatch()
  const existingItems = useAppSelector(getItemsAvailableForPickup())
  const selectedItems = useAppSelector((state) => state.storageFlow.existingItems)
  const newItems = useAppSelector(itemPayloadSelectors.selectAll)
  const itemCountByType = countByItemType([...newItems, ...selectedItems])
  const taasCategories = useTaasCategories()
  const { formatMessage } = useIntl()
  const { productsById, baseStorageProductByItemType, isLoading, isError, error } =
    useProductsAndCategories()
  useEffect(() => {
    dispatch(getItems())
  }, [])
  const handleSelect = (selectedItems: ItemResponseDto[]) => {
    dispatch(setExistingItems(selectedItems))
  }
  const thresholdMap = thresholdMetMap(
    itemCountByType,
    taasCategories.data?.find((t) => t.leadTimeDays === CategoryLeadTime.ASK)?.ranges
  )

  if (isLoading) {
    return <Spinner />
  }
  if (isError) {
    throw error
  }
  if (existingItems.length === 0) {
    return (
      <EmptyStateBox icon={<ReturnBoxIcon />} text={formatMessage({ id: 'no.existing.items' })} />
    )
  }

  const props: ItemListProps = {
    items: updateItemStorageProducts(existingItems, productsById!, baseStorageProductByItemType!),
    handleSubmit: onNextStep,
    handleSelect,
    preselectedItems: selectedItems,
    thresholdMap,
  }

  return <ItemList {...props} />
}
