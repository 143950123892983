import { Box, Button, Center, Flex, Text, Tooltip, Wrap } from '@chakra-ui/react'
import type { BoxProps } from '@chakra-ui/react'
import {
  ChevronRightIcon,
  DynamicIcon,
  DynamicIconName,
  EmptyStateBox,
  ItemWrapper,
} from '@wanda-space/noelle'
import hooray from '@wanda-space/noelle/illustrations/hooray.svg'
import { type ItemResponseDto, StorageItemState } from '@wanda-space/types'
import { ItemImage } from 'components/Item/Image/ItemImage'
import { Routes } from 'consts'
import { format } from 'date-fns'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import * as R from 'ramda'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { sanitizeAmount } from 'utils'

import { DeleteItem } from './DeleteItem'

interface ItemListProps {
  items: ItemResponseDto[]
  containerBoxProps?: BoxProps
  groupByProductType?: boolean
  maxAmountOfItems?: number
  hasM2Subscription?: boolean
  emptyStateBoxProps?: {
    titleLokalizeId?: string
    iconImgSrc?: string
    linkTo: string
    linkTextLokalizeId?: string
  }
  seeAllLink?: {
    pathToSeeAllPage: string
    showWithEmptyStateToo?: boolean
  }
}

export const StorageItemList = ({
  items,
  containerBoxProps = { mx: -3 },
  emptyStateBoxProps,
  groupByProductType,
  hasM2Subscription,
  maxAmountOfItems = 0,
  seeAllLink,
}: ItemListProps) => {
  const { productCategoriesById } = useProductsAndCategories()
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const [collapsed, setCollapsed] = useState(true)

  const storageProducts = R.sortBy(
    R.prop('name'),
    items.map((item) => {
      const {
        storageProduct: { id, name, price, metadata, categoryId },
      } = item
      const productNameOrMetadataName = name.length
        ? name
        : (metadata?.itemType?.toString().toLowerCase() ?? '')
      return {
        id,
        name: productNameOrMetadataName,
        price,
        categoryId,
        // @ts-ignore fix ItemResponseDto for paymentCommitment
        paymentCommitment: item.paymentCommitment,
      }
    })
  )

  const groupedByProduct = groupByProductType
    ? R.groupWith((a, b) => {
        return a.name === b.name
      }, storageProducts)
    : []

  const showSeeAllLink =
    seeAllLink &&
    ((items.length === 0 && seeAllLink.showWithEmptyStateToo === true) || items.length > 0)

  const seeAllLinkMarginX =
    typeof containerBoxProps.mx === 'number' && Math.sign(containerBoxProps.mx) === -1
      ? Math.abs(containerBoxProps.mx)
      : 0

  return (
    <Box {...containerBoxProps}>
      {items.length === 0 && emptyStateBoxProps ? (
        <EmptyStateBox
          icon={<img src={emptyStateBoxProps.iconImgSrc || hooray} alt="" />}
          text={
            <>
              <Text mr="1">
                {formatMessage({
                  id: emptyStateBoxProps.titleLokalizeId || 'itemoverview.emptyState.title',
                })}
              </Text>
              <Link style={{ textDecoration: 'underline' }} to={emptyStateBoxProps.linkTo}>
                {formatMessage({
                  id: emptyStateBoxProps.linkTextLokalizeId || 'itemoverview.emptyState.link',
                })}
              </Link>
            </>
          }
        />
      ) : groupByProductType ? (
        <>
          {groupedByProduct.map((product, i) => {
            const amountOfProducts = product.length
            const formattedName =
              amountOfProducts > 1 ? `${amountOfProducts}x ${product[0].name}` : product[0].name

            const amountOfItemsWithPaymentCommitment = hasM2Subscription
              ? product.length
              : product.filter(({ paymentCommitment }) => paymentCommitment !== null).length

            const name = formattedName.charAt(0).toUpperCase() + formattedName.slice(1)
            const category = productCategoriesById?.[product[0].categoryId ?? '']
            const totalPrice = hasM2Subscription
              ? ''
              : (`${sanitizeAmount(
                  product.map(({ price }) => price).reduce((a, b) => a + b, 0)
                )},-/${formatMessage({ id: 'word.monthShort' })}` ?? '')

            return (
              <Box key={i} display={collapsed && i > maxAmountOfItems ? 'none' : 'auto'}>
                <ItemWrapper
                  minWidth={20}
                  disableHover={true}
                  img={
                    <Center
                      bg="purple.200"
                      borderRadius="lg"
                      minHeight="65px"
                      minWidth="65px"
                      maxHeight="65px"
                      maxWidth="65px"
                      border={amountOfProducts > 1 ? '1px solid' : 'none'}
                      borderColor={amountOfProducts > 1 ? 'purple.600' : 'none'}
                      boxShadow={amountOfProducts > 1 ? '4px 4px 0 #9791E5' : 'auto'}
                    >
                      <ItemImage
                        itemId={product[0].id}
                        iconName={category?.iconName as DynamicIconName}
                      />
                    </Center>
                  }
                  contentProps={{
                    name,
                  }}
                  moreActionButton={
                    <Flex alignItems="center" gap="4">
                      {amountOfItemsWithPaymentCommitment > 0 && (
                        <Tooltip
                          backgroundColor="purple.200"
                          color="black.800"
                          borderRadius="lg"
                          p="4"
                          hasArrow
                          label={
                            <Box>
                              <Text fontWeight="medium">
                                {formatMessage({ id: 'word.paymentcommitment' })}
                              </Text>
                              <Text fontWeight="light">
                                {formatMessage({ id: 'paymentcommitment.description' })}
                              </Text>
                            </Box>
                          }
                        >
                          <Flex
                            px="2"
                            borderRadius="2xl"
                            bg="red.50"
                            border="1px solid #fcdede"
                            alignItems="center"
                            gap="1"
                            py="1"
                          >
                            <DynamicIcon
                              h={4}
                              w={4}
                              iconName={DynamicIconName.lockIcon}
                              color="red.500"
                            />
                            <Text fontSize="14px" color="red.500">
                              {amountOfItemsWithPaymentCommitment}
                            </Text>
                          </Flex>
                        </Tooltip>
                      )}
                      <Text fontWeight="bold">{totalPrice}</Text>
                    </Flex>
                  }
                />
              </Box>
            )
          })}
          {groupedByProduct.length > maxAmountOfItems && (
            <Text
              as={Button}
              textDecoration="underline"
              mx={seeAllLinkMarginX}
              bg="transparent"
              p="1"
              _hover={{ backgroundColor: 'transparent' }}
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed
                ? formatMessage({ id: 'word.seeAll' })
                : formatMessage({ id: 'word.showLess' })}
            </Text>
          )}
        </>
      ) : (
        items.map((item) => {
          const category = productCategoriesById?.[item.storageProduct?.categoryId ?? '']
          // @ts-ignore fix pendingOrderId
          const isBookedItem = !!item.pendingOrderId

          return (
            <ItemWrapper
              key={item.id}
              img={
                <ItemImage
                  itemId={item.id}
                  imageId={item.image ?? undefined}
                  iconName={category?.iconName as DynamicIconName}
                />
              }
              contentProps={{
                name: item.name,
                type: category ? category.name : '',
                tag: isBookedItem ? formatMessage({ id: 'item.label.ordered' }) : '',
                createdAt: isBookedItem
                  ? ''
                  : formatMessage(
                      { id: 'space.createdFrom' },
                      { time: format(new Date(item.createdAt), 'MMM dd yyyy') }
                    ),
              }}
              moreActionButton={
                <span>
                  {isBookedItem === false && item.state === StorageItemState.WITH_CUSTOMER && (
                    <DeleteItem itemName={item.name} itemId={item.id} />
                  )}
                  <ChevronRightIcon w={6} h={6} />
                </span>
              }
              onClick={() => navigate(Routes.ItemDetails(item.id))}
            />
          )
        })
      )}
      {showSeeAllLink ? (
        <Wrap mx={seeAllLinkMarginX} mt="4">
          <Link style={{ textDecoration: 'underline' }} to={seeAllLink.pathToSeeAllPage}>
            {formatMessage({ id: 'word.seeAll' })}
          </Link>
        </Wrap>
      ) : null}
    </Box>
  )
}
