import React, { useEffect, useRef } from 'react'

import type { Stepable } from 'interfaces'

import { Button, Text } from '@chakra-ui/react'
import { useAuth } from 'hooks/useAuth'
import { useIntl } from 'react-intl'

import { useQuery } from '@tanstack/react-query'
import { OrderState, UserFeatures } from '@wanda-space/types'
import type { OrderLineWithFullProductAndDiscount, Product } from 'api-client'
import { getUserFeatures } from 'api-client/lib/routes/userFeature'
import { SelectAddonsInsurance } from 'components/SelectAddonsInsurance/SelectAddonsInsurance'
import { SelectCardSkeleton } from 'components/SelectCardSkeleton'
import { useMixpanelTracker } from 'hooks/useMixpanelTracker'
import { useOrders } from 'hooks/useOrders'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import { insuranceProductFinder } from 'utils'

interface InsuranceAddonProps extends Partial<Stepable> {
  coupon?: string
  onRemoveInsurance: (product: Product) => void
  onAddInsurance: (product: Product) => void
  addonsOrderlines: OrderLineWithFullProductAndDiscount[]
}

export const InsuranceAddon = ({
  onNextStep,
  coupon,
  addonsOrderlines,
  onAddInsurance,
  onRemoveInsurance,
}: InsuranceAddonProps) => {
  const { formatMessage } = useIntl()
  const { isAuthenticated } = useAuth()
  const { userProducts, isLoading, isError, error } = useProductsAndCategories({
    couponCode: coupon,
  })
  const mixpanelTracker = useMixpanelTracker()
  const { data: userFeatures, isInitialLoading: loadingUserFeature } = useQuery(
    ['getUserFeatures'],
    () => getUserFeatures(),
    { enabled: isAuthenticated }
  )
  const isInitialRef = useRef(false)

  const { data: orders, isInitialLoading: isLoadingOrders } = useOrders()

  const pendingOrderWithInsuranceAddon = orders?.find(
    (order) =>
      (order.state === OrderState.ORDER_SUBMITTED ||
        order.state === OrderState.PAYMENT_AUTHORISED) &&
      order.orderLines.find((o) => insuranceProductFinder(o.product as Product))
  )

  const activeInsurance =
    userFeatures?.[UserFeatures.INSURANCE] || typeof pendingOrderWithInsuranceAddon !== 'undefined'

  const currentlySelectedInsuranceOrderline = addonsOrderlines.find((orderline) =>
    insuranceProductFinder(orderline.product)
  )

  const onInsuranceChange = (productId?: string) => {
    const currentlySelectedInsuranceOrderline = addonsOrderlines.find((orderline) =>
      insuranceProductFinder(orderline.product)
    )
    const isSameProduct = currentlySelectedInsuranceOrderline?.product.id === productId
    if (currentlySelectedInsuranceOrderline && !isSameProduct) {
      onRemoveInsurance(currentlySelectedInsuranceOrderline.product)
    }

    if (productId && !isSameProduct) {
      const selectedInsurance = userProducts?.find((p) => p.id === productId)
      if (selectedInsurance) {
        onAddInsurance(selectedInsurance)
      }
    }
  }

  useEffect(() => {
    if (userProducts && !isInitialRef.current) {
      if (!currentlySelectedInsuranceOrderline) {
        const insuranceProducts = userProducts.filter(insuranceProductFinder)
        isInitialRef.current = true
        onInsuranceChange(insuranceProducts[0]?.id)
      }
    }
  }, [userProducts, currentlySelectedInsuranceOrderline, onInsuranceChange])

  if (isError) {
    throw error
  }

  return (
    <>
      <Text textTransform="uppercase" fontWeight="500" mb={2} fontSize="lg">
        {formatMessage({ id: 'word.insurance' })}
      </Text>
      {isLoading || isLoadingOrders || loadingUserFeature ? (
        <>
          <SelectCardSkeleton />
          <SelectCardSkeleton />
          <SelectCardSkeleton />
        </>
      ) : (
        <SelectAddonsInsurance
          onChange={onInsuranceChange}
          selectedInsurance={currentlySelectedInsuranceOrderline?.product.id}
          activeInsurance={activeInsurance}
        />
      )}

      {onNextStep ? (
        <Button
          width="100%"
          onClick={() => {
            onNextStep()
            mixpanelTracker.selectAddons(addonsOrderlines)
          }}
          isLoading={isLoading}
          size="lg"
          variant="solid"
          colorScheme="ctaBlack"
          data-testid="continue-button"
        >
          {formatMessage({ id: 'booking.review' })}
        </Button>
      ) : null}
    </>
  )
}
