/**
 * @file
 *
 * Responsible for tracking (product analytics) by listening in on Redux actions through Redux middleware.
 *
 * There are other alternatives to call analytics tracker functions. However, these violate the...
 * - [Single Responsibility Principle](https://w.wiki/yHV) (SRP),
 * - [Seperation of Concerns](https://w.wiki/3NA$) principle (SoC)
 * - [Don't repeat yourself](https://w.wiki/JAp) principle (DRY)
 *
 * 1. In React components within listeners like onClick or onSubmit
 * 2. In Redux action creators or reducers
 *
 * These options aren't particularly appealing, because they place an extra unrelated burden on parts of the codebase,
 * and (particularly option 1) are hard to maintain.
 *
 * By using redux middleware, we adhere to the principles of SRP, SoC and DRY. However, we may also want to track
 * events that don't impact the Redux store. And we don't want to use the store just for the middleware. So we may
 * need to resort to call tracker functions from React components in some cases (should be avoided when possible).
 *
 * We may also want to look into using a supplementary react router approach,
 * something along the lines of [this example](https://github.com/sheshbabu/react-pageview-tracking-demo)
 */

import type { Middleware, PayloadAction } from '@reduxjs/toolkit'
import type {
  PlaceShopOrderWithOrderlinesResponseDto,
  PlacedOrderResponseDto,
  ProductCategoryResponseDto,
} from '@wanda-space/types'
import type { CreateServiceOrderWithPickupResponseDto } from '@wanda-space/types'
import type { DateAndTime, OrderLineWithFullProductAndDiscount } from 'api-client'
import type { FlattenedDeliveryInfo, PriceWrapper, User } from 'interfaces'
import { mp_handler } from 'tracking/mixpanelHandler'
import type { TrackerDTO } from 'tracking/types'
import {
  getDecoratedTrackerDTO,
  util_getWhatsRelevantByType,
  util_makeHashFromUUID,
} from 'tracking/utils'
import type { KnownObj } from 'tracking/utils'

import { SliceNames, reducerFunctionNames as rFNs, stateKeyMappings } from '../constants'
import type { RootState } from '../index'
import { util_extractOrderSuccessPayload } from './loggerHelpers'

/** Extracts relevant payload for logging, based on redux action payload */
const util_extractLogpayloadFromActionPayload = (
  ap: KnownObj,
  aName: string,
  sName: SliceNames,
  state: RootState
) => {
  switch (aName) {
    // START: Purchase events
    case sName === SliceNames.SERVICE && rFNs[SliceNames.SERVICE].setOrderSuccessPayload:
      return util_extractOrderSuccessPayload(
        ap as CreateServiceOrderWithPickupResponseDto,
        sName,
        state
      )
    case sName === SliceNames.PACKAGING && rFNs[SliceNames.PACKAGING].setOrderSuccessPayload:
      return util_extractOrderSuccessPayload(
        ap as PlaceShopOrderWithOrderlinesResponseDto,
        sName,
        state
      )
    case rFNs[SliceNames.STORAGE].setOrderSuccessPayload:
    case rFNs[SliceNames.RETURN].setOrderSuccessPayload:
    case rFNs[SliceNames.M2_PPA].setOrderSuccessPayload:
    case rFNs[SliceNames.SELL_WITH_PICKUP].setOrderSuccessPayload:
      return util_extractOrderSuccessPayload(ap as PlacedOrderResponseDto, sName, state)
    // END: Purchase events
    case rFNs[SliceNames.SERVICE].addStorageOrderLine:
    case rFNs[SliceNames.SERVICE].addServiceOrderLine:
      return util_getWhatsRelevantByType.orderLine(ap as OrderLineWithFullProductAndDiscount)
    case rFNs[SliceNames.SERVICE].setServiceOrderLines:
    case rFNs[SliceNames.SERVICE].setStorageOrderLines:
    case rFNs[SliceNames.SERVICE].setTaasOrderLines:
      return util_getWhatsRelevantByType.orderLines(ap as OrderLineWithFullProductAndDiscount[])
    case rFNs[SliceNames.SERVICE].updateDateAndTime:
    case rFNs[SliceNames.STORAGE].selectDateAndTime:
      return util_getWhatsRelevantByType.dateAndTimeslot(ap as DateAndTime)
    case rFNs[SliceNames.SERVICE].setSelectedCategory:
      return util_getWhatsRelevantByType.productCategory(ap as ProductCategoryResponseDto)
    case rFNs[SliceNames.SERVICE].setPickupAddress:
      return util_getWhatsRelevantByType.deliveryInfo(ap as FlattenedDeliveryInfo)
    case rFNs[SliceNames.UI].setEstimatedTimeslotCost:
      return util_getWhatsRelevantByType.priceWrapper(ap as PriceWrapper)
    default:
      return {}
  }
}

/**
 * Triggered by action `user/fetchUser/fulfilled`, identifies user with hash
 */
const log_user_authenticated = (user: User) => {
  if (/@wanda.space$/.test(user.email)) {
    // console.warn('Wanda employee! no tracking')
    // Comment out 3 lines below to enable tracking for Wanda employees, for dev / testing purposes
    mp_handler.identify('wanda_employee')
    mp_handler.set_doNotTrack()
    return
  }
  if (user.id) {
    mp_handler.identify(util_makeHashFromUUID(user.id))
  }
}

const sliceNamesPattern = Object.values(SliceNames)
  .map((slice) => `${slice}(?=/|$)`)
  .join('|')

const regexPattern = new RegExp(`^(${sliceNamesPattern})/([^/]+)(/(.+))?`)

/** Helper, to identify relevant slice name etc, derived from action type */
const getActionHelperInfo = (
  actionType: string
): { action: string | null; slice: string | null; extra: string | null } | null => {
  const matches = actionType.match(regexPattern)
  if (matches) {
    return {
      slice: matches[1] || null, // Slice name
      action: matches[2] || null, // Action (reducer) name
      extra: matches[4] || null, // Extra reducer name (if present)
    }
  } else return null
}

/** Slices to be ignored - at least until we have refactored use of useMixpanelTracker hook in misc. files */
const ignoreSlices: SliceNames[] = []

/**
 * General logic for inspecting relevant redux actions (passed on by this middleware interceptor)
 */
const trackEventFromAction = (action: PayloadAction<unknown>, state: RootState) => {
  // Keep for debugging purposes, for now
  /*
  console.groupCollapsed('MIDDLEWARE trackEventFromAction, action "%s"', action.type)
  console.log('action     ', action)
  console.log('state      ', state)
  console.log('actionInfo ', getActionHelperInfo(action.type))
  console.groupEnd()
  */

  // Don't track if user has opted out, or we don't have a payload or action type
  if (mp_handler.get_doNotTrack() || !action.payload || !action.type) return

  const aInfo = getActionHelperInfo(action.type)

  /** Relevant slice name, derived from action type  */
  const sName = aInfo && (aInfo.slice as keyof typeof SliceNames as SliceNames)

  // Only track action events for slices that are known to us, and not set to be ignored
  if (!sName || !Object.values(SliceNames).includes(sName) || !aInfo.action) return

  // Ignore logging of action from some known slices (temporary, see ignoreSlices)
  if (ignoreSlices.includes(sName)) return

  /** Relevant set of function map, based on slice name. See {@link rFNs} */
  const rFNs4Slice = sName in rFNs ? rFNs[sName] : null
  /** Relevant action name (without slice name prefix), derived from action type  */
  const aName = aInfo.action
  /** Relevant set of function map, based on slice name. See {@link rFNs} */
  const knownAction = rFNs4Slice?.[aName] || null

  // Special case, to identify user quickly
  if (sName === SliceNames.USER && knownAction && knownAction === rFNs[sName].fetchUser) {
    if (aInfo.extra && aInfo.extra === 'fulfilled') log_user_authenticated(action.payload as User)
    return
  }

  //Testing
  //util_extractOrderSuccessPayload(action.payload, sName, state)

  // TODO - Decision Point : Log all actions that aren't explicitly ignored, or only defined known actions?

  const extractedPayload = knownAction
    ? util_extractLogpayloadFromActionPayload(action.payload, aName, sName, state)
    : {}

  const event_prop_category = sName.replace(/\s/g, '_').replace(/\//g, '__').toLowerCase()

  const payload: TrackerDTO = getDecoratedTrackerDTO(
    {
      _flow: stateKeyMappings[sName] || null,
      _category: event_prop_category,
      _action: aName,
      ...extractedPayload,
    },
    state.ui || {}
  )

  // Keep for debugging purposes, for now
  /*
  console.groupCollapsed('MIDDLEWARE trackEventFromAction, action "%s" - logging', action.type)
  console.log('sName            ', sName)
  console.log('aName            ', aName)
  console.log('rFNs             ', rFNs)
  console.log('is_aNameAmongReducerFuncerions', knownAction)
  console.log('extractedPayload ', extractedPayload)
  console.log('payload          ', payload)
  console.groupEnd()
  */

  mp_handler.track(`${event_prop_category}-${aName}`, payload)
}

/**
 * Custom middleware to intercept redux actions and trigger tracking / logging
 */
export const loggerMiddleware: Middleware = (api) => (next) => (action) => {
  const response = next(action)
  const afterState = api.getState()

  try {
    trackEventFromAction(action, afterState)
  } catch (error) {
    console.error(error)
  }

  return response
}
