import React from 'react'

const goldenColor = 'rgb(225, 215, 0'
const goldenDarkColor = '#8b7b21'

export const InspectedByWandaBadge: React.FC<{
  width?: number
  inspectedByTranslation?: string
}> = ({ width = 100, inspectedByTranslation = 'INSPECTED BY' }) => {
  return (
    <svg
      viewBox="155.837 141.664 162.302 162.302"
      width={width}
      height={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{inspectedByTranslation} WANDA</title>
      <ellipse fill={goldenColor} cx="236.988" cy="222.815" rx="81.151" ry="81.151" />
      <path
        d="M 237.656 238.124 L 250.31 245.973 C 252.62 247.414 255.454 245.285 254.849 242.597 L 251.496 227.839 L 262.682 217.895 C 264.723 216.082 263.626 212.644 260.942 212.42 L 246.22 211.142 L 240.463 197.195 C 239.427 194.66 235.892 194.66 234.854 197.195 L 229.089 211.112 L 214.367 212.392 C 211.688 212.611 210.593 216.052 212.633 217.867 L 223.82 227.81 L 220.468 242.565 C 219.852 245.254 222.693 247.38 225.007 245.942 L 237.656 238.124 Z"
        fill={goldenDarkColor}
      />
      <path
        id="text-path-top"
        d="M 183.489 222.953 C 183.489 181.62 228.234 155.786 264.03 176.453 C 280.643 186.044 290.877 203.77 290.877 222.953 C 290.877 264.287 246.132 290.12 210.336 269.454 C 193.723 259.862 183.489 242.136 183.489 222.953"
        fill="none"
      />
      <path
        id="text-path-bottom"
        fill="none"
        d="M 183.489 222.953 C 183.489 264.287 228.234 290.12 264.03 269.454 C 280.643 259.862 290.877 242.136 290.877 222.953 C 290.877 181.62 246.132 155.786 210.336 176.453 C 193.723 186.044 183.489 203.77 183.489 222.953"
      />
      <text transform="matrix(1, 0, 0, 1, -0.2709390344145959, -0.012867001460499239)">
        <textPath
          fontSize="20"
          fontWeight="500"
          fill={goldenDarkColor}
          startOffset="25%"
          textAnchor="middle"
          style={{ textTransform: 'uppercase' }}
          href="#text-path-top"
        >
          {inspectedByTranslation}
        </textPath>
      </text>
      <text transform="matrix(1, 0, 0, 1, -0.27093003411278005, 13.987202315950476)">
        <textPath
          fontSize="20"
          fontWeight="500"
          fill={goldenDarkColor}
          startOffset="25%"
          letterSpacing="4"
          textAnchor="middle"
          href="#text-path-bottom"
        >
          WANDA
        </textPath>
      </text>
    </svg>
  )
}
