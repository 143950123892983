import type { DiscountFilterQuery, DiscountResponseDto } from '@wanda-space/types'

import { request } from '../api-client'

export async function fetchDiscounts(params: DiscountFilterQuery) {
  return request<DiscountResponseDto[]>({
    method: 'GET',
    url: 'discount',
    params: params,
  })
}
