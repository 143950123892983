import { type OrderType, ServiceLevelType } from '@wanda-space/types'

import { getKeyForServiceLevel } from './orderline'

export const getCarryingMethodLabel = (
  orderType: OrderType,
  serviceLevelType: ServiceLevelType | undefined
) => {
  const serviceLevelTypeKey = getKeyForServiceLevel(serviceLevelType ?? ServiceLevelType.CURBSIDE)

  const orderTypeKey = orderType.toLowerCase()

  return `carryingMethod.${serviceLevelTypeKey}.${orderTypeKey}.title`
}
