import { captureException } from '@sentry/react'
import { SupportedCountries } from '@wanda-space/noelle'
import { Locale } from '@wanda-space/types'
import type { FormatMessage } from './format'

export function linkFormatter(
  formatMessage: FormatMessage,
  {
    countryCode = SupportedCountries.NO,
    navigation,
    locale = Locale.Norwegian,
  }: {
    navigation?: string
    locale?: string
    countryCode?: SupportedCountries
  } = {}
): string {
  try {
    const formattedCountryCode = countryCode ? countryCode.toLowerCase() : undefined
    if (navigation === 'faq') {
      return formatMessage({ id: 'link.faq' })
    }

    const landingPageBaseUrl = formatMessage({ id: 'link.landing' })
    const trimmedLocale = trimLocale(locale, formattedCountryCode)

    const paths: string[] = ['']

    if (formattedCountryCode) {
      paths.push(formattedCountryCode)
    }

    paths.push(trimmedLocale)

    if (navigation) {
      paths.push(navigation)
    }

    const landingPageUrl = new URL(paths.join('/'), landingPageBaseUrl)

    return landingPageUrl.toString()
  } catch (error) {
    captureException(error)
    return '/'
  }
}

function trimLocale(locale: string, countryCode?: string): string {
  const localeSplit = locale.split('-')

  /*
   * We do not support swedish language for the norwegian page nor norwegian language
   * for the swedish page. Also, we have no support for no/no, just no/nb.
   * Also, se/en is still not created, so the user will be sent to se/sv.
   * We should REALLY either fix cross-language for sanity or remove it altogether.
   */

  const isUnsupportedLangNorway = localeSplit[0] === 'sv' && countryCode === 'no'
  const isUnsupportedLandSweden =
    (localeSplit[0] === 'no' || localeSplit[0] === 'en') && countryCode === 'se'

  if (isUnsupportedLangNorway) {
    return 'nb'
  }

  if (isUnsupportedLandSweden) {
    return 'sv'
  }

  if (localeSplit[0] === 'no') {
    return 'nb'
  }

  return localeSplit[0]
}
