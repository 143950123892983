import type { PostalCodeDto } from '@wanda-space/ops-types'
import type { PostalCodeFilters } from '@wanda-space/types'

import { request } from '../api-client'
import { opsBaseUrl } from '../config'

export async function fetchPostalCodes({
  city,
  country,
  postalCode,
}: PostalCodeFilters): Promise<PostalCodeDto[]> {
  return (
    await request<{ items: PostalCodeDto[]; count: number }>({
      baseUrl: opsBaseUrl,
      skipAuth: true,
      method: 'GET',
      url: '/v1/postal-codes/',
      params: {
        filters: {
          city,
          country,
          postalCode,
          enabled: true,
        },
      },
    })
  ).items
}
