import type {
  FlexDeliveryQuoteRequestDto,
  FlexDeliveryQuoteResponseDto,
  UserFeatureCheckResponseDto,
  UserFeatures,
  UserFeaturesResponseDto,
} from '@wanda-space/types'

import { request } from '../api-client'

export async function checkFeatureForUser(
  feature: UserFeatures
): Promise<UserFeatureCheckResponseDto> {
  return request({
    method: 'GET',
    url: `user-features/${feature}`,
  })
}

export async function getUserFeatures() {
  return request<UserFeaturesResponseDto>({
    method: 'GET',
    url: 'user-features',
  })
}

export async function getFlexDeliveryQuote(orderlines: FlexDeliveryQuoteRequestDto['orderlines']) {
  return request<FlexDeliveryQuoteResponseDto>({
    method: 'POST',
    url: 'user-features/flex-delivery/quote',
    body: { orderlines },
  })
}
