import { useQuery } from '@tanstack/react-query'
import { OrderType, ProductPriceType, UserFeatures } from '@wanda-space/types'
import type { OrderLineWithFullProductAndDiscount } from 'api-client'
import { checkFeatureForUser, getFlexDeliveryQuote } from 'api-client/lib/routes/userFeature'
import { useActiveUserLocation } from 'hooks/useActiveUserLocation'
import { useAppSelector } from 'hooks/useAppSelector'
import { useHideFields } from 'hooks/useHideFields'
import type { UserAddress } from 'interfaces/user'
import { useState } from 'react'
import { mapOrderLineToInputDto } from 'reduxStore/commonMappers'

import { AddressRenderState, type ContactInfoData } from './types'
import {
  hasFlexAddressPredicate,
  isAddressComplete,
  isFlexAddressDisabled,
  isOrderAddressSameAsUserAddress,
} from './utils'

export const useAddressRenderStates = () => {
  const [addressRenderState, setAddressRenderState] = useState(AddressRenderState.MOUNTING)

  const setInitialRenderState = (userAddress: UserAddress, orderAddress?: ContactInfoData) => {
    if (addressRenderState === AddressRenderState.MOUNTING) {
      if (isAddressComplete(userAddress)) {
        setAddressRenderState(AddressRenderState.USER_DEFAULT_ADDRESS)
      } else {
        setAddressRenderState(AddressRenderState.USER_DEFAULT_ADDRESS_INCOMPLETE)
      }
    }

    if (
      orderAddress &&
      isAddressComplete(orderAddress) &&
      isOrderAddressSameAsUserAddress(userAddress, orderAddress) === false &&
      addressRenderState !== AddressRenderState.ORDER_ADDRESS_ACTIVE
    ) {
      setAddressRenderState(AddressRenderState.ORDER_ADDRESS_ACTIVE)
    }
  }

  return {
    addressRenderState,
    setAddressRenderState,
    setInitialRenderState,
  }
}

export const useFlexAddress = (
  storageOrderlines?: OrderLineWithFullProductAndDiscount[],
  addonsOrderlines?: OrderLineWithFullProductAndDiscount[]
) => {
  const user = useAppSelector((state) => state.user.user)
  const isFlexAddressEnableForCountry: boolean =
    !!user?.countryCode && isFlexAddressDisabled(user.countryCode) === false

  const { isInitialLoading: isCheckingFlexAddress, data: flexFeatureSubscription } = useQuery(
    ['checkFlexDeliveryFeature'],
    () => checkFeatureForUser(UserFeatures.FLEX_ADDRESS),
    { enabled: isFlexAddressEnableForCountry }
  )

  const { isInitialLoading: isOneTimeFlexOrderlineLoading, data: _oneTimeFlexOrderline } = useQuery(
    ['getFlexDeliveryQuote'],
    () => storageOrderlines && getFlexDeliveryQuote(storageOrderlines.map(mapOrderLineToInputDto)),
    { enabled: !!storageOrderlines && isFlexAddressEnableForCountry }
  )

  const alreadyAddedOnetimeFlexAddress = addonsOrderlines?.find(
    hasFlexAddressPredicate(ProductPriceType.ONE_TIME)
  )
  let oneTimeFlexOrderline: any

  if (
    !isCheckingFlexAddress &&
    !flexFeatureSubscription?.hasFeature &&
    user &&
    !isOneTimeFlexOrderlineLoading
  ) {
    oneTimeFlexOrderline = _oneTimeFlexOrderline
  }

  return {
    isCheckingFlexAddress,
    isOneTimeFlexOrderlineLoading,
    hasFlexAddressSubscription: flexFeatureSubscription?.hasFeature,
    oneTimeFlexOrderline,
    alreadyAddedOnetimeFlexAddress,
    isFlexAddressEnableForCountry,
  }
}

export const useInitializeContactInfoData = () => {
  const { postalCode, country } = useActiveUserLocation()

  const contactInfo: ContactInfoData = {
    firstName: '',
    lastName: '',
    city: '',
    elevator: false,
    phoneNumber: '',
    street: '',
    postalCode,
    addressComment: '',
    companyName: '',
    countryCode: country,
    email: '',
  }

  return { contactInfo }
}

export const useContactInfoData = (orderAddress?: ContactInfoData) => {
  const user = useAppSelector((state) => state.user.user)
  const { postalCode } = useActiveUserLocation()

  let { contactInfo } = useInitializeContactInfoData()
  const hideFields = useHideFields()

  let isUserAddressComplete = false

  if (user?.address) {
    const {
      firstName,
      lastName,
      city,
      floorNumber,
      elevator,
      phoneNumber,
      street,
      addressComment,
      companyName,
    } = user?.address ?? {}

    contactInfo.firstName = firstName ?? ''
    contactInfo.lastName = lastName ?? ''
    contactInfo.city = city ?? ''
    contactInfo.phoneNumber = phoneNumber ?? ''
    contactInfo.elevator = elevator
    contactInfo.floorNumber = floorNumber
    contactInfo.postalCode = postalCode || ''
    contactInfo.street = street ?? ''
    contactInfo.addressComment = addressComment || ''
    contactInfo.companyName = companyName
  }

  if (orderAddress && isAddressComplete(orderAddress, hideFields)) {
    contactInfo = orderAddress
  }

  if (user?.address && isAddressComplete(user.address)) {
    isUserAddressComplete = true
  }

  return { contactInfo, userAddress: user?.address, accountId: user?.id, isUserAddressComplete }
}
