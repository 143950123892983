import { Spinner, useToken } from '@chakra-ui/react'
import { Elements } from '@stripe/react-stripe-js'
import type { StripeElementsOptions } from '@stripe/stripe-js'
import { SupportedCountries } from '@wanda-space/types'
import { useStripeContext } from 'contexts/stripe'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import React from 'react'
import { getCurrencyForCountryCode } from 'utils'
import { PaymentDetails } from './PaymentDetails'
import type { PaymentWrapperProps } from './types'

const defaultStripeOptions: StripeElementsOptions = {
  mode: 'setup',
  currency: getCurrencyForCountryCode(SupportedCountries.NO),
  setup_future_usage: 'off_session',
}

export const PaymentWrapper = (props: PaymentWrapperProps) => {
  const stripeContext = useStripeContext()
  const featureFlags = useFeatureFlags()
  const [ctaBlack900, red500, blue500] = useToken('colors', ['ctaBlack.900', 'red.500', 'blue.500'])
  const [fontFamily] = useToken('fonts', ['body'])
  const [fontSizeSm] = useToken('fontSizes', ['sm'])
  const [space7, space2] = useToken('space', ['7', '2'])

  const options = props.options ?? defaultStripeOptions

  if (stripeContext.loading || featureFlags.isInitialLoading) {
    return <Spinner />
  }

  return (
    <Elements
      stripe={stripeContext.stripe}
      options={{
        ...options,
        appearance: {
          variables: {
            colorPrimary: blue500,
            colorDanger: red500,
            fontFamily,
            fontSizeSm,
            spacingGridRow: space7,
          },
          rules: {
            '.Label': {
              color: ctaBlack900,
              textTransform: 'uppercase',
              marginBottom: space2,
            },
            '.Input': {
              fontWeight: '500',
            },
          },
        },
      }}
    >
      <PaymentDetails {...props} options={options} />
    </Elements>
  )
}
