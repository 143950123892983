import type { Address } from '@wanda-space/ops-types'
import { OrderType, type SupportedCountries } from '@wanda-space/types'
import type { ContactInfoData } from 'components/contact-info/types'
import type { FlattenedDeliveryInfo } from 'interfaces'
import type { UserAddress } from 'interfaces/user'
import { omit, toPairs } from 'ramda'

export const mapContactInfoDataToDeliveryInfo = (
  orderType: OrderType,
  contactInfo: ContactInfoData,
  warehouseAddress?: Address
): FlattenedDeliveryInfo => {
  if ([OrderType.CUSTOMER_COLLECTS, OrderType.CUSTOMER_DROP_OFF].includes(orderType)) {
    if (!warehouseAddress) {
      throw new Error('Warehouse address is required for warehouse visit')
    }
    return {
      ...omit(['addressComment'], contactInfo),
      extraDeliveryInfo: contactInfo.addressComment,
      shortStorage: false,
      contactLess: false,
      street: warehouseAddress.street,
      city: warehouseAddress.city,
      postalCode: warehouseAddress.postalCode,
      floorNumber: undefined,
      elevator: undefined,
    }
  }
  return {
    ...omit(['addressComment'], contactInfo),
    extraDeliveryInfo: contactInfo.addressComment,
    shortStorage: false,
    contactLess: false,
  }
}

export const mapDeliveryInfoToContactInfoData = (
  deliveryInfo: FlattenedDeliveryInfo,
  userCountry: SupportedCountries
): ContactInfoData => {
  const { floorNumber, elevator, countryCode, extraDeliveryInfo, ...rest } = deliveryInfo
  return {
    ...omit(['shortStorage', 'contactLess'], rest),
    floorNumber: floorNumber,
    elevator: elevator,
    countryCode: countryCode || userCountry,
    addressComment: extraDeliveryInfo,
  }
}

export const mapUserAddress = (address: Partial<UserAddress>): Partial<UserAddress> => {
  const addressPairs = toPairs(address)
  return addressPairs.reduce(
    (accumulator, [key, value]) => {
      const shouldAssignUndefined =
        (typeof value === 'string' && !value) || (Array.isArray(value) && value.length)
      if (shouldAssignUndefined) {
        // to prevent validation on empty string or empty array
        accumulator[key] = undefined
      }
      return accumulator
    },
    { ...address }
  )
}
