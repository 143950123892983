import type { FormatMessage } from 'utils'
import {
  ContactPersonSchema,
  firstName,
  floorNumber,
  lastName,
  phoneNumber,
} from 'utils/validators'
import * as Yup from 'yup'

import { FieldNames } from './types'

export const contactInfoFormSchema = (formatMessage: FormatMessage) => {
  const baseShape: { [key: string]: Yup.Schema<unknown, unknown> } = {
    [FieldNames.FIRST_NAME]: firstName(formatMessage),
    [FieldNames.LAST_NAME]: lastName(formatMessage),
    [FieldNames.EMAIL]: Yup.string()
      .email()
      .required(formatMessage({ id: 'validation.required' })),
    [FieldNames.PHONE_NUMBER]: phoneNumber(formatMessage).required(
      formatMessage({ id: 'validation.required' })
    ),
    [FieldNames.ADDRESS_COMMENT]: Yup.string(),
    [FieldNames.FLOOR_NUMBER]: floorNumber(formatMessage),
    [FieldNames.ELEVATOR]: Yup.boolean().required(formatMessage({ id: 'validation.required' })),
    contactPerson: ContactPersonSchema(formatMessage),
  }

  return Yup.object().shape(baseShape)
}

export function filterVisibleFields(
  baseShape: Yup.ObjectSchema<any>,
  hideFields: FieldNames[],
  disableList: FieldNames[]
) {
  return baseShape.omit(hideFields ?? []).omit(disableList ?? [])
}
