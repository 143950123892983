import { Box, List, ListIcon, ListItem, Text } from '@chakra-ui/react'
import { CheckIcon, HourGlassIcon, WandaTooltip } from '@wanda-space/noelle'
import type { AddonServiceCompletionStatus } from 'interfaces/listing'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

export const ListingAddonStatus = ({
  addonServicesCompletionStatus,
}: { addonServicesCompletionStatus: AddonServiceCompletionStatus[] }) => {
  const { formatMessage } = useIntl()

  const tooltipContent = useMemo(
    () =>
      addonServicesCompletionStatus.some((addonService) => !addonService.isComplete)
        ? formatMessage({ id: 'listing.addon.status.tooltip' })
        : null,
    [addonServicesCompletionStatus]
  )

  if (!addonServicesCompletionStatus.length) return null

  return (
    <Box>
      <Text fontSize="sm" color="neutralGray.700" casing="uppercase" w="100%">
        {formatMessage({ id: 'booking.addons' })}

        {tooltipContent && (
          <Text as="span" float="right" mt="-3">
            <WandaTooltip content={tooltipContent} />
          </Text>
        )}
      </Text>

      <List>
        {addonServicesCompletionStatus.map((addonService, idx) => (
          <ListItem key={addonService.listingAddonType} lineHeight="1.5em">
            <ListIcon as={addonService.isComplete ? CheckIcon : HourGlassIcon} />
            {formatMessage({ id: `${addonService.localizationKey}.name` })}
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
