import { request } from '../api-client'

const isLocalhost =
  typeof window !== 'undefined' ? window.location.hostname.includes('local') : true

export async function getTranslations(language: string): Promise<Record<string, string>> {
  return request<Record<string, string>>({
    method: 'GET',
    url: `translations/${language}?projects=spaceship&projects=products${
      isLocalhost ? '&noCache=true' : ''
    }`,
  })
}
