import { Button, type ButtonProps, Flex, Heading, Text } from '@chakra-ui/react'
import { Routes } from 'consts'
import React, { type ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

export interface BookingConfirmFooterProps {
  editOrderBtn?: {
    btnProps?: ButtonProps
    orderId: string
    show: boolean
  }
  addImagesBtn?: {
    btnProps?: ButtonProps
    show: boolean
  }
  detailsMessage: ReactNode
  titleKey?: string
}

export const BookingConfirmFooter = ({
  editOrderBtn,
  addImagesBtn,
  detailsMessage,
  titleKey,
}: BookingConfirmFooterProps) => {
  const { formatMessage } = useIntl()
  const location = useLocation()
  const navigate = useNavigate()
  const showEditOrderBtn = editOrderBtn?.show ?? false
  const showAddImagesBtn = addImagesBtn?.show ?? false

  return (
    <>
      <Flex flexDirection="column" mb={5}>
        <Heading as="h2" size="lg" fontWeight="normal">
          {formatMessage({ id: titleKey || 'booking.confirmation.order.forget' })}
        </Heading>
        <Text size="md" mt={3}>
          {detailsMessage}
        </Text>
      </Flex>
      <Flex gap={2} mb={showEditOrderBtn || showAddImagesBtn ? 3 : 0}>
        {showEditOrderBtn && (
          <Button
            variant="outline"
            width="100%"
            onClick={() => {
              navigate(Routes.EditOrderDetails(editOrderBtn!.orderId))
            }}
          >
            {formatMessage({ id: 'booking.confirmation.order.editOrder' })}
          </Button>
        )}
        {showAddImagesBtn && (
          <Button
            variant="outline"
            width="100%"
            onClick={() => {
              navigate(Routes.NoImageItemView, {
                state: {
                  referrer: location.pathname + location.search,
                },
              })
            }}
          >
            {formatMessage({ id: 'booking.confirmation.order.addImages' })}
          </Button>
        )}
      </Flex>
      <Button
        data-testid="continue-button"
        colorScheme="ctaBlack"
        width="100%"
        onClick={() => {
          navigate(Routes.Space)
        }}
      >
        {formatMessage({ id: 'phrase.goToProfile' })}
      </Button>
    </>
  )
}
