import {
  Link as ChakraLink,
  Container,
  Flex,
  HStack,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { CheckMarkIcon, InfoBox, WandaSpinner, useWandaToast } from '@wanda-space/noelle'
import { ListingBidStatus, type ListingResponseDto, ListingStatus } from '@wanda-space/types'
import {
  getBidsForListing,
  postListingImage,
  postListingPrimaryImage,
  updateListingItemImage,
  updateListingStatus,
} from 'api-client'
import { WarningModalContent } from 'components/Modals/WarningModal/WarningModal'
import { Routes } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import { withRequiredParams } from 'hooks/withRequiredParams'
import type { Stepable } from 'interfaces'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Navigate, useNavigate } from 'react-router'
import { getAdvertisementRoute } from 'routes/BuySellFlow/BuySellFlow'
import { ListingPreview } from 'routes/BuySellFlow/common/ListingPreview'
import { useListingPreviewDetails } from 'routes/BuySellFlow/common/hooks'
import { getListingAmountDetails, listingToPrice, pathTo, sanitizeAmount } from 'utils'

import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { EditListingModal } from './EditListingModal'
import { ForYourInformationBox } from './ForYourInformationBox'
import { InfoNotification } from './InfoNotification'

export const ListingPublished = withRequiredParams<{ simpleId: string }, Stepable>(
  ['simpleId'],
  function ListingPublished({ simpleId }) {
    const { formatMessage } = useIntl()
    const [isLargerThanmedium] = useMediaQuery('(min-width: 768px)')
    const [hasCopiedLink, setHasCopiedLink] = useState(false)
    const [hasCopiedInfo, setHasCopiedInfo] = useState(false)
    const listingDetails = useListingPreviewDetails(simpleId)

    const listingBids = useQuery(
      ['listingBids', listingDetails.listingResult.data?.id],
      () => {
        const { data: listing } = listingDetails.listingResult
        if (listing?.id) {
          return getBidsForListing(listing.id)
        }
      },
      { enabled: Boolean(listingDetails.listingResult.data?.id) }
    )
    const user = useAppSelector(({ user: { user } }) => user)
    const navigate = useNavigate()
    const { isOpen, onClose, onOpen } = useDisclosure()
    const toast = useWandaToast({
      durationInMilliSecond: 5000,
      position: 'bottom',
    })
    const {
      isOpen: editModalIsOpen,
      onClose: onEditModalClose,
      onOpen: onEditModalOpen,
    } = useDisclosure()

    const getLinkTitle = () => formatMessage({ id: 'info.buy.sell.share.link.title' })
    const getLinkBody = () => formatMessage({ id: 'info.buy.sell.share.link.body' })

    const getDisplayLink = (simpleId: string) =>
      `${window.location.origin}${pathTo(Routes.Buy, getAdvertisementRoute(simpleId))}`

    const handleCopy = (simpleId: string, info: boolean) => {
      const sharableLink = getDisplayLink(simpleId)
      if (info) {
        setHasCopiedInfo(true)
        setHasCopiedLink(false)
        const sharebleLinkBody = getLinkBody()
        const sharebleLinkTitle = getLinkTitle()
        navigator.clipboard.writeText(`${sharebleLinkTitle}\n${sharebleLinkBody}\n${sharableLink}`)
      } else {
        setHasCopiedLink(true)
        setHasCopiedInfo(false)
        navigator.clipboard.writeText(sharableLink)
      }
    }

    if (listingDetails.isLoading) {
      return <WandaSpinner />
    }

    if (listingDetails.isError || !listingDetails.listingResult.data) {
      throw (
        listingDetails.listingResult.error ||
        listingDetails.listingItemsResult.error ||
        listingDetails.listingImages.error
      )
    }

    const { data: listing } = listingDetails.listingResult

    const isNotListingOwnerOrIsInactiveListing =
      listing &&
      user &&
      (user.id !== listing.sellerAccountId || listing.status !== ListingStatus.ACTIVE)

    if (isNotListingOwnerOrIsInactiveListing) {
      return <Navigate to={Routes.Home} />
    }

    const uploadImages = async ({ images }: { images: string[] }) => {
      const hasPrimaryImage = Boolean(listing.image)
      const uploadPromises = images.map(async (image, index) => {
        const { imageId } = await postListingImage({
          listingId: listing.id,
          file: image,
        })
        if (!hasPrimaryImage && index === 0) {
          await postListingPrimaryImage(listing.id, imageId)
        }
      })

      await Promise.all(uploadPromises)
      await listingDetails.listingImages.refetch()
      if (!hasPrimaryImage) {
        const listingItemIds = listing.storageItems.map(({ id }) => id)
        await handleUploadListingImageForItem(images, listing.id, listingItemIds)
        await listingDetails.listingItemsResult.refetch()
      }

      toast({
        status: 'success',
        description: formatMessage({ id: 'word.image.added' }),
        variant: 'subtle',
      })
    }

    const handleUploadListingImageForItem = async (
      files: string[],
      listingId: string,
      itemIds: string[]
    ) => {
      const [primaryImage] = files
      if (primaryImage) {
        for (const itemId of itemIds) {
          await updateListingItemImage(listingId, itemId, primaryImage)
        }
      }
    }

    const handleDeleteSalesPage = async () => {
      try {
        await updateListingStatus(listing.id, {
          status: ListingStatus.INACTIVE,
        })
        toast({
          status: 'success',
          description: formatMessage({
            id: 'listing.delete.toast.success.description',
          }),
          variant: 'subtle',
        })
        return navigate(Routes.ViewListing(listing.simpleId))
      } catch (error) {
        captureException(error)
      }
    }

    const actionableNotification = (
      <InfoNotification
        onEditModalOpen={onEditModalOpen}
        isBuyableListing={listing.status === ListingStatus.ACTIVE}
        sellingFromHome
        hasCopiedLink={hasCopiedLink}
        hasCopiedInfo={hasCopiedInfo}
        handleCopy={handleCopy}
        simpleId={simpleId}
      />
    )

    const bidInfoBoxes = listingBids.data?.items.map((bid) => {
      const isActive =
        bid.status === ListingBidStatus.WAITING && listing.status === ListingStatus.ACTIVE

      return (
        <InfoBox
          mb={!isLargerThanmedium ? 4 : undefined}
          title={
            <HStack as="span">
              <Text as="span">
                {formatMessage(
                  { id: 'word.bid.for' },
                  { amount: <strong>{sanitizeAmount(bid.amount)},-</strong> }
                )}
              </Text>
              <ChakraLink
                textDecoration="underline"
                as={Link}
                to={Routes.ViewBid(bid.listingId, bid.id)}
              >
                {formatMessage({ id: 'word.seeMore' })}
              </ChakraLink>
            </HStack>
          }
          description=""
          borderWidth={1}
          borderColor={isActive ? 'green.100' : 'neutralGray.100'}
          backgroundColor={isActive ? 'green.50' : 'neutralGray.50'}
          key={bid.id}
          leftIcon={<CheckMarkIcon color={isActive ? 'green' : 'gray'} />}
        />
      )
    })

    const listingDimension = {
      length: listing.dimensionLength,
      width: listing.dimensionWidth,
      height: listing.dimensionHeight,
      freeText: listing.dimensionFreeText,
    }

    const price = listingToPrice(listingDetails.listingResult.data)

    const { sellerAmount, feeFlat, feePercent } = getListingAmountDetails(
      price,
      listingDetails.listingResult.data
    )

    return (
      <>
        <EditListingModal
          onEdited={() => {
            listingDetails.listingResult.refetch()
            listingDetails.listingItemsResult.refetch()
            listingDetails.listingImages.refetch()
          }}
          listingId={listing.id}
          name={listing.name}
          description={listing.description ?? ''}
          price={listing.price}
          currency={listing.currency}
          onClose={onEditModalClose}
          onOpen={onEditModalOpen}
          isOpen={editModalIsOpen}
          imageReferences={listingDetails.listingImages.data ?? []}
          conditionType={listing.conditionType}
          dimensions={listingDimension}
          extraDescriptiveInfo={listing.extraDescriptiveInfo}
        />

        <WarningModalContent
          title={formatMessage({ id: 'delete.sales.page.confirmation.title' })}
          description={formatMessage(
            { id: 'delete.sales.page.confirmation.description' },
            { name: listing?.name, bold: (value) => <b>{value}</b> }
          )}
          onClose={onClose}
          isOpen={isOpen}
          handleClickYes={handleDeleteSalesPage}
        />
        <Flex flexDirection={['column', 'column', 'row']} maxWidth="inherit">
          <Container pt="0">
            {!isLargerThanmedium ? (
              <>
                {bidInfoBoxes}
                {actionableNotification}
              </>
            ) : null}
            <ListingPreview listingDetails={listingDetails} />
          </Container>
          <Container maxW={['100%', '100%', 'xs']} p={['auto', 'auto', '0']}>
            <Flex gap={4} direction="column">
              {isLargerThanmedium ? (
                <>
                  {bidInfoBoxes}
                  {actionableNotification}
                </>
              ) : null}
              <ForYourInformationBox
                onOpen={onOpen}
                sellerAmount={sellerAmount}
                feeFlat={feeFlat}
                feePercent={feePercent}
                currency={listing.currency}
                uploadImages={uploadImages}
              />
            </Flex>
          </Container>
        </Flex>
      </>
    )
  }
)
