import { Box, Link } from '@chakra-ui/react'
import { SelectCard, type SelectCardProps } from '@wanda-space/noelle'
import { UserFeatures } from '@wanda-space/types'
import { AddonsInsuranceType } from 'consts'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import React from 'react'
import { useIntl } from 'react-intl'
import { getProductLocalizedName, sanitizeAmount } from 'utils'

export interface Props {
  activeInsurance?: boolean | string
  selectedInsurance?: string
  onChange: (data?: string) => void
  standardCardProps?: Partial<SelectCardProps>
  extraCardProps?: Partial<SelectCardProps>
}

const SelectAddonsInsurance = ({
  activeInsurance,
  selectedInsurance,
  onChange,
  standardCardProps,
  extraCardProps = {},
}: Props) => {
  const { userProducts } = useProductsAndCategories()
  const { showCheckMark, ...restExtraCardProps } = extraCardProps

  const { formatMessage, messages } = useIntl()
  const insuranceProducts = userProducts
    ? userProducts.filter((p) => p.metadata.userFeatureKey === UserFeatures.INSURANCE)
    : []
  const standardInsuranceKey = 'addons.standardInsurance'
  const hasMoreInfoLinkStandardInsurance = messages[`${standardInsuranceKey}.readMoreLink`]

  return (
    <Box width="100%">
      <Box mb="6">
        <SelectCard
          key={AddonsInsuranceType.STANDARD}
          title={formatMessage({ id: `${standardInsuranceKey}.title` })}
          description={
            <span>
              {formatMessage({ id: `${standardInsuranceKey}.description` })}
              {hasMoreInfoLinkStandardInsurance && (
                <Link
                  textDecoration="underline"
                  target="_blank"
                  ml="2"
                  href={formatMessage({ id: `${standardInsuranceKey}.readMoreLink` })}
                >
                  {formatMessage({ id: 'word.readMore' })}
                </Link>
              )}
            </span>
          }
          isDisabled={Boolean(activeInsurance)}
          active={!activeInsurance && !selectedInsurance}
          showCheckMark={!activeInsurance && !selectedInsurance}
          onClick={() => onChange()}
          badge={formatMessage({ id: 'word.included' })}
          {...standardCardProps}
        />
      </Box>
      {insuranceProducts.map((product) => {
        const moreInfoLinkKey = `${product.localizationKey}.readMoreLink`
        const hasMoreInfoLink = messages[moreInfoLinkKey]

        return (
          <Box key={product.id} mb={6}>
            <SelectCard
              title={getProductLocalizedName(product, formatMessage)}
              description={
                <span>
                  {formatMessage({ id: `${product.localizationKey}.description` })}
                  {hasMoreInfoLink && (
                    <Link
                      textDecoration="underline"
                      target="_blank"
                      ml="2"
                      href={formatMessage({ id: moreInfoLinkKey })}
                    >
                      {formatMessage({ id: 'word.readMore' })}
                    </Link>
                  )}
                </span>
              }
              isDisabled={Boolean(activeInsurance) && activeInsurance !== product.id}
              active={selectedInsurance === product.id || activeInsurance === product.id}
              onClick={() => activeInsurance !== product.id && onChange(product.id)}
              badge={`${sanitizeAmount(product.price)},- / ${formatMessage({
                id: 'word.monthShort',
              })}`}
              badges={
                activeInsurance === product.id
                  ? [{ title: formatMessage({ id: 'word.active' }), bg: 'green.200' }]
                  : []
              }
              showCheckMark={
                activeInsurance === product.id ? false : selectedInsurance === product.id
              }
              openBtnText={formatMessage({ id: 'word.open' })}
              closeBtnText={formatMessage({ id: 'word.close' })}
              {...restExtraCardProps}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export { SelectAddonsInsurance }
