import { SupportedCountries } from '@wanda-space/noelle'
import { OrderType } from '@wanda-space/types'
import { SelectTransportOrWarehouseVisitBase } from 'components/SelectTransportOrWarehouseVisitBase'
import { useAppSelector } from 'hooks/useAppSelector'
import type { Stepable } from 'interfaces'
import React from 'react'
import { useAppDispatch } from 'reduxStore'
import { setOrderType, setServiceLevel, submitAddress } from 'reduxStore/ducks/storage/storageFlow'

export function SelectTransportOrWarehouseVisit({ onNextStep }: Stepable) {
  const dispatch = useAppDispatch()
  const orderType = useAppSelector((state) => state.storageFlow.orderType)

  return (
    <SelectTransportOrWarehouseVisitBase
      selectedOrderType={orderType}
      taasOrderType={OrderType.PICK_UP}
      warehouseVisitOrderType={OrderType.CUSTOMER_DROP_OFF}
      onSelect={({ orderType, serviceLevel, warehouse }) => {
        dispatch(setOrderType(orderType))
        dispatch(setServiceLevel({ serviceLevelType: serviceLevel }))
        if (warehouse) {
          dispatch(
            submitAddress({
              extraDeliveryInfo: '',
              firstName: '',
              lastName: '',
              email: '',
              phoneNumber: '',
              contactLess: undefined,
              shortStorage: false,
              ...warehouse.address,
              countryCode: warehouse.address.countryCode as SupportedCountries,
            })
          )
        } else {
          dispatch(
            submitAddress({
              extraDeliveryInfo: '',
              firstName: '',
              lastName: '',
              email: '',
              phoneNumber: '',
              contactLess: undefined,
              shortStorage: false,
              city: '',
              postalCode: '',
              street: '',
              countryCode: SupportedCountries.NO,
            })
          )
        }
      }}
      onNextStep={onNextStep}
    />
  )
}
