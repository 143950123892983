import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  type CreateServiceOrderWithPickupResponseDto,
  type DiscountResponseDto,
  OrderType,
  type ProductCategoryResponseDto,
  type ServiceLevelType,
} from '@wanda-space/types'
import type { DateAndTime, OrderLineWithFullProductAndDiscount, Product } from 'api-client'
import type { ContactPerson } from 'components/contact-info/types'
import type { FlattenedDeliveryInfo, PriceWrapper } from 'interfaces'
import { getAllOrderlinesWithDiscounts } from 'utils'

import { SliceNames } from '../../constants'

export interface ServicesFlowState {
  logisticsOrderType: OrderType
  dateAndTime: DateAndTime
  address: FlattenedDeliveryInfo
  serviceLevel?: ServiceLevelType
  taasPrice: PriceWrapper
  selectedCategory?: ProductCategoryResponseDto
  serviceOrderLines: OrderLineWithFullProductAndDiscount[]
  storageOrderLines: OrderLineWithFullProductAndDiscount[]
  timeslotOrderLines: OrderLineWithFullProductAndDiscount[]
  taasOrderlines: OrderLineWithFullProductAndDiscount[]
  contactPerson?: ContactPerson
  orderSuccessPayload: CreateServiceOrderWithPickupResponseDto | null
  coupon?: string
}

const initialState: ServicesFlowState = {
  logisticsOrderType: OrderType.PICK_UP,
  dateAndTime: {},
  address: {} as FlattenedDeliveryInfo,
  taasPrice: {} as PriceWrapper,
  serviceOrderLines: [] as OrderLineWithFullProductAndDiscount[],
  storageOrderLines: [] as OrderLineWithFullProductAndDiscount[],
  timeslotOrderLines: [] as OrderLineWithFullProductAndDiscount[],
  taasOrderlines: [] as OrderLineWithFullProductAndDiscount[],
  orderSuccessPayload: null,
}

export const servicesFlowNextStateSlice = createSlice({
  name: SliceNames.SERVICE,
  initialState,
  reducers: {
    setSelectedCategory: (state, action: PayloadAction<ProductCategoryResponseDto>) => {
      if (state.selectedCategory !== action.payload) {
        state.selectedCategory = action.payload
        state.serviceOrderLines = []
        state.storageOrderLines = []
      }
    },
    updateDateAndTime: (state, action: PayloadAction<DateAndTime>) => {
      state.dateAndTime = action.payload
    },
    setContactPerson: (state, { payload }: PayloadAction<ContactPerson>) => {
      state.contactPerson = payload
    },
    setPickupAddress: (state, action: PayloadAction<FlattenedDeliveryInfo>) => {
      state.address = action.payload
    },

    setServiceLevel: (state, action: PayloadAction<ServiceLevelType>) => {
      state.serviceLevel = action.payload
    },
    updateServiceLevelWithPrice: (
      state,
      action: PayloadAction<{ serviceLevel: ServiceLevelType; taasPrice: PriceWrapper }>
    ) => {
      state.serviceLevel = action.payload.serviceLevel
      state.taasPrice = action.payload.taasPrice
    },
    setServiceOrderLines: (state, action: PayloadAction<OrderLineWithFullProductAndDiscount[]>) => {
      state.serviceOrderLines = action.payload
    },
    setStorageOrderLines: (state, action: PayloadAction<OrderLineWithFullProductAndDiscount[]>) => {
      state.storageOrderLines = action.payload
    },
    addStorageOrderLine: (state, action: PayloadAction<OrderLineWithFullProductAndDiscount>) => {
      state.storageOrderLines.push(action.payload)
    },
    addServiceOrderLine: (state, action: PayloadAction<OrderLineWithFullProductAndDiscount>) => {
      state.serviceOrderLines.push(action.payload)
    },
    setTaasOrderLines: (state, action: PayloadAction<OrderLineWithFullProductAndDiscount[]>) => {
      state.taasOrderlines = action.payload
    },
    updateCommentOnServiceOrderLine: (
      state,
      action: PayloadAction<{ index: number; comment: string }>
    ) => {
      const { comment, index } = action.payload
      state.serviceOrderLines[index].comment = comment
    },
    removeStorageOrderLine: (state, action: PayloadAction<string>) => {
      state.storageOrderLines = state.storageOrderLines.filter(
        (ol) => ol.item!.id !== action.payload
      )
      state.serviceOrderLines = state.serviceOrderLines.filter(
        (ol) => ol.item!.id !== action.payload
      )
    },
    pruneStorageOrderLines: (state) => {
      const itemIdsWithService = state.serviceOrderLines.map((sol) => sol.item!.id)
      state.storageOrderLines = state.storageOrderLines.filter((ol) =>
        itemIdsWithService.includes(ol.item!.id)
      )
    },
    removeServiceOrderLine: (state, action: PayloadAction<number>) => {
      state.serviceOrderLines.splice(action.payload, 1)
    },
    setOrderSuccessPayload: (
      state,
      action: PayloadAction<CreateServiceOrderWithPickupResponseDto>
    ) => {
      state.orderSuccessPayload = action.payload
    },
    setTimeslotOrderlinesService: (
      state,
      action: PayloadAction<OrderLineWithFullProductAndDiscount[]>
    ) => {
      state.timeslotOrderLines = action.payload
    },
    resetAll: () => {
      return initialState
    },
    applyDiscounts: (
      state,
      action: PayloadAction<{ coupon?: string; discounts: DiscountResponseDto[] }>
    ) => {
      if (action.payload.coupon) {
        state.coupon = action.payload.coupon
      }
      const { services, storage, timeslot, taas } = getAllOrderlinesWithDiscounts(
        [],
        [],
        state.storageOrderLines,
        state.taasOrderlines,
        state.timeslotOrderLines,
        state.serviceOrderLines,
        action.payload.discounts
      )

      state.serviceOrderLines = services
      state.timeslotOrderLines = timeslot
      state.storageOrderLines = storage
      state.taasOrderlines = taas
    },
  },
})

export const {
  setSelectedCategory,
  setPickupAddress,
  updateServiceLevelWithPrice,
  updateDateAndTime,
  setServiceOrderLines,
  setStorageOrderLines,
  setServiceLevel,
  setOrderSuccessPayload,
  resetAll,
  setContactPerson,
  addStorageOrderLine,
  addServiceOrderLine,
  removeStorageOrderLine,
  removeServiceOrderLine,
  updateCommentOnServiceOrderLine,
  setTimeslotOrderlinesService,
  pruneStorageOrderLines,
  applyDiscounts,
  setTaasOrderLines,
} = servicesFlowNextStateSlice.actions

export const servicesFlowNext = servicesFlowNextStateSlice.reducer
