import { createSelector } from '@reduxjs/toolkit'
import { type ItemResponseDto, ListingAddonType } from '@wanda-space/types'
import type { ListingForPreviewType, StorageItemsType } from 'interfaces/listing'
import type { RootState } from 'reduxStore'
import type { ListingForPickup, ListingForStorage, ListingFormValues } from './sellFlow'

export function getSelectedItemsFromStorageToSell(state: RootState): ItemResponseDto[] {
  return state.sellFlow.storageItems
}

export const getListingFromStorageState = (state: RootState): ListingForStorage => state.sellFlow
export const orderContactPersonSelector = (state: RootState) =>
  state.sellWithPickupFlow.contactPerson

export const getListingWithPickupFromState = (state: RootState): ListingForPickup =>
  state.sellWithPickupFlow

type ReturnListingFormData = (state: RootState) => ListingFormValues

export const getListingFormState = (isPickup: boolean): ReturnListingFormData =>
  createSelector(
    [(state: RootState) => (isPickup ? state.sellWithPickupFlow : state.sellFlow)],
    (relevantState) => relevantState.formValues as ListingFormValues
  )

type ReturnListingForPreviewType<T extends boolean> = (state: RootState) => ListingForPreviewType<T>
export const getListingForPreview = <T extends boolean>(
  isPickup: T
): ReturnListingForPreviewType<T> =>
  createSelector(
    [(state: RootState) => (isPickup ? state.sellWithPickupFlow : state.sellFlow)],
    (relevantState) => {
      const addonServicesCompletionStatus = relevantState.addons.map((addonType) => {
        const localizationKey = relevantState.uiState.addonLocalizationKeys[addonType]
        return {
          isComplete: false,
          localizationKey,
          listingAddonType: addonType,
        }
      })

      return {
        ...relevantState.formValues,
        storageItems: relevantState.storageItems as StorageItemsType<T>,
        inspectedByWanda:
          relevantState.uiState.selectedAddons[ListingAddonType.INSPECTION] || false,
        addons: relevantState.addons,
        coupon: relevantState.coupon || undefined,
        addonServicesCompletionStatus,
      } as ListingForPreviewType<T>
    }
  )
