import { Box, Button, Collapse, Divider, Flex, Link, Text } from '@chakra-ui/react'
import {
  Alert,
  CalendarWithLinesIcon,
  MapPinIcon,
  MoneyCoinsIcon,
  RefreshIcon,
  UserSettingIcon,
  WSkeleton,
} from '@wanda-space/noelle'
import type { DiscountResponseDto, SupportedCountries } from '@wanda-space/types'
import type { Product } from 'api-client'
import { PaymentWrapper } from 'components/PaymentOptions/PaymentWrapper'
import { MinimalPriceSummaryRow, PriceSummaryRow } from 'components/Price/PriceSummaryRow'
import { CreditsDiscounts } from 'components/SummaryComponents/CreditsDiscounts'
import { useDateLocale } from 'contexts/Intl'
import { format } from 'date-fns'
import { useAuth } from 'hooks/useAuth'
import { sum } from 'ramda'
import React from 'react'
import { type ReactElement, useState } from 'react'
import { useIntl } from 'react-intl'
import type { ActionCreator, AnyAction } from 'redux'
import { useAppDispatch } from 'reduxStore'
import { Coupon } from 'routes/Storage/OldComponents/Summary/Coupon'
import { getCurrencyForCountryCode, hasDiscountedPrice } from 'utils'
import { formatTimeSlot } from 'utils/timeslot-utils'

import { PaymentRedirectionModal } from 'components/PaymentOptions/PaymentRedirectionModal'
import { useStripeRedirectHandler } from 'hooks/useStripeRedirectHandler'
import { ObosMembershipField } from './ObosMembershipField'

export interface PaymentItem {
  name: string
  price: number
  oldPrice?: number
  discount?: number
}
export interface OneTimePaymentItem extends PaymentItem {
  product?: Product
  isTaas?: boolean
  oldPrice?: number
}

interface PaymentStepBaseProps {
  error: string | undefined
  onObosMembershipActivation: (products: DiscountResponseDto[]) => void
  onNextStep: () => void
  addCoupon: ActionCreator<AnyAction>
  coupon: string | undefined
  totalSubscriptionCost?: number | string | React.ReactNode
  amountOfStoredItems: number
  order?: {
    address: string
    ownerName: string
    date?: Date
    timeslot?: { from: string; to: string }
  }
  disableOfferInvoiceOption: boolean
  createOrder: (paymentMethodId?: string | undefined) => Promise<void>
  isSubmittingOrder: boolean
  hasActiveOrderLimitReached: boolean
  isTimeslotOpen: boolean
  changeAddress: string
  changeDate: string
  totalTransactionPrice?: number
  oneTimePayment: {
    isService?: boolean
    priceDescription?: string
    price: number
    oneTimeServices: { name: string }[] | ReactElement
    oldPrice?: number
    items: OneTimePaymentItem[] | ReactElement
    defaultCollapsed: boolean
    isLoading?: boolean
  }
  monthlyPayment?: {
    pricePrefix?: string
    price: number
    oldPrice?: number
    amountOfThings: number | string
    optionalDescriptionField?: string
    items?: PaymentItem[] | ReactElement
    isLoading?: boolean
  }
  country: SupportedCountries
  hideYouPayNow?: boolean
  showAddress?: boolean
}

export const PaymentStepBase = ({
  error,
  totalSubscriptionCost,
  onNextStep,
  amountOfStoredItems,
  disableOfferInvoiceOption,
  createOrder,
  isSubmittingOrder,
  hasActiveOrderLimitReached,
  isTimeslotOpen,
  order,
  changeAddress,
  changeDate,
  oneTimePayment,
  totalTransactionPrice,
  monthlyPayment,
  addCoupon,
  coupon,
  country,
  onObosMembershipActivation,
  hideYouPayNow = false,
}: PaymentStepBaseProps) => {
  const { formatMessage } = useIntl()
  const { isAuthenticated } = useAuth()
  const dispatch = useAppDispatch()
  const { hasPaymentSucceded } = useStripeRedirectHandler()
  const [isOneTimeCollapseOpen, setIsOneTimeCollapseOpen] = useState(false)
  const [isMonthlyCollapseOpen, setIsMonthlyCollapseOpen] = useState(false)
  const [wandaCredit, setWandaCredit] = useState(0)
  const dateLocale = useDateLocale()
  const totalTransactionPriceWithWandaCreditsApplied = Math.max(
    (totalTransactionPrice || 0) - wandaCredit,
    0
  )

  const margin = 4
  const smallGap = 2
  const padding = 6

  const marginLeftWithoutIcon = 8

  const getTotalDiscountForOrder = () => {
    const totalOneTimeDiscount: number = Array.isArray(oneTimePayment.items)
      ? sum(oneTimePayment.items.map((item) => item.discount ?? 0))
      : 0
    const monthlyPaymentDiscount =
      monthlyPayment?.items && Array.isArray(monthlyPayment.items)
        ? sum(monthlyPayment.items.map((item) => item.discount ?? 0))
        : 0
    return totalOneTimeDiscount === 0 && monthlyPaymentDiscount === 0
      ? undefined
      : totalOneTimeDiscount + monthlyPaymentDiscount
  }

  return (
    <>
      <PaymentRedirectionModal
        isOpen={hasPaymentSucceded && !error}
        text={formatMessage({ id: 'word.placing.order' })}
      />
      <Flex direction="column">
        <Flex gap={margin} direction="column">
          {order && (
            <>
              <Text fontSize="large" fontWeight="medium">
                {formatMessage({ id: 'paymentstep.subtitle' })}
              </Text>
              <Flex
                direction="column"
                gap={margin}
                color="gray.600"
                p={padding}
                outline="1px solid"
                borderRadius="md"
                outlineColor="gray.200"
              >
                <Flex gap={smallGap}>
                  <UserSettingIcon color="gray.600" />
                  <Text>{order.ownerName}</Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Flex gap={smallGap}>
                    <MapPinIcon color="gray.600" />
                    <Text>{order.address}</Text>
                  </Flex>
                  <Link href={changeAddress} textDecoration="underline">
                    {formatMessage({ id: 'word.change' })}
                  </Link>
                </Flex>
                <Flex justifyContent="space-between">
                  <Flex gap={smallGap}>
                    <CalendarWithLinesIcon color="gray.600" />
                    <Text>
                      {order.date &&
                        format(order.date, 'do MMMM yyyy', {
                          locale: dateLocale,
                        })}
                    </Text>
                    <Text> {formatTimeSlot(order.timeslot)}</Text>
                  </Flex>
                  <Link href={changeDate} textDecoration="underline">
                    {formatMessage({ id: 'word.change' })}
                  </Link>
                </Flex>
              </Flex>
            </>
          )}
          <Text fontSize="large" fontWeight="medium">
            {formatMessage({ id: 'word.payment' })}
          </Text>
          {/* One-time Payment */}
          <Flex
            direction="column"
            gap={margin}
            color="gray.600"
            p={padding}
            outline="1px solid"
            borderRadius="md"
            outlineColor="gray.200"
          >
            <Flex color="gray.600" direction="column" gap={smallGap}>
              <Flex justifyContent="space-between" alignItems="center">
                <Flex alignItems="center">
                  <MoneyCoinsIcon mr={smallGap} />
                  <Text fontSize="large" fontWeight="medium" color="black">
                    {formatMessage({ id: 'payment.summary.one_time.title' })}
                  </Text>
                </Flex>
                {oneTimePayment.isLoading ? (
                  <WSkeleton height="20px" width="16" />
                ) : hasDiscountedPrice({
                    oldPrice: oneTimePayment.oldPrice,
                    amount: oneTimePayment.price,
                  }) && oneTimePayment.oldPrice ? (
                  <Text textDecoration="line-through">{oneTimePayment.oldPrice.toFixed(2)},-</Text>
                ) : null}
                {oneTimePayment.isLoading ? (
                  <WSkeleton height="20px" width="20" />
                ) : (
                  <Text
                    textTransform="lowercase"
                    fontSize="large"
                    fontWeight="medium"
                    color="black"
                  >
                    {oneTimePayment.priceDescription}
                    {`${oneTimePayment.price.toFixed(2)},-`}
                  </Text>
                )}
              </Flex>
              <Flex
                ml={marginLeftWithoutIcon}
                justifyContent="space-between"
                mr={margin}
                mb={margin}
              >
                {Array.isArray(oneTimePayment.oneTimeServices) ? (
                  <Text>
                    {oneTimePayment.oneTimeServices.map((services) => services.name).join(', ')}
                  </Text>
                ) : (
                  oneTimePayment.oneTimeServices
                )}
              </Flex>
              <Flex textAlign="left" gap={smallGap} direction="column" ml={marginLeftWithoutIcon}>
                <Collapse in={isOneTimeCollapseOpen}>
                  {Array.isArray(oneTimePayment.items)
                    ? oneTimePayment.isService
                      ? oneTimePayment.items.map((item, i) => {
                          return (
                            <PriceSummaryRow
                              key={i}
                              title={item.name}
                              description={
                                item.isTaas
                                  ? ''
                                  : formatMessage({
                                      id: `${item?.product?.localizationKey}.name`,
                                    })
                              }
                              price={item.price}
                              oldPrice={item.oldPrice}
                              isLegend={false}
                            />
                          )
                        })
                      : oneTimePayment.items.map((item, i) => {
                          if (item.price !== 0) {
                            return (
                              <MinimalPriceSummaryRow
                                key={i}
                                title={item.name}
                                price={item.price}
                                oldPrice={item.oldPrice}
                              />
                            )
                          }
                        })
                    : oneTimePayment.items}
                </Collapse>
                <Text
                  color="black"
                  textDecoration="underline"
                  variant="link"
                  as="button"
                  alignSelf="flex-start"
                  p="0"
                  m="0"
                  onClick={() => setIsOneTimeCollapseOpen(!isOneTimeCollapseOpen)}
                >
                  {formatMessage({
                    id: isOneTimeCollapseOpen ? 'word.dismiss' : 'word.see.details',
                  })}
                </Text>
              </Flex>
            </Flex>

            {/* Monthly Payment */}

            {monthlyPayment && (
              <>
                <Divider />
                <Flex color="gray.600" direction="column" gap={smallGap}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                      <RefreshIcon mr={smallGap} />
                      <Text fontSize="large" fontWeight="medium" color="black">
                        {formatMessage({
                          id: 'payment.summary.recurring.title',
                        })}
                      </Text>
                    </Flex>
                    {monthlyPayment.isLoading ? (
                      <WSkeleton height="20px" width="16" />
                    ) : hasDiscountedPrice({
                        oldPrice: monthlyPayment.oldPrice,
                        amount: monthlyPayment.price,
                      }) && monthlyPayment.oldPrice ? (
                      <Text textDecoration="line-through">
                        {monthlyPayment.oldPrice.toFixed(2)},-
                      </Text>
                    ) : null}
                    {monthlyPayment.isLoading ? (
                      <WSkeleton height="20px" width="20" />
                    ) : (
                      <Text fontSize="large" fontWeight="medium" color="black">
                        {monthlyPayment.pricePrefix && monthlyPayment.pricePrefix}
                        {monthlyPayment.price.toFixed(2)},-
                      </Text>
                    )}
                  </Flex>
                  <Flex ml={marginLeftWithoutIcon} justifyContent="space-between" mb={margin}>
                    <Text>
                      {typeof monthlyPayment.amountOfThings === 'number'
                        ? formatMessage(
                            { id: 'paymentStep.monthly.storage' },
                            { amount: monthlyPayment.amountOfThings }
                          )
                        : monthlyPayment.amountOfThings}
                    </Text>
                  </Flex>
                  {(monthlyPayment.items || monthlyPayment?.optionalDescriptionField) && (
                    <Flex
                      textAlign="left"
                      gap={smallGap}
                      direction="column"
                      ml={marginLeftWithoutIcon}
                    >
                      <Collapse in={isMonthlyCollapseOpen}>
                        <Box>
                          {Array.isArray(monthlyPayment.items)
                            ? monthlyPayment.items.map((item, i) => {
                                const pricePlusDiscount = item.price + (item?.discount ?? 0)
                                return (
                                  <MinimalPriceSummaryRow
                                    key={i}
                                    title={item.name}
                                    price={item.price}
                                    oldPrice={item.oldPrice ?? pricePlusDiscount}
                                  />
                                )
                              })
                            : monthlyPayment.items}
                          {monthlyPayment?.optionalDescriptionField && (
                            <Text color="gray.600">{monthlyPayment.optionalDescriptionField}</Text>
                          )}
                        </Box>
                      </Collapse>
                      <Text
                        color="black"
                        textDecoration="underline"
                        variant="link"
                        as="button"
                        alignSelf="flex-start"
                        p="0"
                        m="0"
                        onClick={() => setIsMonthlyCollapseOpen(!isMonthlyCollapseOpen)}
                      >
                        {formatMessage({
                          id: isMonthlyCollapseOpen ? 'word.dismiss' : 'word.see.details',
                        })}
                      </Text>
                    </Flex>
                  )}
                </Flex>
                <Divider />
                {/* Wanda credit */}
                {totalTransactionPrice ? (
                  <Box ml={marginLeftWithoutIcon}>
                    <CreditsDiscounts
                      totalTransactionPrice={totalTransactionPrice}
                      totalDiscount={getTotalDiscountForOrder()}
                      setWandaCredit={setWandaCredit}
                    />
                  </Box>
                ) : null}
              </>
            )}
            {!hideYouPayNow && (
              <Flex
                pl={padding}
                pr={margin}
                py={margin}
                borderRadius="md"
                bg="purple.100"
                direction="column"
              >
                <Flex width="100%" justifyContent="space-between">
                  <Text fontSize="large" fontWeight="medium" color="black">
                    {formatMessage({ id: 'payment.summary.total.title' })}
                  </Text>
                  <Text fontSize="large" fontWeight="medium" color="black">
                    {totalTransactionPriceWithWandaCreditsApplied.toFixed(2)},-
                  </Text>
                </Flex>
                <Text mt={smallGap}>{formatMessage({ id: 'summary.box.total.mva' })}</Text>
              </Flex>
            )}
          </Flex>
          {addCoupon && isAuthenticated && (
            <Coupon
              newStyling={true}
              coupon={coupon ?? ''}
              onSuccessCoupon={(discounts, coupon) => dispatch(addCoupon(discounts, coupon))}
            />
          )}
          {isAuthenticated && (
            <ObosMembershipField
              padding={padding}
              smallGap={smallGap}
              coupon={coupon}
              onMembershipActivation={onObosMembershipActivation}
            />
          )}
          {!!totalSubscriptionCost && (
            <Flex
              direction="column"
              gap={margin}
              color="gray.600"
              p={padding}
              outline="1px solid"
              borderRadius="md"
              outlineColor="gray.200"
            >
              <Text fontSize="large" fontWeight="medium" color="black">
                {formatMessage({ id: 'word.yourSubscription' })}
              </Text>
              <Text>
                {typeof totalSubscriptionCost === 'number'
                  ? formatMessage(
                      { id: 'payment.subscriptionSummary' },
                      {
                        amountOfStoredItems: amountOfStoredItems ?? 0,
                        totalSubscriptionCost: totalSubscriptionCost ?? 0,
                        bold: (price) => (
                          <Text as="span" fontWeight="medium">
                            {price}
                          </Text>
                        ),
                      }
                    )
                  : totalSubscriptionCost}
              </Text>
            </Flex>
          )}
          {isAuthenticated && (
            <PaymentWrapper
              error={error}
              disableOfferInvoiceOption={disableOfferInvoiceOption}
              onSuccess={createOrder}
              buttonLoading={isSubmittingOrder}
              disableButton={isSubmittingOrder || hasActiveOrderLimitReached || !isTimeslotOpen}
              options={{
                mode: 'setup',
                currency: getCurrencyForCountryCode(country),
              }}
            />
          )}

          {!isAuthenticated && (
            <Button
              data-testid="continue-button"
              colorScheme="ctaBlack"
              isDisabled={!isTimeslotOpen}
              size="lg"
              onClick={() => onNextStep()}
              width="100%"
            >
              {formatMessage({ id: 'word.login.and.pay' })}
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  )
}
