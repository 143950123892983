import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import {
  BoxDeliveryIcon,
  EmptyStateBox,
  ExclamationMarkInHexagonIcon,
  M2SubscriptionsInfoBox,
} from '@wanda-space/noelle'
import {
  CategoryLeadTime,
  type ItemResponseDto,
  type UsedM2SpaceResponseDto,
} from '@wanda-space/types'
import type { Product } from 'api-client'
import { ItemList, type ItemListProps } from 'components/Item/List/ItemList'
import { useAppSelector } from 'hooks/useAppSelector'
import { useM2Subscriptions, useM2UsedSpace } from 'hooks/useM2Subscriptions'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import { useTaasCategories } from 'hooks/useTaasCategories'
import type { PriceWrapper, Stepable } from 'interfaces'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'reduxStore'
import { getItems, getItemsAvailableForDelivery } from 'reduxStore/ducks/item'
import { setItems } from 'reduxStore/ducks/returnFlow/reducers'
import { isProductSquareMeter } from 'routes/SquareMeterFlow/helper'
import { formatPriceWrapper, sanitizeAmount, toSupportedCurrency } from 'utils'
import { countByItemType } from 'utils/item'
import { thresholdMetMap } from 'utils/product-utils'

const getUsedSpaceAfterReturnOrderItems = (
  selectedItems: ItemResponseDto[],
  subscription: UsedM2SpaceResponseDto
) => {
  const { subscriptionInfo, activeOrdersSize } = subscription
  const totalAvailableSize = subscriptionInfo.m2Size + activeOrdersSize.m2Size
  const m2LoadUnit = subscriptionInfo.m2Size / subscriptionInfo.load
  const totalReturnOrderItemsLoad = selectedItems.reduce((acc, item) => acc + (item.load || 0), 0)
  const usedSpace = subscription.usedSpace.load
  const newUsedSpace = usedSpace - totalReturnOrderItemsLoad

  return {
    usedSpace: Number((newUsedSpace * m2LoadUnit).toFixed(2)),
    totalAvailableSize: totalAvailableSize,
  }
}

const getNextTier = (params: {
  usedM2Size: number
  allM2Products: any[]
}) => {
  const { usedM2Size } = params
  const nextTierProducts = params.allM2Products
    .filter((p) => p.metadata.m2Size > usedM2Size)
    .sort((a, b) => a.metadata.m2Size - b.metadata.m2Size)
  const nextTier = nextTierProducts[0]

  if (nextTier) {
    return nextTier
  }
}

export const SelectItems = ({ onNextStep }: Stepable) => {
  const dispatch = useAppDispatch()
  const selectedItems = useAppSelector((state) => state.returnFlow.items)
  const availableItems = useAppSelector(getItemsAvailableForDelivery())
  const itemCountByType = countByItemType(selectedItems)
  const taasCategories = useTaasCategories()
  const { formatMessage } = useIntl()
  const status = useAppSelector((state) => state.item.status)
  const { hasSquareMeterSubscriptions, isLoading: isLoadingSquareMeterCheck } = useM2Subscriptions()
  const { userProducts } = useProductsAndCategories({
    enabled: Boolean(hasSquareMeterSubscriptions),
  })
  const { data: usedSpace, isInitialLoading: isLoadingUsage } = useM2UsedSpace({
    enabled: Boolean(hasSquareMeterSubscriptions),
  })

  let newUsedSpace: { usedSpace: number; totalAvailableSize: number } | undefined
  let nextTier: Product | undefined

  if (usedSpace && userProducts) {
    const m2Products = userProducts.filter(isProductSquareMeter)
    newUsedSpace = getUsedSpaceAfterReturnOrderItems(selectedItems, usedSpace)
    nextTier = getNextTier({
      usedM2Size: newUsedSpace.usedSpace,
      allM2Products: m2Products,
    })
  }

  useEffect(() => {
    dispatch(getItems())
  }, [])

  const handleSelect = (selectedItems: ItemResponseDto[]) => {
    dispatch(setItems(selectedItems))
  }

  const thresholdMap = thresholdMetMap(
    itemCountByType,
    taasCategories.data?.find((t) => t.leadTimeDays === CategoryLeadTime.ASK)?.ranges
  )

  const isLoading = status === 'loading' || isLoadingSquareMeterCheck

  if (status === 'failed') {
    throw new Error('Error loading items')
  }

  if (!isLoading && availableItems.length === 0) {
    return (
      <EmptyStateBox
        icon={<ExclamationMarkInHexagonIcon w="16" h="16" />}
        text={formatMessage({ id: 'returnflow.selectItems.emptyState' })}
      />
    )
  }

  const areItemsWithListingSelected =
    selectedItems.length && selectedItems.some((item) => Boolean(item.listingId))

  const props: ItemListProps = {
    isLoading,
    items: availableItems,
    handleSubmit: onNextStep,
    handleSelect,
    preselectedItems: selectedItems,
    thresholdMap,
    paginate: true,
    shouldDisableNextStep: areItemsWithListingSelected
      ? { areItemsWithListingSelected }
      : undefined,
  }

  return (
    <>
      {hasSquareMeterSubscriptions &&
        (!isLoadingUsage && newUsedSpace?.usedSpace === 0 ? (
          <Box
            mt="3"
            mb="5"
            borderRadius="6"
            py="12"
            px="6"
            border="1px solid"
            borderColor="gray.100"
            boxShadow="sm"
            textAlign="center"
            bg="gray.50"
          >
            <Flex justifyContent="center">
              <BoxDeliveryIcon color="gray.500" width="16" height="16" />
            </Flex>
            <Heading fontWeight="normal" fontSize="xl" mt="5" mb="3">
              {formatMessage({ id: 'return.flow.m2.subsciption.info.title' })}
            </Heading>
            <Text color="gray.600">
              {formatMessage({ id: 'return.flow.m2.size' })}:&nbsp;
              <Text as="span" fontWeight="medium">
                {usedSpace?.subscriptionInfo.m2Size}m&sup2;
              </Text>
            </Text>
            <Text color="gray.600">
              {formatMessage({ id: 'return.flow.m2.cost' })}:&nbsp;
              <Text as="span" fontWeight="medium">
                {formatPriceWrapper(
                  {
                    ...usedSpace?.subscriptionInfo.price,
                    amount: sanitizeAmount(usedSpace?.subscriptionInfo.price.amount as number),
                  } as PriceWrapper,
                  true,
                  formatMessage
                )}
              </Text>
            </Text>
          </Box>
        ) : (
          <M2SubscriptionsInfoBox
            usedSpace={newUsedSpace?.usedSpace || 0}
            availableSpace={nextTier?.metadata.m2Size || newUsedSpace?.totalAvailableSize || 0}
            isLoadingUsage={isLoadingUsage}
            title={formatMessage({ id: 'word.your.subscriptions' })}
            utilizationText={formatMessage({ id: 'square.meter.activeSubscription.utilization' })}
            totalPrice={
              nextTier
                ? formatPriceWrapper({
                    amount: sanitizeAmount(nextTier.price),
                    currency: toSupportedCurrency(nextTier.currency),
                  })
                : '0'
            }
            priceText={formatMessage({ id: 'word.storing.price' })}
          />
        ))}
      <ItemList {...props} />
    </>
  )
}
