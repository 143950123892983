import {
  type ItemResponseDto,
  type ListingResponseDto,
  type ProductResponseDto,
  ServiceLevelType,
  type StorageItemType,
  SupportedCountries,
  SupportedCurrencies,
} from '@wanda-space/types'
import type { ListingServiceFees, OrderLineWithFullProductAndDiscount, Product } from 'api-client'
import type { ItemPayloadWithProduct } from 'reduxStore/commonMappers'

import { DEFAULT_COUNTRY } from 'i18n'
import type { PriceWrapper } from '../interfaces'
import type { FormatMessage } from './format'
import { productToPrice } from './product-utils'

export function add(...prices: PriceWrapper[]): PriceWrapper {
  return prices.reduce(
    (acc, currentPrice) => ({
      amount: acc.amount + currentPrice.amount,
      currency: currentPrice.currency,
      oldPrice: (acc.oldPrice ?? 0) + (currentPrice.oldPrice ?? 0),
    }),
    {
      amount: 0,
      currency: prices[0]?.currency ?? getCurrencyForCountryCode(DEFAULT_COUNTRY),
      oldPrice: 0,
    }
  )
}

export function multiply(n: number, price: PriceWrapper): PriceWrapper {
  return {
    amount: price.amount ? price.amount * n : 0,
    currency: price.currency,
  }
}

export function subtract(price: PriceWrapper, prices: PriceWrapper[]): PriceWrapper {
  const result = prices
    .filter((p) => p.amount)
    .reduce((a, b) => ({ amount: a.amount - b.amount, currency: a.currency }), {
      amount: price.amount,
      currency: prices.length ? prices[0].currency : undefined,
    })

  return { amount: result.amount, currency: price.currency }
}

export function sanitizeStripeAmount(stripeAmount: number): number {
  return Math.ceil(Number(stripeAmount / 100))
}

export function sanitizeAmount(amount: number): number {
  return Math.ceil(Number(amount / 100))
}

export const formatPrice = (
  currency: SupportedCurrencies | undefined,
  amount: number | undefined
) => `${`${currency}`.toUpperCase()} ${amount?.toFixed(2)},-`

export const formatCost = (currency?: SupportedCurrencies, amount?: number) =>
  `${Math.ceil(amount || 0)} ${(currency ?? '').toLowerCase()}`

export function formatPriceWrapper(
  price: PriceWrapper,
  recurring?: boolean,
  format?: FormatMessage
): string {
  return `${Math.ceil(price.amount)}${price.currency ? ` ${price.currency.toLowerCase()}` : ',-'}${
    recurring && format ? `/${format({ id: 'word.monthAbbreviation' })}` : ''
  }`
}
export function getStoragePrices(products: Product[]): Record<StorageItemType, PriceWrapper> {
  return Object.fromEntries(
    products.map((product) => [product.metadata.itemType, productToPrice(product)])
  )
}
export function getStoragePricesForItems(
  items: (ItemResponseDto | ItemPayloadWithProduct)[],
  productsByItemType: Record<keyof typeof StorageItemType, Product>
): PriceWrapper {
  const itemPrices = items.map((i) =>
    productToPrice(i.storageProduct || productsByItemType[i.type])
  )
  return add(...itemPrices)
}

export function getPriceForAddons(addons: OrderLineWithFullProductAndDiscount[]): PriceWrapper {
  const itemPrices: PriceWrapper[] = addons.map((line) =>
    productToPrice({
      ...line.product,
      [line.product.price]: line.product.price * line.quantity,
    })
  )
  return add(...itemPrices)
}

const currencies: Record<SupportedCountries, string> = {
  NO: 'nok',
  SE: 'sek',
}

const supportedCurrencies: Record<SupportedCountries, SupportedCurrencies> = {
  NO: SupportedCurrencies.NOK,
  SE: SupportedCurrencies.SEK,
}

export function getSupportedCurrencyForCountryCode(countryCode = DEFAULT_COUNTRY) {
  return supportedCurrencies[countryCode]
}

export function getCurrencyForCountryCode(countryCode = DEFAULT_COUNTRY) {
  return currencies[countryCode]
}

export function getSupportedCurrencyFromProductResponseDto(
  product: ProductResponseDto
): SupportedCurrencies {
  if (product.currency === currencies.NO) {
    return SupportedCurrencies.NOK
  }

  if (product.currency === currencies.SE) {
    return SupportedCurrencies.SEK
  }

  return getSupportedCurrencyForCountryCode(product.country)
}

export const getTaasAmountByServiceLevel = (
  serviceLevelType: ServiceLevelType,
  {
    carryingTotalCost,
    curbSideTotalCost,
    firstDoorTotalCost,
  }: {
    carryingTotalCost: string
    curbSideTotalCost: string
    firstDoorTotalCost?: string
  }
) => {
  if (serviceLevelType === ServiceLevelType.CARRYING) {
    return carryingTotalCost
  }

  if (serviceLevelType === ServiceLevelType.FIRST_DOOR) {
    return firstDoorTotalCost
  }

  return curbSideTotalCost
}

export const hasDiscountedPrice = (price: {
  oldPrice?: PriceWrapper['oldPrice']
  amount: PriceWrapper['amount']
}) => price.oldPrice && price.amount < price.oldPrice

export const getDiscountFromPrice = (price: PriceWrapper): PriceWrapper | undefined =>
  price.oldPrice && hasDiscountedPrice(price)
    ? { amount: price.oldPrice - price.amount, currency: price.currency }
    : undefined

export const isSupportedCurrency = (currency?: string): currency is SupportedCurrencies => {
  const currencies: string[] = Object.values(SupportedCurrencies)
  if (!currency) return false
  return currencies.includes(currency.toUpperCase())
}

export const toSupportedCurrency = (currency: string): SupportedCurrencies => {
  if (!isSupportedCurrency(currency)) {
    throw new Error(`Currency ${currency} is not supported`)
  }

  return currency
}

export const priceToSupportedCurrency = (
  { currency }: PriceWrapper,
  country: SupportedCountries = SupportedCountries.NO
) => {
  return (
    (currency?.toUpperCase() as SupportedCurrencies) ?? getSupportedCurrencyForCountryCode(country)
  )
}

export function getListingAmountDetails(
  listingPrice: PriceWrapper,
  listingConfig: ListingServiceFees
) {
  const price = listingPrice
  const serviceFeePercent = listingConfig?.sellSideCut ?? 0
  const serviceFeeByPercentage = (price.amount * (listingConfig?.sellSideCut ?? 0)) / 100
  const serviceFeeMinimumFlat = sanitizeAmount(listingConfig?.sellSideMinimum ?? 0)
  const serviceFeeAmount = Math.max(serviceFeeByPercentage, serviceFeeMinimumFlat)
  const amountReceivedBySeller = Math.max(price.amount - serviceFeeAmount, 0)
  const buyerServiceFee = (price.amount * (listingConfig?.buySideCut ?? 0)) / 100
  const amountPaidByBuyer = price.amount + buyerServiceFee

  return {
    buyerAmount: amountPaidByBuyer,
    sellerAmount: amountReceivedBySeller,
    feePercent: serviceFeePercent,
    feeFlat: serviceFeeMinimumFlat,
  }
}

export function listingToPrice(listing: ListingResponseDto): PriceWrapper {
  return {
    amount: sanitizeAmount(listing.price),
    currency: listing.currency,
  }
}

export function convertToOre(amount = 0): number {
  return amount * 100
}
