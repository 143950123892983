import { postalCodeFormatter } from '@wanda-space/noelle'
import { OrderType, type PlaceOrderRequestDto, type SupportedCountries } from '@wanda-space/types'
import type { OrderLineWithFullProductAndDiscount } from 'api-client'
import type { ContactPerson } from 'components/contact-info/types'
import type { User } from 'interfaces/user'
import { omit, prop } from 'ramda'
import { mapOrderLineToInputDto } from 'reduxStore/commonMappers'

import type { ReturnFlowState } from './types'

interface MapToCreateOrderDtoInputType {
  state: ReturnFlowState
  user: User
  countryCode?: SupportedCountries
}

function isContactPersonDetailsComplete(contactPerson?: ContactPerson) {
  return contactPerson?.name && contactPerson?.phoneNumber
}

export const mapToCreateOrderDto = ({
  state,
  user,
  countryCode,
}: MapToCreateOrderDtoInputType): PlaceOrderRequestDto => {
  const { address, email } = user
  const {
    dateAndTime,
    items,
    serviceLevelType,
    address: orderAddress,
    orderLines,
    contactPerson,
    coupon,
  } = state
  const {
    firstName,
    lastName,
    companyName,
    phoneNumber,
    postalCode,
    countryCode: cc,
    city,
    street,
    floorNumber,
    elevator,
    addressComment,
  } = address
  const { date, timeslot } = dateAndTime

  return {
    type: OrderType.DELIVERY,
    storageItemIds: items.map(prop('id')),
    orderDetails: {
      skipClearingStorageProducts: true,
      deliveryDate: date!,
      deliveryTimeSlot: {
        from: timeslot!.from,
        to: timeslot!.to,
      },
      locale: user.locale,
      contactInfo: {
        contactPerson: isContactPersonDetailsComplete(contactPerson) ? contactPerson : undefined,
        companyName,
        email,
        firstName: orderAddress ? orderAddress.firstName : firstName,
        lastName: orderAddress ? orderAddress.lastName : lastName,
        mobilePhone: orderAddress ? orderAddress.phoneNumber : phoneNumber,
        address: {
          street: orderAddress ? orderAddress.street : street,
          city: orderAddress ? orderAddress.city : city,
          postalCode: postalCodeFormatter(
            (orderAddress ? orderAddress.postalCode : postalCode) as string
          ),
          countryCode: cc || (countryCode as SupportedCountries),
        },
      },
      // @ts-ignore this should be fixed in the backend
      extraDeliveryInfo: orderAddress ? orderAddress.addressComment : addressComment,
      serviceLevelType,
      floorNumber: orderAddress ? orderAddress.floorNumber : (floorNumber ?? 0),
      elevator: orderAddress ? orderAddress.elevator : elevator,
      coupon,
    },
    orderLines: [
      ...orderLines.storage.map(mapReturnStorageItemOrderLineToInputDto),
      ...orderLines.addons.map(mapOrderLineToInputDto),
      ...orderLines.timeslot.map(mapOrderLineToInputDto),
    ],
  }
}

export const mapReturnStorageItemOrderLineToInputDto = (
  orderLine: OrderLineWithFullProductAndDiscount
): NonNullable<PlaceOrderRequestDto['orderLines']>[number] => ({
  ...omit(['product'], orderLine),
  productId: orderLine.product.id,
  accountId: orderLine.accountId ? orderLine.accountId : undefined,
  quantity: -1,
})
