import { Flex, Text, VStack } from '@chakra-ui/react'
import { DynamicIcon, DynamicIconName } from '@wanda-space/noelle'
import type { CustomerPaymentMethod } from 'api-client'
import React from 'react'
import { useIntl } from 'react-intl'
import { nameToCapitalFirstLetterOnly } from 'utils'

export const PaymentMethod = ({ paymentMethod }: { paymentMethod: CustomerPaymentMethod }) => {
  const { formatMessage } = useIntl()
  if (paymentMethod.type === 'card') {
    const cardNumber = paymentMethod?.card?.last4
    const cardBrand = paymentMethod?.card?.brand
    const expirationDate =
      paymentMethod?.card && `${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`
    return (
      <Flex
        data-testid="payment-method"
        width={'100%'}
        justifyContent="space-between"
        padding="0 4"
      >
        <VStack spacing={0} alignItems="start" margin={0}>
          <Text fontWeight={'500'} lineHeight="6">
            {`${nameToCapitalFirstLetterOnly(cardBrand ?? '')} ****${cardNumber}`}
          </Text>
          <Text as="span" marginTop="unset" color="ctaBlack.700">
            {formatMessage(
              {
                id: 'payment.card.expirationdate',
              },
              { date: expirationDate }
            )}
          </Text>
        </VStack>
        <DynamicIcon
          textAlign="end"
          mr={1}
          iconName={(cardBrand as DynamicIconName) || DynamicIconName.cardIcon}
          height={10}
          width={10}
        />
      </Flex>
    )
  }
  return (
    <VStack data-testid="payment-method" spacing={0} alignItems="start" margin={0}>
      <Text fontWeight={'500'} lineHeight="6">
        {nameToCapitalFirstLetterOnly(paymentMethod.type ?? '')}
      </Text>
      <Text as="span" marginTop="unset" color="ctaBlack.700">
        {nameToCapitalFirstLetterOnly(paymentMethod.billing_details.name ?? '')}
      </Text>
    </VStack>
  )
}
