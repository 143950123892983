import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Routes } from 'consts'
import React, { type ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { capitalize } from 'utils'

export type RedirectModalPropTypes = {
  titleLokalizeKey: string
  descriptionLokalizeKey: string
  actionRoute: string
  actionButtonLokalizeKey: string
  show: boolean
  descriptionText?: string | ReactNode
}

export const RedirectModal = ({
  titleLokalizeKey,
  descriptionLokalizeKey,
  actionRoute = '',
  actionButtonLokalizeKey,
  descriptionText,
  show = false,
}: RedirectModalPropTypes) => {
  const { onClose } = useDisclosure()
  const { formatMessage } = useIntl()

  if (show) {
    return (
      <Modal
        isCentered
        closeOnOverlayClick={false}
        onClose={onClose}
        closeOnEsc={false}
        isOpen={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="large" fontWeight="500">
            {formatMessage({ id: titleLokalizeKey })}
          </ModalHeader>
          <ModalBody pb={6}>
            <Text color="gray.600">
              {descriptionText || formatMessage({ id: descriptionLokalizeKey })}
            </Text>
            <Flex mt="7" justifyContent="end" gap="3">
              <Button href={Routes.Space} as="a" cursor="pointer" colorScheme="purple">
                {capitalize(formatMessage({ id: 'word.go.to.my.space' }))}
              </Button>
              <Button href={actionRoute} as="a" cursor="pointer" colorScheme="ctaBlack">
                {formatMessage({ id: actionButtonLokalizeKey })}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  return null
}
