import { type EntityState, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  type AccountId,
  ConditionType,
  type CreateListingRequestBodyDto,
  type DiscountResponseDto,
  type ItemResponseDto,
  type ListingAddonType,
  OrderType,
  type PlacedOrderResponseDto,
  type ServiceLevelType,
  SupportedCurrencies,
} from '@wanda-space/types'
import type { DateAndTime, OrderLineWithFullProductAndDiscount, Product } from 'api-client'
import type { ContactPerson } from 'components/contact-info/types'
import type { FlattenedDeliveryInfo, PriceWrapper } from 'interfaces'
import type { RootState } from 'reduxStore'
import { mapItemToStorageOrderLine, mapUserProductToOrderLine } from 'reduxStore/commonMappers'
import type { ItemPayloadWithProduct } from 'reduxStore/commonMappers'
import { getAllOrderlinesWithDiscounts, getSupportedCurrencyFromProductResponseDto } from 'utils'

import { SliceNames } from '../../constants'

// SHARED

interface ExtraDescriptiveFields {
  colour: string
  brand: string
  model: string
  material: string
  year: string
  comment: string
}

export const getDefaultExtraDescriptiveInfo = (): ExtraDescriptiveFields => ({
  brand: '',
  model: '',
  material: '',
  year: '',
  colour: '',
  comment: '',
})

export type CommonListingInformation = Omit<
  CreateListingRequestBodyDto,
  'extraDescriptiveInfo' | 'storageItems' | 'currency' | 'price'
> & {
  images: string[]
  extraDescriptiveInfo: ExtraDescriptiveFields
}

type ListingFormPrice = {
  amount: PriceWrapper['amount'] | undefined
  currency: SupportedCurrencies
}

export type ListingFormValues = CommonListingInformation & {
  price: ListingFormPrice
}

/** The values that are used in form (create listing step)  */
const getDefaultFormValues = (): ListingFormValues => ({
  name: '',
  description: '',
  showOwnerName: true,
  conditionType: ConditionType.USED,
  dimensionLength: undefined,
  dimensionHeight: undefined,
  dimensionWidth: undefined,
  dimensionFreeText: '',
  price: {
    amount: undefined,
    currency: SupportedCurrencies.NOK,
  },
  images: [],
  extraDescriptiveInfo: getDefaultExtraDescriptiveInfo(),
})

type AddonLocalizationKeys = Partial<Record<ListingAddonType, string>>

/**
 * Keeps UI state (user selections, text in forms etc)
 */
export interface UI_State {
  /** Which addons have been selected (true) or not selected (false) */
  selectedAddons: { [key: string]: any }
  /** Total price for selected addons - required to determine whether we should have payment step for storage flow */
  selectedAddons_totalPrice: number
  /** Total number of addons available for choice - required to determine whether user is allowed to go to next step  */
  addonsAvailableNo: number
  /** We need to keep hold on the localization keys for relevant addons */
  addonLocalizationKeys: AddonLocalizationKeys
}

/** These properties are common for the 2 listing flows, but NOT shared between them */
export interface CommonStateProperties {
  formValues: ListingFormValues
  uiState: UI_State
  addons: ListingAddonType[]
  coupon?: string
}

const getDefaultCommonStateProperties = (): CommonStateProperties => ({
  formValues: getDefaultFormValues(),
  uiState: {
    selectedAddons: {},
    selectedAddons_totalPrice: 0,
    addonsAvailableNo: 0,
    addonLocalizationKeys: {},
  },
  addons: [],
  coupon: undefined,
})

// COMMON REDUCER FUNCTIONS

const updateListingFormValues = (
  state: CommonStateProperties,
  action: PayloadAction<ListingFormValues>
) => {
  state.formValues = action.payload
}

/**
 * Sets the initial (static) information for the listing in the state
 * This only needs to be done once per listing, as long as its items are not changed
 */
const setListingInitialInfo = (
  state: CommonStateProperties,
  action: PayloadAction<{ count: number; addonLocalizationKeys: AddonLocalizationKeys }>
) => {
  state.uiState.addonsAvailableNo = action.payload.count
  state.uiState.addonLocalizationKeys = action.payload.addonLocalizationKeys
}

const setListingAddonSelected = (
  state: CommonStateProperties,
  action: PayloadAction<{ type: ListingAddonType; isSelected: boolean; priceChange: number }>
) => {
  const { type, isSelected, priceChange } = action.payload
  state.uiState.selectedAddons_totalPrice += priceChange
  state.uiState.selectedAddons[type] = isSelected
  state.addons = Object.keys(state.uiState.selectedAddons).filter(
    (key) => state.uiState.selectedAddons[key]
  ) as ListingAddonType[]
}

// PICKUP

const itemPayloadAdapter = createEntityAdapter<ItemPayloadWithProduct>()

export interface ListingForPickup extends CommonStateProperties {
  logisticsOrderType: OrderType
  formValues: ListingFormValues
  storageItems: EntityState<ItemPayloadWithProduct>
  dateAndTime: DateAndTime
  orderLines: {
    storage: OrderLineWithFullProductAndDiscount[]
    taas: OrderLineWithFullProductAndDiscount[]
    packing: OrderLineWithFullProductAndDiscount[]
    timeslot: OrderLineWithFullProductAndDiscount[]
    addons: OrderLineWithFullProductAndDiscount[]
  }
  address: FlattenedDeliveryInfo
  contactPerson?: ContactPerson
  serviceLevel?: ServiceLevelType
  orderSuccessPayload: PlacedOrderResponseDto | null
}

export const sellWithPickupInitialState: ListingForPickup = {
  storageItems: itemPayloadAdapter.getInitialState(),
  dateAndTime: {},
  address: {} as FlattenedDeliveryInfo,
  orderLines: { storage: [], taas: [], packing: [], timeslot: [], addons: [] },
  orderSuccessPayload: null,
  ...getDefaultCommonStateProperties(),
  logisticsOrderType: OrderType.PICK_UP,
}

const sellWithPickupStateSlice = createSlice({
  name: SliceNames.SELL_WITH_PICKUP,
  initialState: sellWithPickupInitialState,
  reducers: {
    updateListingFormValues,
    setListingInitialInfo,
    setListingAddonSelected,
    setItems: (state, action: PayloadAction<ItemPayloadWithProduct[]>) => {
      itemPayloadAdapter.removeAll(state.storageItems)
      itemPayloadAdapter.addMany(state.storageItems, action.payload)
      state.orderLines.storage = [...action.payload].map(mapItemToStorageOrderLine)
    },
    resetAll() {
      return sellWithPickupInitialState
    },
    setOrderType(state, action: PayloadAction<OrderType>) {
      state.logisticsOrderType = action.payload
    },
    setAddress(state, action: PayloadAction<FlattenedDeliveryInfo>) {
      state.address = action.payload
    },
    setContactPerson(state, action: PayloadAction<ContactPerson>) {
      state.contactPerson = action.payload
    },
    selectDateAndTime(state, action: PayloadAction<DateAndTime>) {
      state.dateAndTime = action.payload
    },
    setServiceLevel(state, action: PayloadAction<{ serviceLevelType: ServiceLevelType }>) {
      state.serviceLevel = action.payload.serviceLevelType
    },
    setTaasOrderLines(state, action: PayloadAction<OrderLineWithFullProductAndDiscount[]>) {
      state.orderLines.taas = action.payload
    },
    setTimeslotOrderlines: (
      state,
      action: PayloadAction<OrderLineWithFullProductAndDiscount[]>
    ) => {
      state.orderLines.timeslot = action.payload
    },
    addAddons: (state, action: PayloadAction<{ product: Product; accountId: AccountId }>) => {
      state.orderLines.addons = [
        ...state.orderLines.addons,
        mapUserProductToOrderLine(action.payload.product, action.payload.accountId),
      ]
    },
    removeAddon: (state, action: PayloadAction<{ productId: string }>) => {
      const index = state.orderLines.addons.findIndex(
        (addon) => addon.product.id === action.payload.productId
      )

      state.orderLines.addons.splice(index, 1)
    },
    applyDiscounts: (
      state,
      action: PayloadAction<{ coupon?: string; discounts: DiscountResponseDto[] }>
    ) => {
      if (action.payload.coupon) {
        state.coupon = action.payload.coupon
      }

      const { packing, taas, storage, timeslot, addons } = getAllOrderlinesWithDiscounts(
        state.orderLines.addons,
        state.orderLines.packing,
        state.orderLines.storage,
        state.orderLines.taas,
        state.orderLines.timeslot,
        [],
        action.payload.discounts
      )
      state.orderLines = { packing, taas, storage, timeslot, addons }
    },

    setOrderSuccessPayload: (state, action: PayloadAction<PlacedOrderResponseDto>) => {
      state.orderSuccessPayload = action.payload
    },
  },
})

// STORAGE
export interface ListingForStorage extends CommonStateProperties {
  storageItems: ItemResponseDto[]
}

const initialState: ListingForStorage = {
  storageItems: [],
  ...getDefaultCommonStateProperties(),
}

const SellFromStorageFlowStateSlice = createSlice({
  name: SliceNames.SELL,
  initialState,
  reducers: {
    updateListingFormValues,
    setListingInitialInfo,
    setListingAddonSelected,
    toggleStorageItems(state, action: PayloadAction<ItemResponseDto[]>) {
      state.storageItems = action.payload
    },
    resetAll() {
      return initialState
    },
    applyDiscounts: (
      state,
      action: PayloadAction<{ coupon?: string; discounts: DiscountResponseDto[] }>
    ) => {
      if (action.payload.coupon) {
        state.coupon = action.payload.coupon
      }
    },
  },
})

// EXPORTS

export const itemPayloadSelectors = itemPayloadAdapter.getSelectors<RootState>(
  (state) => state.sellWithPickupFlow.storageItems
)

export const orderAddressSelectors = (state: RootState) => state.sellWithPickupFlow.address

export const {
  setItems,
  selectDateAndTime,
  setAddress,
  setContactPerson,
  setServiceLevel,
  setTaasOrderLines,
  setTimeslotOrderlines,
  setOrderSuccessPayload,
  addAddons,
  removeAddon,
  setOrderType,
} = sellWithPickupStateSlice.actions

export const sell = SellFromStorageFlowStateSlice.reducer
export const sellWithPickupFlow = sellWithPickupStateSlice.reducer

/** For cleaner implementation - allowing same name for same type of actions, accross the flows */
export const pickupActions = {
  ...sellWithPickupStateSlice.actions,
}

export const storageActions = {
  ...SellFromStorageFlowStateSlice.actions,
}
