import type {
  CreateServiceOrderRequestDto,
  SupportedCities,
  SupportedCountries,
} from '@wanda-space/types'
import type { User } from 'interfaces'

import type { OrderLineWithFullProductAndDiscount } from 'api-client'
import { mapOrderLineToInputDto } from '../../../reduxStore/commonMappers'

type CreateServiceFromStoragePayloadInput = {
  serviceOrderLines: OrderLineWithFullProductAndDiscount[]
  user: User
  coupon?: string
}
export const createServiceOrderPayload = ({
  serviceOrderLines,
  user,
  coupon,
}: CreateServiceFromStoragePayloadInput): CreateServiceOrderRequestDto => {
  return {
    city: user.city as SupportedCities,
    countryCode: user.countryCode as SupportedCountries,
    orderLines: serviceOrderLines.map(mapOrderLineToInputDto),
    extraInfo: '',
    coupon,
  }
}
