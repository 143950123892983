import { ServiceArea } from '@wanda-space/ops-types'
import type { OrderType, StorageItemType } from '@wanda-space/types'
import { useMemo } from 'react'

import type { DateAndTime } from '../api-client'
import { isTimeSlotStillOpen } from '../utils'
import { countByItemType } from '../utils/item'
import { useActiveUserLocation } from './useActiveUserLocation'
import { useAvailableTimeslots } from './useAvailableTimeslots'

export const useCheckTimeslotOpen = ({
  orderType,
  dateAndTime,
  items,
}: {
  orderType: OrderType
  dateAndTime: DateAndTime
  items: { type: StorageItemType }[]
}) => {
  const { postalCode, country, serviceArea } = useActiveUserLocation()
  const year = dateAndTime?.date ? new Date(dateAndTime?.date).getFullYear() : 0
  const month = dateAndTime?.date ? new Date(dateAndTime?.date).getMonth() + 1 : 0
  const itemCount = useMemo(() => countByItemType(items), [items])
  const { isLoading, data, isSuccess } = useAvailableTimeslots(
    {
      orderType,
      year,
      month,
      postalCode,
      itemCount,
      serviceArea,
      country,
    },
    { enabled: Boolean(dateAndTime?.date && postalCode) }
  )

  const isTimeslotOpen = useMemo(() => {
    if (!isLoading && isSuccess && data && dateAndTime) {
      return isTimeSlotStillOpen(data, new Date(dateAndTime!.date!), dateAndTime.timeslot)
    }
    return true
  }, [data, isLoading, isSuccess, dateAndTime])

  return [isTimeslotOpen]
}
