import { Spinner } from '@chakra-ui/react'
import type { ItemResponseDto } from '@wanda-space/types'
import { ItemForm } from 'components/Item'
import React from 'react'

import { useImageHook } from '../hooks'

interface Props {
  item: ItemResponseDto
}

export function ServicePending({ item }: Props) {
  const { isLoading: imageLoading, isError, isSuccess, addImage } = useImageHook()

  if (imageLoading) {
    return <Spinner />
  }
  return <ItemForm item={item} isError={isError} isSuccess={isSuccess} addImage={addImage} />
}
