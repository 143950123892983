import type {
  CreateServiceOrderRequestDto,
  CreateServiceOrderWithPickupRequestDto,
  CreateServiceOrderWithPickupResponseDto,
  ListingAddonServiceOrderlinesRequest,
  ListingAddonServiceOrderlinesResponse,
  PaginatedServiceOrderResponseDto,
  PaginationQueryParams,
  ServiceOrderResponseDto,
} from '@wanda-space/types'

import { request } from '../api-client'

export async function createServiceOrderWithPickup(data: CreateServiceOrderWithPickupRequestDto) {
  return request<CreateServiceOrderWithPickupResponseDto>({
    method: 'POST',
    url: 'service-orders/with-pickup',
    body: data,
  })
}

export async function createServiceFromStorage(data: CreateServiceOrderRequestDto) {
  return request<ServiceOrderResponseDto>({
    method: 'POST',
    url: 'service-orders',
    body: data,
  })
}

export async function fetchServiceOrders(params: PaginationQueryParams) {
  return request<PaginatedServiceOrderResponseDto>({
    method: 'GET',
    url: 'service-orders',
    params: params,
  })
}

export async function fetchServiceOrder(id: string) {
  return request<ServiceOrderResponseDto>({
    method: 'GET',
    url: `service-orders/${id}`,
  })
}

export async function cancelServiceOrder(id: string) {
  return request<ServiceOrderResponseDto>({
    method: 'POST',
    body: {},
    url: `service-orders/${id}/cancel`,
  })
}

export async function fetchListingAddonServiceOrderlines(
  data: ListingAddonServiceOrderlinesRequest
) {
  data.storageItems = data.storageItems || []
  return request<ListingAddonServiceOrderlinesResponse>({
    method: 'POST',
    body: data,
    url: 'service-orders/listing-addon-service-orderlines',
  })
}
