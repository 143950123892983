import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { CloseIcon } from '@wanda-space/noelle'
import type { ApiError } from 'api-client/lib/api-client'
import React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

export interface Props {
  initialValue: string
  onClose: () => void
  onSubmit: (value: string) => Promise<void>
  isOpen: boolean
}

export const ChangeItemDescriptionModal = ({ initialValue, onClose, onSubmit, isOpen }: Props) => {
  const { formatMessage } = useIntl()
  const [value, setValue] = useState<string>(initialValue)
  const ref = useRef<HTMLDivElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const updateDescriptionMutation = useMutation(['updateItemDescription'], async (value: string) =>
    onSubmit(value)
  )

  const adjustTextAreaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
    adjustTextAreaHeight()
  }

  useEffect(() => {
    adjustTextAreaHeight()
  }, [isOpen])

  return (
    <div ref={ref}>
      <Modal isOpen={isOpen} onClose={onClose} portalProps={{ containerRef: ref }}>
        <ModalOverlay />
        <ModalContent width="85%" borderRadius="3xl">
          <ModalHeader
            pb={0}
            borderRadius={8}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text>{formatMessage({ id: 'item.description.title' })}</Text>
            <IconButton
              aria-label="close button"
              icon={<CloseIcon w="4" h="4" />}
              onClick={onClose}
              backgroundColor="purple.200"
              size="sm"
              position="absolute"
              top="2"
              right="2"
            />
          </ModalHeader>
          <ModalBody>
            <Box>
              <FormControl isInvalid={updateDescriptionMutation.isError}>
                <Text mb={4}>{formatMessage({ id: 'item.description.description' })}</Text>
                <Textarea
                  resize="none"
                  ref={textareaRef}
                  value={value}
                  onChange={onChangeHandler}
                />
                <FormErrorMessage ml="2">
                  {formatMessage({
                    id:
                      (updateDescriptionMutation.error as ApiError)?.localizationKey() ??
                      'error.general',
                  })}
                </FormErrorMessage>
              </FormControl>
              <ModalFooter pl="unset" pr="unset">
                <Button
                  data-testid="submit-item-button"
                  type="submit"
                  colorScheme="purple"
                  width="100%"
                  borderRadius="lg"
                  onClick={() => updateDescriptionMutation.mutate(value)}
                >
                  {formatMessage({ id: 'word.update' })}
                </Button>
              </ModalFooter>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  )
}
