import { useQueries } from '@tanstack/react-query'
import type { SupportedCountries } from '@wanda-space/noelle'
import type {
  OrderContext,
  OrderType,
  StorageItemsForOrderlinesRequest,
  SupportedCurrencies,
} from '@wanda-space/types'
import type { OrderLineForNewItems } from 'api-client'
import { fetchDiscounts } from 'api-client/lib/routes/discount'
import { fetchTaasOrderLines, fetchTaasOrderLinesByPriceGroups } from 'api-client/lib/routes/taas'
import { useAppSelector } from 'hooks/useAppSelector'
import type { PriceWrapper } from 'interfaces'
import { useMemo } from 'react'

import { CURRENCIES_BY_COUNTRY } from '../consts/Currency'
import { add } from '../utils/price-utils'
import {
  getOrderlinesWithDiscount,
  mapProductToDiscountedProduct,
  productToPrice,
} from '../utils/product-utils'
import { useFeatureFlags } from './useFeatureFlags'

interface TaasProps {
  storageItems: StorageItemsForOrderlinesRequest[]
  orderType: OrderType
  elevator?: boolean
  floorNumber?: number
  enabledCondition?: boolean
  orderContext?: OrderContext
  coupon?: string
  countryCode?: SupportedCountries
}

export const useTaas = ({
  storageItems,
  orderType,
  elevator = false,
  floorNumber,
  enabledCondition = true,
  orderContext,
  coupon,
  countryCode,
}: TaasProps) => {
  const user = useAppSelector((state) => state.user.user)
  const uiCountry = useAppSelector((state) => state.ui.country)
  const city = useAppSelector((state) => state.ui.city)
  const userFloorNumber = user?.address?.floorNumber || 0
  const loadingUser = useAppSelector((state) => state.user.loading)
  const storageItemIds = storageItems.map((item) => item.id).join(',')
  const { data: featureFlags, isInitialLoading: isFeatureFlagsLoading } = useFeatureFlags()

  const country = countryCode ?? uiCountry

  const [discountsQuery, taasQuery] = useQueries({
    queries: [
      {
        queryKey: ['products-with-discount', country, city, coupon],
        queryFn: async () => {
          const discounts = await fetchDiscounts({
            countryCode: country,
            city: city!,
            code: coupon,
          })
          return discounts
        },
        enabled: !!city && !!country,
      },
      {
        queryKey: [
          'getOrderLinesForServiceTypes',
          storageItemIds,
          floorNumber,
          orderType,
          elevator,
        ],
        queryFn: async () => {
          const payload = {
            countryCode: country,
            floorNumber: floorNumber ?? userFloorNumber,
            orderType,
            orderContext: orderContext,
            elevator,
          }
          if (featureFlags?.ENABLE_PRICE_GROUP_ORDERLINES) {
            return await fetchTaasOrderLinesByPriceGroups({
              ...payload,
              storageItems: storageItems.map(({ name, id, type, productId, orderContext }) => ({
                name,
                id,
                type,
                orderContext,
                productId,
              })),
            })
          }
          return await fetchTaasOrderLines({
            ...payload,
            storageItems: storageItems.map(({ name, id, type }) => ({
              name,
              id,
              type,
            })),
          })
        },
        enabled: enabledCondition && !isFeatureFlagsLoading,
      },
    ],
  })

  const { curbSideTotalCost, carryingTotalCost, firstDoorTotalCost } = getOrderlinesWithDiscount(
    taasQuery.data?.orderline ?? { carrying: [], curbside: [], firstDoor: [] },
    discountsQuery.data ?? [],
    CURRENCIES_BY_COUNTRY[country]
  )

  const data: { orderline: OrderLineForNewItems } = useMemo(() => {
    const orderline: OrderLineForNewItems = { carrying: [], curbside: [], firstDoor: [] }

    if (taasQuery.data && discountsQuery.data) {
      orderline.carrying = taasQuery.data.orderline.carrying.map((line) => {
        return {
          ...line,
          product: mapProductToDiscountedProduct(line.product, discountsQuery.data),
        }
      })

      orderline.curbside = taasQuery.data.orderline.curbside.map((line) => {
        return {
          ...line,
          product: mapProductToDiscountedProduct(line.product, discountsQuery.data),
        }
      })

      orderline.firstDoor = taasQuery.data.orderline.firstDoor.map((line) => {
        return {
          ...line,
          product: mapProductToDiscountedProduct(line.product, discountsQuery.data),
        }
      })
    }
    return { orderline }
  }, [taasQuery.data, discountsQuery.data])

  return {
    data,
    isLoading: taasQuery?.isLoading || loadingUser,
    isError: taasQuery?.isError,
    isSuccess: taasQuery?.isSuccess,
    error: taasQuery.error,
    carryingTotalCost: carryingTotalCost.amount.toFixed(2).toString(),
    curbSideTotalCost: curbSideTotalCost.amount.toFixed(2).toString(),
    firstDoorTotalCost: firstDoorTotalCost?.amount.toFixed(2).toString(),
  }
}

export function getTotalTaasPrices(orderLine: OrderLineForNewItems, currency: SupportedCurrencies) {
  let carryingTotalCost: PriceWrapper = { amount: 0, currency }
  let curbSideTotalCost: PriceWrapper = { amount: 0, currency }
  let firstDoorTotalCost: PriceWrapper | undefined

  if (orderLine.carrying.length > 0) {
    carryingTotalCost = add(...orderLine.carrying.map(({ product }) => productToPrice(product)))
  }

  if (orderLine.curbside.length > 0) {
    curbSideTotalCost = add(...orderLine.curbside.map(({ product }) => productToPrice(product)))
  }

  if (orderLine.firstDoor.length > 0) {
    firstDoorTotalCost = add(...orderLine.firstDoor.map(({ product }) => productToPrice(product)))
  }

  return { carryingTotalCost, curbSideTotalCost, firstDoorTotalCost }
}
