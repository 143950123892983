import type {
  CreateServiceOrderWithPickupResponseDto,
  PlaceShopOrderWithOrderlinesResponseDto,
  PlacedOrderResponseDto,
} from '@wanda-space/types'
import type { OrderLineWithFullProductAndDiscount } from 'api-client'
import type { GA4PurchaseEvent, GA4PurchaseEvent_wrapped } from 'tracking/types'
import {
  parseOrderLineArraysForPurchaseEvent,
  util_makeHashFromUUID,
  wrapGA4PurchaseEvent,
} from 'tracking/utils'

import { SliceNames, stateKeyMappings } from '../constants'
import type { reduxSliceKeys } from '../constants'
import type { RootState } from '../index'

type OrderSuccessPayload =
  | PlacedOrderResponseDto
  | CreateServiceOrderWithPickupResponseDto
  | PlaceShopOrderWithOrderlinesResponseDto
  | null

/**
 * Utility function to generate purchase events for logging, according to relevant
 * [GA4 naming conventions](https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#purchase)
 *
 * Background: We want to log purchase events to GA4. A better way would be to log purchases server side
 * (from API, where we have all relevant data in normalized form), but we don't have that option at the moment.
 *
 * This approach is DRY and adhers to SoC and SRP, but it's hacky and requires maintaining of correct mappings.
 * A better approach had been to utilize shared shopping cart, payment and receipt components for all flows,
 * into which we could easily plug logging. But that would require a lot of refactoring, and we currently
 * don't have time for that.
 *
 * @param ap        Action payload, for a `setOrderSuccessPayload` action type
 * @param sName     Which slice the action belongs to
 * @param state     The current state (after action), from which we can extract relevant data
 */
export const util_extractOrderSuccessPayload = (
  ap: OrderSuccessPayload,
  sName: SliceNames,
  state: RootState
): GA4PurchaseEvent_wrapped => {
  const stateKey = (stateKeyMappings[sName] as reduxSliceKeys) || null
  const stateRef = stateKey && Object.hasOwn(state, stateKey) && state[stateKey as keyof RootState]

  /** Fail-safe, can be used for debugging */
  const errMsg = (msg: string) =>
    wrapGA4PurchaseEvent(
      {
        _spaceship_error: `util_extractOrderSuccessPayload-${msg} (${stateKey || sName})`,
      },
      stateKey
    )

  if (!stateRef || !ap) return errMsg('unexpected_data')

  let ga4: GA4PurchaseEvent | Record<string, never> = {}
  let transaction_id = ''
  let orderLinesArrs: OrderLineWithFullProductAndDiscount[][] = []

  switch (sName) {
    case SliceNames.SERVICE: {
      const { serviceOrderLines, storageOrderLines, timeslotOrderLines, taasOrderlines } =
        stateRef as RootState['servicesFlowNext']
      const { serviceOrderId, pickupOrderId } = ap as CreateServiceOrderWithPickupResponseDto

      orderLinesArrs = [serviceOrderLines, storageOrderLines, timeslotOrderLines, taasOrderlines]
      transaction_id = `${serviceOrderId}_${pickupOrderId}`
      break
    }
    case SliceNames.PACKAGING:
      orderLinesArrs = Object.values((stateRef as RootState['packagingFlow']).orderLines)
      transaction_id = (ap as PlaceShopOrderWithOrderlinesResponseDto).packagingOrderId
      break
    case SliceNames.STORAGE:
    case SliceNames.RETURN:
    case SliceNames.SELL_WITH_PICKUP:
      orderLinesArrs = Object.values(
        (stateRef as RootState['storageFlow' | 'returnFlow' | 'sellWithPickupFlow']).orderLines
      )
      break
    case SliceNames.M2_PPA:
      // NOTE: The squareMeterFlow.orderLines.squareMeterProduct is not required to be an array,
      // but can be a single OrderLine or null. So we need to handle that case.
      orderLinesArrs = Object.values((stateRef as RootState['squareMeterFlow']).orderLines)
        .filter((value) => value !== null)
        .map((value) =>
          Array.isArray(value) ? value : [value as OrderLineWithFullProductAndDiscount]
        )
      break
  }

  // Already have transaction_id from ServiceOrderWithPickupResponse and PlaceShopOrderWithOrderlinesResponseDto, but not from OrderStatusResponseDto
  if (!transaction_id && Object.hasOwn(ap, 'orderId'))
    transaction_id = (ap as PlacedOrderResponseDto).orderId
  if (!transaction_id) return errMsg('cannot_determine_transaction_id')

  const ignoreStorageOrderLines = ![SliceNames.STORAGE, SliceNames.M2_PPA].includes(sName)

  if (orderLinesArrs.length) {
    try {
      ga4 = parseOrderLineArraysForPurchaseEvent(
        orderLinesArrs,
        util_makeHashFromUUID(transaction_id),
        ignoreStorageOrderLines
      )
    } catch (e) {
      return errMsg('unexpected_data2')
    }
  } else {
    return errMsg('no_orderlines')
  }

  /*
  // Keep this for debugging purposes
  console.groupCollapsed('util_extractOrderSuccessPayload (%s)', stateKey)
  console.log('ap             ', ap)
  console.log('sName          ', sName)
  console.log('state          ', state)
  console.log('stateKey       ', stateKey)
  console.log('stateRef       ', stateRef)
  console.log('ga4            ', ga4)
  console.log('orderLinesArrs ', orderLinesArrs)
  console.groupEnd()
  */

  return wrapGA4PurchaseEvent(ga4, stateKey)
}
