import { useQuery } from '@tanstack/react-query'
import type {
  ServiceArea,
  TimeslotAvailabilityDto,
  TimeslotAvailabilityInputDto,
} from '@wanda-space/ops-types'
import type { StorageItemType, SupportedCountries } from '@wanda-space/types'
import type { Product } from 'api-client'
import { getAvailableOpsTimeslotsByCount } from 'api-client/lib/routes/taas'
import { indexBy } from 'ramda'

import { useProductsAndCategories } from './useProductsAndCategories'

export type props = TimeslotAvailabilityInputDto & {
  country: SupportedCountries
  itemCount: Record<StorageItemType, number>
}

export type TimeslotAvailabilityAdjusted = Omit<
  TimeslotAvailabilityDto,
  'startAt' | 'endAt' | 'serviceArea' | 'sufficientCapacity'
> & {
  from: string
  to: string
  city: ServiceArea
  isOpen: boolean
  productId?: string
}

export function useAvailableTimeslots(params: props, options?: { enabled?: boolean }) {
  const { timeslotsProducts, isLoading, isSuccess } = useProductsAndCategories()

  const { data: timeslots, ...rest } = useQuery(
    ['ops-timeslots', params],
    () =>
      getAvailableOpsTimeslotsByCount({
        ...params,
        quantityBox: params.itemCount.BOX,
        quantitySmall: params.itemCount.SMALL,
        quantityLarge: params.itemCount.LARGE,
      }),
    options
  )

  if (timeslots && timeslotsProducts) {
    const productsByKey: { [key: string]: Product } = indexBy(
      (product) => `${product.metadata.timeSlotKey}`,
      Object.values(timeslotsProducts)
    )
    const timeslotsWithProduct: Record<string, TimeslotAvailabilityAdjusted[]> = {}
    for (const [date, timeslotsPerDate] of Object.entries(timeslots)) {
      timeslotsWithProduct[date] = timeslotsPerDate.map((timeslot) => ({
        id: timeslot.id,
        key: timeslot.key!,
        country: timeslot.country,
        isOpen: timeslot.sufficientCapacity,
        date: timeslot.date,
        city: timeslot.serviceArea,
        from: timeslot.startAt,
        to: timeslot.endAt,
        productId: productsByKey[timeslot.key!]?.id,
      }))
    }

    return {
      data: timeslotsWithProduct,
      ...rest,
    }
  }
  return {
    data: undefined,
    ...rest,
    isLoading: isLoading || rest.isLoading,
    isSuccess: isSuccess && rest.isSuccess,
  }
}
