import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { CheckIcon, CloseIcon, CouponIcon } from '@wanda-space/noelle'
import type { DiscountResponseDto, SupportedCities, SupportedCountries } from '@wanda-space/types'
import { fetchDiscounts } from 'api-client/lib/routes/discount'
import { Form, Formik } from 'formik'
import { useAppSelector } from 'hooks/useAppSelector'
import React from 'react'
import { useIntl } from 'react-intl'

interface Props {
  onSuccessCoupon: (discounts: DiscountResponseDto[], coupon: string) => void
  coupon: string
  newStyling?: boolean
}

const Coupon = ({ coupon, onSuccessCoupon, newStyling }: Props) => {
  const { formatMessage } = useIntl()
  const InitialValues = {
    coupon: coupon.trim(),
  }
  const country = useAppSelector((state) => state.user.user?.countryCode)
  const city = useAppSelector((state) => state.user.user?.city)

  const couponDiscounts = useMutation(
    ['products-with-discount', country, city, coupon],
    async (values: { coupon: string; country: SupportedCountries; city: SupportedCities }) => {
      const { coupon, country, city } = values
      if (!coupon || !country || !city) {
        return
      }
      const properCouponInput = coupon.toUpperCase().trim()
      const discounts = await fetchDiscounts({
        code: properCouponInput,
        city: city,
        countryCode: country,
      })
      if (discounts.length) {
        onSuccessCoupon(discounts, coupon)
      }
      return discounts
    }
  )

  const isLoading = couponDiscounts.isLoading
  const isSuccess = couponDiscounts.isSuccess
  const error = couponDiscounts.isError

  const handleSubmit = async (data: typeof InitialValues) => {
    if (country && city) {
      return couponDiscounts.mutateAsync({ coupon: data.coupon, country, city })
    }
  }

  return (
    <>
      {newStyling ? (
        <>
          <Formik initialValues={InitialValues} onSubmit={handleSubmit}>
            {(formik) => (
              <Form data-testid="coupon-form">
                <InputGroup>
                  <InputLeftElement height="100%" pl={4}>
                    <CouponIcon />
                  </InputLeftElement>
                  <Input
                    size="lg"
                    isDisabled={isLoading}
                    name="coupon"
                    paddingLeft={12}
                    value={formik.values.coupon}
                    onChange={formik.handleChange}
                    placeholder={formatMessage({ id: 'coupon.use.coupon' })}
                    py={7}
                  />
                  <InputRightElement height="100%">
                    <Button
                      color="white"
                      _active={{ backgroundColor: 'transparent' }}
                      _hover={{
                        backgroundColor: isSuccess ? 'green.600' : error ? 'red.600' : 'purple.800',
                      }}
                      mr={8}
                      borderRadius="md"
                      bg={isSuccess ? 'green.200' : error ? 'red.200' : 'purple.600'}
                      isDisabled={isLoading}
                      type="submit"
                      data-testid="add-coupon-button"
                    >
                      {isSuccess ? (
                        <CheckIcon color="white" />
                      ) : error ? (
                        <CloseIcon color="white" />
                      ) : (
                        formatMessage({ id: 'word.ok' })
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Form>
            )}
          </Formik>
          {error && (
            <Text align="center" mt={2} mb={2} color="red.500" variant="h6">
              {formatMessage({ id: 'word.notValidCoupon' })}
            </Text>
          )}
        </>
      ) : (
        <>
          <Formik initialValues={InitialValues} onSubmit={handleSubmit}>
            {(formik) => (
              <Form data-testid="coupon-form">
                <Input
                  isDisabled={isLoading}
                  name="coupon"
                  placeholder={formatMessage({ id: 'word.coupon' })}
                  value={formik.values.coupon}
                  onChange={formik.handleChange}
                />
                <Button
                  mt={2}
                  isDisabled={isLoading || !formik.values.coupon}
                  size="small"
                  type="submit"
                  colorScheme="ctaBlack"
                  data-testid="add-coupon-button"
                >
                  {formatMessage({ id: 'word.applyCoupon' })}
                </Button>
              </Form>
            )}
          </Formik>
          {error && (
            <Text align="center" mt={2} mb={2} color="red.500" variant="h6">
              {formatMessage({ id: 'word.notValidCoupon' })}
            </Text>
          )}
        </>
      )}
    </>
  )
}

export { Coupon }
