import { Box, Button, Wrap } from '@chakra-ui/react'
import { SelectCard } from '@wanda-space/noelle'
import { OrderContext, type OrderType, ServiceLevelType } from '@wanda-space/types'
import type { WarehouseWithAddress } from 'api-client/lib/routes/warehouses'
import { useAppSelector } from 'hooks/useAppSelector'
import { useTaas } from 'hooks/useTaas'
import { useWarehouseForVisit } from 'hooks/useWarehouseForVisit'
import type { Stepable } from 'interfaces'
import React from 'react'
import { useIntl } from 'react-intl'
import { combinedItemsSelector } from 'reduxStore/ducks/storage/selectors'
import { add, orderlineToPrice } from 'utils'
import { getItemPayloadsFromItems } from 'utils/item'

export function SelectTransportOrWarehouseVisitBase({
  selectedOrderType: orderType,
  taasOrderType,
  warehouseVisitOrderType,
  onNextStep,
  onSelect,
}: {
  selectedOrderType: OrderType
  taasOrderType: OrderType
  warehouseVisitOrderType: OrderType
  onSelect: (option: {
    orderType: OrderType
    serviceLevel: ServiceLevelType
    warehouse?: WarehouseWithAddress
  }) => void
  onNextStep: Stepable['onNextStep']
}) {
  const { formatMessage } = useIntl()
  const items = useAppSelector(combinedItemsSelector)

  const city = useAppSelector((state) => state.ui.city)
  const { data: warehouse } = useWarehouseForVisit({ serviceArea: city })

  const { data: transportTaas } = useTaas({
    storageItems: getItemPayloadsFromItems(items, OrderContext.STORAGE),
    orderType: taasOrderType,
    floorNumber: 0,
    elevator: false,
  })

  const { data: warehouseVisitTaas } = useTaas({
    storageItems: getItemPayloadsFromItems(items, OrderContext.STORAGE),
    orderType: warehouseVisitOrderType,
    floorNumber: 0,
    elevator: false,
  })

  const warehouseVisitPrice = add(
    ...(warehouseVisitTaas?.orderline.curbside.map(orderlineToPrice) ?? [])
  )
  const transportPrice = add(...(transportTaas?.orderline.firstDoor.map(orderlineToPrice) ?? []))

  return (
    <Box width="100%">
      <Wrap mb={6}>
        <SelectCard
          key={taasOrderType}
          title={formatMessage({
            id: `order.transportOption.${taasOrderType.toLowerCase()}.title`,
          })}
          description={formatMessage({
            id: `order.transportOption.${taasOrderType.toLowerCase()}.description`,
          })}
          active={orderType === taasOrderType}
          onClick={() =>
            onSelect({
              orderType: taasOrderType,
              serviceLevel: ServiceLevelType.FIRST_DOOR,
            })
          }
          badge={`${transportPrice.amount},-`}
          data-testid="taas"
          showCheckMark
        />
      </Wrap>

      <Wrap mb={6}>
        <SelectCard
          key={warehouseVisitOrderType}
          title={formatMessage({
            id: `order.transportOption.${warehouseVisitOrderType.toLowerCase()}.title`,
          })}
          description={formatMessage({
            id: `order.transportOption.${warehouseVisitOrderType.toLowerCase()}.description`,
          })}
          active={orderType === warehouseVisitOrderType}
          onClick={() =>
            onSelect({
              orderType: warehouseVisitOrderType,
              serviceLevel: ServiceLevelType.CURBSIDE,
              warehouse,
            })
          }
          badge={`${warehouseVisitPrice.amount},-`}
          openBtnText={formatMessage({ id: 'word.open' })}
          closeBtnText={formatMessage({ id: 'word.close' })}
          data-testid="warehouse_visit"
          showCheckMark
        />
      </Wrap>

      <Button
        width="100%"
        onClick={() => {
          onNextStep()
        }}
        size="lg"
        variant="solid"
        colorScheme="ctaBlack"
        data-testid="continue-button"
      >
        {formatMessage({ id: 'word.continue' })}
      </Button>
    </Box>
  )
}
