import { Link as ChakraLink, Container, Flex, Text } from '@chakra-ui/react'
import { BackButton, ExclamationMarkInHexagonIcon, InfoBox, Spinner } from '@wanda-space/noelle'
import {
  StorageItemState,
  itemCanBeInDeliveryOrder,
  itemHasPendingLogisticOrder,
  itemHasPendingServiceOrder,
} from '@wanda-space/types'
import { Routes } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import { eqProps, uniqWith } from 'ramda'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { sanitizeStripeAmount } from 'utils/price-utils'

import { useItems } from 'hooks/useItems'
import { StorageItemList } from './StorageItemList'

/*
Active items contains stored, service pending items and booked items.
Inactive items are not-stored items (but not if they are booked, then they are active)
*/

const MyItemsView = () => {
  const { formatMessage } = useIntl()
  const user = useAppSelector((state) => state.user.user)
  const [showAllInactiveItems, setShowAllInactiveItems] = useState(false)
  const navigate = useNavigate()
  const { isInitialLoading: itemsLoading, data: items } = useItems(user)

  const padding = 2
  const margin = 4
  const bigMargin = 8
  const itemsPerPage = 3

  const storedItems = (items || []).filter(({ state }) => state === StorageItemState.STORED) || []
  const storedItemsCount = storedItems.length

  const { inactiveItems, count: inactiveItemsCount } = useMemo(() => {
    const notStoredItems = (items || []).filter(
      // @ts-ignore fix ItemResponseDto
      (item) => !itemCanBeInDeliveryOrder(item)
    )
    if (!showAllInactiveItems && items && items.length > 2) {
      return {
        inactiveItems: notStoredItems.slice(0, itemsPerPage),
        count: notStoredItems.length,
      }
    }
    return { inactiveItems: notStoredItems, count: notStoredItems.length }
  }, [showAllInactiveItems, items])

  const totalStoragePrice = sanitizeStripeAmount(
    (storedItems || [])
      .map(({ storageProduct }) => storageProduct?.price ?? 0)
      .reduce((a, b) => a + b, 0)
  )

  // Booked items
  const bookedItems = useMemo(() => {
    // @ts-ignore fix ItemResponseDto
    return (items || []).filter((item) => itemHasPendingLogisticOrder(item))
  }, [items])

  // serviceItems
  const serviceItems = useMemo(() => {
    return (items || []).filter((item) => itemHasPendingServiceOrder(item))
  }, [items])

  // Active items => stored items and booked items
  const activeItems = uniqWith(eqProps('id'), [...bookedItems, ...storedItems, ...serviceItems])

  const itemsWithoutImages = items?.some(({ image }) => !image)

  return (
    <Container>
      <BackButton linkProps={{ onClick: () => navigate(-1) }} />
      {itemsLoading ? (
        <Spinner />
      ) : (
        <>
          <Flex flexDirection="column">
            <Flex flexDirection="column" mt={margin} mb={padding}>
              {itemsWithoutImages && (
                <Flex flexDirection="column" width="100%" my={bigMargin}>
                  <InfoBox
                    leftIcon={<ExclamationMarkInHexagonIcon p="0.8" />}
                    title={formatMessage({ id: 'space.infoBox.missingImages.title' })}
                    onClick={() =>
                      navigate(Routes.NoImageItemView, {
                        state: { referrer: location.pathname },
                      })
                    }
                    description={
                      <Flex direction="column">
                        <Text>
                          {formatMessage({ id: 'space.infoBox.missingImages.description' })}
                        </Text>
                        <ChakraLink
                          style={{ textDecoration: 'underline' }}
                          onClick={() =>
                            navigate(Routes.NoImageItemView, {
                              state: { referrer: location.pathname },
                            })
                          }
                        >
                          {formatMessage({ id: 'space.infoBox.missingImages.link' })}
                        </ChakraLink>
                      </Flex>
                    }
                  />
                </Flex>
              )}
              <Text fontSize="xl" mt={margin} fontWeight="medium">
                {formatMessage({ id: 'space.yourThings' }, { amountOfItems: activeItems.length })}
              </Text>
            </Flex>
            <Text mb={padding}>
              {formatMessage(
                { id: 'space.yourItemsSummary' },
                {
                  amountOfStoredItems: storedItemsCount,
                  priceOfStoredItems: totalStoragePrice,
                }
              )}
            </Text>
          </Flex>

          {/* Active items */}
          <StorageItemList
            items={activeItems}
            emptyStateBoxProps={{
              linkTo: Routes.ServicesNext,
            }}
          />
        </>
      )}

      {/* Inactive items */}

      <Text mt={margin} mb="2" fontWeight="500">
        {formatMessage(
          { id: 'space.myItems.inactiveItems.title' },
          { amountOfNotStoredItems: inactiveItemsCount }
        )}
      </Text>
      <StorageItemList items={inactiveItems} />
      {inactiveItems.length > 0 && (
        <Flex width="100%">
          <ChakraLink
            style={{ textDecoration: 'underline' }}
            onClick={() => setShowAllInactiveItems(!showAllInactiveItems)}
          >
            {showAllInactiveItems
              ? formatMessage({ id: 'word.showLess' })
              : formatMessage({ id: 'word.seeAll' })}
          </ChakraLink>
        </Flex>
      )}
    </Container>
  )
}

export { MyItemsView }
