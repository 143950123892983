import { ChakraProvider, Container, Flex } from '@chakra-ui/react'
import { ExtraErrorData } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { Integrations as Tracing } from '@sentry/tracing'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { GeneralError, Spinner, theme } from '@wanda-space/noelle'
import { ApiError } from 'api-client/lib/api-client'
import { getOrCreateSessionId } from 'api-client/lib/session-id'
import { App } from 'components/App/App'
import { AuthProvider } from 'hooks/useAuth'
import React, { StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { store } from 'reduxStore'
import 'reset-css-dogmatic'
import 'whatwg-fetch'
import './styles.css'

const environment =
  window.location.hostname === 'app.wanda.space'
    ? 'production'
    : window.location.hostname.replace('.wanda.space', '')

Sentry.init({
  dsn: 'https://dba42634ca064d7899c6ae3c1b48d31a@o987876.ingest.sentry.io/5944941',
  integrations: [new Tracing.BrowserTracing(), new ExtraErrorData()],
  environment,
  release: `spaceship-${VERSION}`,
  enabled: ['production'].includes(environment),
  beforeSend(event, hint) {
    if (
      hint?.originalException?.toString().includes('fbq') ||
      (hint?.originalException instanceof Error && hint.originalException.stack?.includes('gtm')) ||
      (hint?.originalException instanceof ApiError && hint.originalException.status() < 500)
    ) {
      return null
    }
    if (hint?.originalException?.toString()?.includes('ChunkLoadError')) {
      event.fingerprint = ['ChunkLoadError']
    } else if (hint?.originalException?.toString()?.includes('Failed to load Stripe.js')) {
      event.fingerprint = ['failed to load stripe']
    }
    event.tags = { ...(event.tags || {}), sessionId: getOrCreateSessionId() }
    if (event.exception && localStorage.enableSentryReportDialog) {
      try {
        Sentry.showReportDialog({
          eventId: event.event_id,
          user: {
            email: store.getState().user.user?.email,
          },
        })
      } catch (error) {
        // we still want to send the event when we can't load the report dialog
        return event
      }
    }
    return event
  },
  tracesSampleRate: 0.1,
})

const Root = () => {
  const queryClient = new QueryClient()
  return (
    <Router>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <Suspense
            fallback={
              <Container height="100%">
                {/* Height margin top is same as the height of the Header, look into Layout.tsx */}
                <Flex
                  mt="16"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="calc(100% - 4rem)"
                >
                  <Spinner />
                </Flex>
              </Container>
            }
          >
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <AuthProvider>
                <Sentry.ErrorBoundary
                  fallback={() => (
                    <GeneralError
                      buttonText="Go to your wanda space"
                      onClick={() => {
                        // this is location.replace instead of history.replace
                        // since we want to make the browser reload the app
                        window.location.replace('/space')
                      }}
                    />
                  )}
                >
                  <App />
                </Sentry.ErrorBoundary>
              </AuthProvider>
            </QueryClientProvider>
          </Suspense>
        </ChakraProvider>
      </Provider>
    </Router>
  )
}
const root = createRoot(document.getElementById('root')!)

root.render(
  <StrictMode>
    <Root />
  </StrictMode>
)
