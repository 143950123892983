import type { OrderResponseDto } from '@wanda-space/types'
import { TimeFormat } from 'consts'
import { useDateLocale } from 'contexts/Intl'
import { format, parse } from 'date-fns'
import React from 'react'
import { useIntl } from 'react-intl'

import { WorthKnowingStep } from './WorthKnowingStep'

interface PropTypes {
  order: OrderResponseDto
}

export const OrderlineAddress = ({ order }: PropTypes) => {
  const { formatMessage } = useIntl()
  const dateLocale = useDateLocale()
  const { contactInfo, deliveryDate, deliveryTimeSlot } = order.orderDetails

  if (!order) {
    return null
  }

  return (
    <>
      <WorthKnowingStep
        key="step1"
        iconName="movingTruck"
        text={formatMessage(
          { id: `booking.confirmation.order.worthKnowing.${order.type.toLowerCase()}.arrivalInfo` },
          {
            streetAddress: contactInfo.address.street,
            date: format(new Date(deliveryDate), 'EEEE do MM', {
              locale: dateLocale,
            }),
            timeFrom: format(
              new Date(parse(deliveryTimeSlot.from, 'HHmm', new Date())),
              TimeFormat,
              {
                locale: dateLocale,
              }
            ),
            timeTo: format(new Date(parse(deliveryTimeSlot.to, 'HHmm', new Date())), TimeFormat, {
              locale: dateLocale,
            }),
          }
        )}
      />
    </>
  )
}
