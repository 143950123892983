import type { BulletPoint } from '@wanda-space/noelle'
import { ServiceLevelType } from '@wanda-space/types'
import { format } from 'date-fns'
import type { StorageFlowState } from 'reduxStore/ducks/storage/types'
import { type FormatMessage, formatAddress } from 'utils'
import { formatTimeSlot } from 'utils/timeslot-utils'

const selectItems = 'select-items'
const transportOrWarehouseVisit = 'transport'
const existingItems = `${selectItems}/existing-items`
const schedule = 'schedule'
const carrying = 'carrying'
const addons = 'addons'
const summary = 'summary'
const address = 'address'
const packing = 'packing'
const register = 'register'
const introduction = 'introduction'
const bookingConfirmation = 'booking-confirmation'
const disclaimer = 'disclaimer'

export const paths = {
  selectItems,
  existingItems,
  schedule,
  transportOrWarehouseVisit,
  carrying,
  addons,
  summary,
  address,
  register,
  packing,
  bookingConfirmation,
  introduction,
  disclaimer,
}

export const bulletPoints = (
  formatMessage: FormatMessage,
  data: StorageFlowState,
  furthestStepReached: number,
  locale: Locale | undefined
): BulletPoint[] => {
  return [
    {
      summaryText:
        data.orderLines.storage.length > 0
          ? formatMessage({ id: 'nav.storing' }, { number: data.orderLines.storage.length })
          : undefined,
      inProgressText: formatMessage({ id: 'nav.storing.default' }),
      link: paths.selectItems,
    },
    {
      summaryText: data.dateAndTime.date
        ? formatMessage(
            { id: 'editOrderDetails.pickupTime' },
            {
              date: `${format(new Date(data.dateAndTime.date), 'EEEE do MMM', {
                locale,
              })}, ${formatTimeSlot(data.dateAndTime.timeslot)})
              `,
            }
          )
        : undefined,
      inProgressText: formatMessage({ id: 'nav.timeAndDate.default' }),
      link: paths.schedule,
    },
    {
      summaryText: data.address.street ? formatAddress(data.address) : undefined,
      inProgressText: formatMessage({ id: 'word.address' }),
      link: paths.address,
    },
    {
      summaryText:
        furthestStepReached > 4
          ? data.serviceLevel === ServiceLevelType.CURBSIDE
            ? formatMessage({ id: 'order.serviceLevel.curbside.title' })
            : formatMessage({ id: 'order.serviceLevel.carrying.title' })
          : undefined,
      inProgressText: `${formatMessage({ id: 'word.carrying' })}?`,
      link: paths.carrying,
    },
    {
      summaryText:
        furthestStepReached > 4
          ? data.orderLines.packing.length > 0
            ? formatMessage({ id: 'packing.wePack' })
            : formatMessage({ id: 'packing.youPack' })
          : undefined,
      inProgressText: formatMessage({ id: 'booking.packing.orderline.description' }),
      link: paths.packing,
    },
    {
      summaryText:
        data.orderLines.addons.length > 0
          ? data.orderLines.addons
              .map(({ product }) => formatMessage({ id: `${product.localizationKey}.name` }))
              .join(', ')
          : furthestStepReached > 4
            ? formatMessage({ id: 'word.noAdditionalServices' })
            : undefined,
      inProgressText: formatMessage({ id: 'word.additionalServices' }),
      link: paths.addons,
    },
    {
      inProgressText: formatMessage({ id: 'word.payment' }),
      link: paths.summary,
    },
  ]
}
