import type { SupportedCountries } from '@wanda-space/noelle'
import {
  ProductPriceType,
  ProductTarget,
  StorageItemType,
  type SupportedCurrencies,
  UserFeatures,
} from '@wanda-space/types'
import type {
  OrderLineWithFullProductAndDiscount,
  OrderlineInOrderResponse,
  Product,
} from 'api-client'
import type { MessageFormatElement } from 'react-intl'
import {
  type FormatMessage,
  getSupportedCurrencyForCountryCode,
  productToPrice,
  sanitizeAmount,
} from 'utils'

export const getm2ProductToItemsMap = (selectedM2Product: Product) => {
  const itemsMap = []

  for (let i = 0; i < (selectedM2Product.metadata.BOX || 5); i++) {
    itemsMap.push({
      type: StorageItemType.BOX,
    })
  }

  for (let i = 0; i < (selectedM2Product.metadata.LARGE || 1); i++) {
    itemsMap.push({
      type: StorageItemType.LARGE,
    })
  }

  return itemsMap
}

export const isProductSquareMeter = (product: Product | OrderlineInOrderResponse['product']) => {
  return (
    product.metadata?.userFeatureKey === UserFeatures.PAY_PER_AREA &&
    product.priceType === ProductPriceType.RECURRING &&
    product.target === ProductTarget.USER &&
    product.active
  )
}

export const isProductSquareMeterMinTaas = (product: Product) => {
  return (
    product.metadata.userFeatureKey === UserFeatures.PAY_PER_AREA &&
    product.priceType === ProductPriceType.ONE_TIME &&
    product.target === ProductTarget.ORDER &&
    product.active
  )
}

export const hasOrderlineSquareMeterOrderline = (
  orderlines: (OrderLineWithFullProductAndDiscount | OrderlineInOrderResponse)[]
) => {
  return orderlines.find((o) => isProductSquareMeter(o.product))
}

export const getMoreInfoLink = (
  formatMessage: FormatMessage,
  messages: Record<string, string> | Record<string, MessageFormatElement[]>
) => {
  const id = 'intropage.square.meter.readMoreLink'
  if (messages[id]) {
    return formatMessage({ id })
  }
}

export const getMonthlyPrice = (params: {
  countryCode: SupportedCountries
  selected: OrderLineWithFullProductAndDiscount | null
  nextLevelTier?: Product
  subscription?: any
}) => {
  const { selected, nextLevelTier, subscription } = params
  if (nextLevelTier && subscription) {
    return {
      amount: sanitizeAmount(nextLevelTier.price - subscription.subscriptionInfo.price.amount),
      currency: nextLevelTier.currency as SupportedCurrencies,
      oldPrice: 0,
    }
  }

  if (selected?.discount) {
    return productToPrice({
      ...selected.product,
      price: selected.product.price - selected.discount.discountAmount,
      originalPrice: selected.product.price - selected.discount.discountAmount,
    })
  }

  if (selected?.product) {
    return productToPrice(selected?.product)
  }

  return {
    amount: 0,
    currency: getSupportedCurrencyForCountryCode(params.countryCode),
    oldPrice: 0,
  }
}

export const hidePrice = (args: {
  usedM2Load: number
  thisM2ProductLoad: number
  totalAvailableM2Load: number
}) => {
  const { usedM2Load, thisM2ProductLoad, totalAvailableM2Load } = args
  return thisM2ProductLoad < totalAvailableM2Load - usedM2Load
}
