import { Flex, FormLabel, Text } from '@chakra-ui/react'
import { Color } from '@wanda-space/noelle'
import React, { type ReactNode } from 'react'
import { useIntl } from 'react-intl'

export interface FormLabelWithMessageProps {
  maxCharacters: number
  children?: ReactNode
}

const FormLabelMaxChars = ({ maxCharacters, children }: FormLabelWithMessageProps) => {
  const { formatMessage } = useIntl()
  return (
    <FormLabel>
      <Flex alignItems="baseline">
        {children}
        <Text
          color={Color.Black45}
          ml={1}
          fontSize="smaller"
          textTransform="lowercase"
          fontStyle="italic"
          fontWeight={300}
        >
          {formatMessage(
            { id: 'form.warning.maxCharacters' },
            {
              maxCharacters,
            }
          )}
        </Text>
      </Flex>
    </FormLabel>
  )
}

export { FormLabelMaxChars }
