import {
  Box,
  Button,
  Fade,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react'
import type { BoxProps } from '@chakra-ui/react'
import type { SupportedCountries } from '@wanda-space/noelle'
import { PhoneNumber } from 'components/ui/Field'
import type { FormikErrors, FormikHandlers, FormikHelpers } from 'formik'
import React from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'

import type { ContactPerson, UpdateContactPerson } from './types'

export interface AddAnotherContactPersonProps {
  phoneInputDefaultCountry: SupportedCountries
  setFieldValue?: FormikHelpers<any>['setFieldValue']
  formikHandleChange?: FormikHandlers['handleChange']
  formikHandleBlur?: FormikHandlers['handleBlur']
  contactPerson: ContactPerson
  errors?: FormikErrors<ContactPerson>
  updateContactPerson?: UpdateContactPerson
  expanderType?: 'button' | 'a'
  expandContainerProps?: BoxProps
}

export const AnotherContactPerson = ({
  phoneInputDefaultCountry,
  updateContactPerson,
  setFieldValue,
  formikHandleChange,
  formikHandleBlur,
  contactPerson,
  errors,
  expanderType = 'a',
  expandContainerProps = {},
}: AddAnotherContactPersonProps) => {
  const { formatMessage } = useIntl()

  const [showAddContactPerson, setShowAddContactPerson] = useState(Boolean(contactPerson.name))
  const phoneNumberError = errors?.phoneNumber
  const isExpanderButton = expanderType === 'button'

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (updateContactPerson) {
      updateContactPerson({ ...contactPerson, name: e.target.value })
    } else if (formikHandleChange) {
      formikHandleChange(e)
    }
  }

  const onPhoneChange = (value: string) => {
    if (updateContactPerson) {
      updateContactPerson({ ...contactPerson, phoneNumber: value })
    } else if (setFieldValue) {
      setFieldValue('contactPerson.phoneNumber', value)
    }
  }

  const toggleShowAddContactPerson = () => {
    if (showAddContactPerson) {
      if (updateContactPerson) {
        updateContactPerson({ name: '', phoneNumber: '' })
      } else if (setFieldValue) {
        setFieldValue('contactPerson.phoneNumber', '')
        setFieldValue('contactPerson.name', '')
      }
    }

    setShowAddContactPerson(!showAddContactPerson)
  }

  return (
    <>
      {!showAddContactPerson ? (
        <Button
          variant={isExpanderButton ? 'outline' : 'ghost'}
          size="lg"
          colorScheme="gray"
          as={expanderType}
          width={isExpanderButton ? '100%' : 'auto'}
          mb={2}
          _hover={{ cursor: 'pointer', backgroundColor: isExpanderButton ? 'gray.100' : 'none' }}
          _active={{ backgroundColor: isExpanderButton ? 'gray.100' : 'none' }}
          pt={isExpanderButton ? 6 : 0}
          pb={isExpanderButton ? 6 : 0}
          px={isExpanderButton ? 6 : 0}
          onClick={toggleShowAddContactPerson}
          textDecoration={isExpanderButton ? 'none' : 'underline'}
          fontWeight="medium"
        >
          {expanderType === 'a' ? '+' : ''}
          {formatMessage({ id: 'word.add.contact.deliveryAddress' })}
        </Button>
      ) : (
        <Box mb={8} {...expandContainerProps}>
          <Flex justifyContent="space-between" alignItems="center" mb={2}>
            <Text fontWeight="medium">{formatMessage({ id: 'word.contactPerson.for.order' })}</Text>
            <Button
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              backgroundColor="transparent"
              p={0}
              onClick={toggleShowAddContactPerson}
              textDecoration="underline"
            >
              {formatMessage({ id: 'word.clear.contactPerson' })}
            </Button>
          </Flex>
          <Fade in={showAddContactPerson}>
            <FormControl onBlur={formikHandleBlur} mb={2}>
              <FormLabel fontSize={14}>{formatMessage({ id: 'word.name' })}</FormLabel>
              <Input
                id="contactPerson.name"
                name="contactPerson.name"
                onChange={onNameChange}
                value={contactPerson.name}
                mb={2}
              />
            </FormControl>
            <FormControl isInvalid={Boolean(phoneNumberError)} onBlur={formikHandleBlur} mb={2}>
              <FormLabel fontSize={14}>{formatMessage({ id: 'word.phoneNumber' })}</FormLabel>
              <PhoneNumber
                defaultCountry={phoneInputDefaultCountry}
                name="contactPerson.phoneNumber"
                data-testid="contactPerson-phoneNumber"
                value={contactPerson.phoneNumber}
                placeholder={formatMessage({ id: 'word.phoneNumber' })}
                setFieldValue={onPhoneChange}
                error={errors?.phoneNumber}
              />
              <FormErrorMessage>{phoneNumberError}</FormErrorMessage>
              <FormHelperText>
                {formatMessage({ id: 'word.contactPerson.phone.info' })}
              </FormHelperText>
            </FormControl>
          </Fade>
        </Box>
      )}
    </>
  )
}
