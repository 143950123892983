import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  Textarea,
} from '@chakra-ui/react'
import {
  Address,
  AddressKeys,
  CustomRadioBtnGroup,
  FloorPicker,
  GeoLocationKeys,
  Notification,
  getAddressSchema,
} from '@wanda-space/noelle'
import type { SupportedCities, SupportedCountries } from '@wanda-space/types'
import type { OrderLineWithFullProductAndDiscount } from 'api-client'
import { PhoneNumber } from 'components/ui/Field'
import { Form, Formik } from 'formik'
import { usePostalCodes } from 'hooks/usePostalCodes'
import { isNil, omit } from 'ramda'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { sanitizeStripeAmount } from 'utils'
import * as Yup from 'yup'

import { useActiveUserLocation } from 'hooks/useActiveUserLocation'
import { FormLabelMaxChars } from '../Forms/FormLabelMaxChars'
import { AddressSkeleton } from './AddressSkeleton'
import { AnotherContactPerson } from './AnotherContactPerson'
import { contactInfoFormSchema, filterVisibleFields } from './schema'
import {
  AddressRenderState,
  type ContactInfoData,
  type ContactInfoPageSubmitHandler,
  type ContactPerson,
  FieldNames,
  type UpdateAddressRenderState,
} from './types'
import { isFieldDisabled, isFieldVisible } from './utils'

export type ContactInfoFormSubmitHandler = (values: ContactInfoData) => void

interface FormikFormType extends ContactInfoData {
  contactPerson: ContactPerson
}

export interface ContactInfoFormProps {
  onSubmit: ContactInfoPageSubmitHandler
  submitBtnLabel?: string
  contactInfo: ContactInfoData
  contactPerson?: ContactPerson
  updateAddressRenderState?: UpdateAddressRenderState
  flexOneTimeOrderline?: OrderLineWithFullProductAndDiscount
  showContactPerson: boolean
  isSaving?: boolean
  hideFields?: FieldNames[]
  disableFields?: FieldNames[]
  useSavedAddressComment?: boolean
  showItemsMustfit?: boolean
  showPostalCodeNotMatchedMessage?: boolean
}

const getInitialFieldChangeState = () => {
  const state: Record<string, { unsavedChanges: boolean }> = Object.create({})

  for (const name in FieldNames) {
    state[FieldNames[name as keyof typeof FieldNames]] = {
      unsavedChanges: false,
    }
  }

  return state
}

export const ContactInfoForm = ({
  onSubmit,
  submitBtnLabel,
  contactInfo,
  contactPerson,
  updateAddressRenderState,
  flexOneTimeOrderline,
  showContactPerson,
  isSaving = false,
  hideFields = [],
  disableFields = [],
  useSavedAddressComment = false,
  showItemsMustfit = true,
  showPostalCodeNotMatchedMessage = false,
}: ContactInfoFormProps) => {
  const { formatMessage } = useIntl()
  const intialState = getInitialFieldChangeState()
  const [fieldChangeState, setFieldChangeState] = useState(intialState)
  const { country, serviceArea } = useActiveUserLocation()
  const { data: postalCodesFromOps, isLoading: isPostalCodeLoading } = usePostalCodes(
    { country, city: serviceArea },
    { suspense: true }
  )
  const postalCodes =
    postalCodesFromOps?.map((pc) => ({
      postalCode: pc.postalCode,
      city: pc.city as SupportedCities,
      country: pc.country as SupportedCountries,
    })) || []
  const initialValues = {
    addressComment: useSavedAddressComment ? contactInfo.addressComment : '',
    floorNumber: contactInfo.floorNumber,
    elevator: contactInfo.elevator,
    contactPerson: contactPerson || { name: '', phoneNumber: '' },
    firstName: contactInfo.firstName || '',
    lastName: contactInfo.lastName || '',
    email: contactInfo.email ?? '',
    phoneNumber: contactInfo.phoneNumber || '',
    companyName: contactInfo.companyName || '',
    [AddressKeys.STREET_ADDRESS]: contactInfo.street || '',
    [AddressKeys.POSTAL_CODE]: contactInfo.postalCode || '',
    [AddressKeys.CITY]: contactInfo.city || '',
    [AddressKeys.COUNTRY]: contactInfo.countryCode,
    [AddressKeys.LOCATION]: {
      [GeoLocationKeys.LAT]: '',
      [GeoLocationKeys.LNG]: '',
    },
    [AddressKeys.PLACE_ID]: '',
  }

  const maxFloor = 200
  const minFloor = -10
  const maxFloorWithoutElevator = 6

  const showAddressSkeleton =
    initialValues[AddressKeys.STREET_ADDRESS] === '' &&
    initialValues[AddressKeys.POSTAL_CODE] === '' &&
    initialValues[AddressKeys.CITY] === ''

  const onFormikSubmitHandler = (values: FormikFormType) => {
    const { contactPerson, ...contactInfo } = values

    // If we decide to keep coordinates and google place id just remove sanifization
    const sanifizedContactInfo = omit([AddressKeys.LOCATION, AddressKeys.PLACE_ID], contactInfo)
    onSubmit({ contactPerson, contactInfo: sanifizedContactInfo })
  }

  const runOnNextTick = (callback: () => void) => {
    setTimeout(() => {
      callback()
    }, 0)
  }

  const setUnsavedStateOfField = (fieldName: FieldNames) => {
    setFieldChangeState((oldState) => {
      const newStates = Object.assign({}, oldState)
      newStates[fieldName] = {
        unsavedChanges: true,
      }

      return newStates
    })
  }

  const addressSchema = Yup.object().shape(
    getAddressSchema(formatMessage, postalCodes || [], contactInfo.countryCode)
  )

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validateOnChange={false}
      validationSchema={filterVisibleFields(
        isPostalCodeLoading
          ? contactInfoFormSchema(formatMessage)
          : contactInfoFormSchema(formatMessage).concat(addressSchema),
        hideFields,
        disableFields
      )}
      onSubmit={onFormikSubmitHandler}
    >
      {(formik) => {
        const hasElevatorWarning =
          !!formik.values.floorNumber &&
          formik.values.floorNumber >= maxFloorWithoutElevator &&
          !formik.values.elevator

        const isElevatorChoiceDisabled =
          isFieldDisabled(FieldNames.ELEVATOR, disableFields) &&
          typeof formik.values.elevator !== 'undefined' &&
          fieldChangeState[FieldNames.ELEVATOR].unsavedChanges === false

        return (
          <Form data-testid="contact-info-form">
            {isFieldVisible(FieldNames.FIRST_NAME, hideFields) && (
              <FormControl
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.firstName && !!formik.touched.firstName}
                mb="4"
              >
                <FormLabel htmlFor={FieldNames.FIRST_NAME}>
                  {formatMessage({ id: 'word.firstName' })}
                </FormLabel>
                <Input
                  isDisabled={
                    isFieldDisabled(FieldNames.FIRST_NAME, disableFields) &&
                    formik.values.firstName !== '' &&
                    fieldChangeState[FieldNames.FIRST_NAME].unsavedChanges === false
                  }
                  id={FieldNames.FIRST_NAME}
                  name={FieldNames.FIRST_NAME}
                  onChange={(e) => {
                    setUnsavedStateOfField(FieldNames.FIRST_NAME)
                    formik.handleChange(e)
                  }}
                  value={formik.values.firstName}
                  mb={2}
                />
                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
              </FormControl>
            )}
            {isFieldVisible(FieldNames.LAST_NAME, hideFields) && (
              <FormControl
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.lastName && !!formik.touched.lastName}
                mb="4"
              >
                <FormLabel htmlFor={FieldNames.LAST_NAME}>
                  {formatMessage({ id: 'word.lastName' })}
                </FormLabel>
                <Input
                  disabled={
                    isFieldDisabled(FieldNames.LAST_NAME, disableFields) &&
                    formik.values.lastName !== '' &&
                    fieldChangeState[FieldNames.LAST_NAME].unsavedChanges === false
                  }
                  id={FieldNames.LAST_NAME}
                  name={FieldNames.LAST_NAME}
                  onChange={(e) => {
                    setUnsavedStateOfField(FieldNames.LAST_NAME)
                    formik.handleChange(e)
                  }}
                  value={formik.values.lastName}
                  mb={2}
                />
                <FormErrorMessage>{formik.errors.firstName}</FormErrorMessage>
              </FormControl>
            )}
            {isFieldVisible(FieldNames.EMAIL, hideFields) && (
              <FormControl
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.email && !!formik.touched.email}
                mb="4"
              >
                <FormLabel htmlFor={FieldNames.EMAIL}>
                  {formatMessage({ id: 'word.email' })}
                </FormLabel>
                <Input
                  type="email"
                  isDisabled={
                    isFieldDisabled(FieldNames.EMAIL, disableFields) &&
                    formik.values.email !== '' &&
                    fieldChangeState[FieldNames.EMAIL].unsavedChanges === false
                  }
                  id={FieldNames.EMAIL}
                  name={FieldNames.EMAIL}
                  onChange={(e) => {
                    setUnsavedStateOfField(FieldNames.EMAIL)
                    formik.handleChange(e)
                  }}
                  value={formik.values.email}
                  mb={2}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
            )}
            {isFieldVisible(FieldNames.PHONE_NUMBER, hideFields) && (
              <FormControl
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.phoneNumber && !!formik.touched.phoneNumber}
                mb="4"
              >
                <FormLabel htmlFor={FieldNames.PHONE_NUMBER}>
                  {formatMessage({ id: 'word.phoneNumber' })}
                </FormLabel>
                <PhoneNumber
                  disabled={
                    isFieldDisabled(FieldNames.PHONE_NUMBER, disableFields) &&
                    formik.values.phoneNumber !== '' &&
                    fieldChangeState[FieldNames.PHONE_NUMBER].unsavedChanges === false
                  }
                  data-testid={FieldNames.PHONE_NUMBER}
                  placeholder={formatMessage({ id: 'word.phoneNumber' })}
                  name={FieldNames.PHONE_NUMBER}
                  value={formik.values.phoneNumber}
                  error={formik.touched.phoneNumber ? formik.errors.phoneNumber : ''}
                  setFieldValue={(value: string) => {
                    formik.setFieldValue(FieldNames.PHONE_NUMBER, value)
                    setUnsavedStateOfField(FieldNames.PHONE_NUMBER)
                    runOnNextTick(() => formik.setFieldTouched(FieldNames.PHONE_NUMBER))
                  }}
                  defaultCountry={contactInfo.countryCode}
                />
                <FormErrorMessage>{formik.errors.phoneNumber}</FormErrorMessage>
              </FormControl>
            )}

            {isPostalCodeLoading && showAddressSkeleton ? (
              <AddressSkeleton />
            ) : (
              <Address
                postalCode={contactInfo.postalCode}
                availablePostalCodes={postalCodes}
                formikbag={formik}
                postalCodeValidationMessages={
                  showPostalCodeNotMatchedMessage
                    ? {
                        postalCodeIsNotMatch: formatMessage({
                          id: 'postal.code.not.matching.message',
                        }),
                      }
                    : undefined
                }
                onChangeHandleInjection={setUnsavedStateOfField}
                useAutocomplete={true}
                autocompleteProps={{
                  placeholder: formatMessage({ id: 'address.lookup.placeholder' }),
                  noOptionsMessage: formatMessage({ id: 'address.lookup.no.options' }),
                  loadingMessage: formatMessage({ id: 'address.lookup.loading' }),
                }}
                variant="outline"
                fieldLabels={{
                  ac: formatMessage({ id: 'address.lookup.label' }),
                  [AddressKeys.CITY]: formatMessage({ id: 'word.city' }),
                  [AddressKeys.POSTAL_CODE]: formatMessage({ id: 'word.zip' }),
                  [AddressKeys.STREET_ADDRESS]: formatMessage({ id: 'word.street' }),
                }}
                fieldProps={{
                  [AddressKeys.STREET_ADDRESS]: {
                    isDisabled:
                      isFieldDisabled(FieldNames.STREET, disableFields) &&
                      formik.values.street !== '' &&
                      fieldChangeState[FieldNames.STREET].unsavedChanges === false,
                    hidden: !isFieldVisible(FieldNames.STREET, hideFields),
                  },
                  [AddressKeys.POSTAL_CODE]: {
                    disabled:
                      isFieldDisabled(FieldNames.POSTCODE, disableFields) &&
                      formik.values.postalCode !== '' &&
                      fieldChangeState[FieldNames.POSTCODE].unsavedChanges === false,
                    hidden: !isFieldVisible(FieldNames.POSTCODE, hideFields),
                  },
                  [AddressKeys.CITY]: {
                    isDisabled:
                      isFieldDisabled(FieldNames.CITY, disableFields) &&
                      formik.values.city !== '' &&
                      fieldChangeState[FieldNames.CITY].unsavedChanges === false,
                    hidden: !isFieldVisible(FieldNames.CITY, hideFields),
                  },
                }}
              />
            )}

            {isFieldVisible(FieldNames.ADDRESS_COMMENT, hideFields) && (
              <FormControl
                onBlur={formik.handleBlur}
                isInvalid={!!formik.errors.addressComment && !!formik.touched.addressComment}
                mb="4"
              >
                <FormLabelMaxChars maxCharacters={2000}>
                  {formatMessage({ id: 'word.comment' })}
                </FormLabelMaxChars>
                <Textarea
                  isDisabled={
                    isFieldDisabled(FieldNames.ADDRESS_COMMENT, disableFields) &&
                    formik.values.addressComment !== '' &&
                    fieldChangeState[FieldNames.ADDRESS_COMMENT].unsavedChanges === false
                  }
                  id={FieldNames.ADDRESS_COMMENT}
                  name={FieldNames.ADDRESS_COMMENT}
                  value={formik.values.addressComment}
                  onChange={(e) => {
                    setUnsavedStateOfField(FieldNames.ADDRESS_COMMENT)
                    formik.handleChange(e)
                  }}
                  placeholder={formatMessage({ id: 'schedule.instructionsForDelivery.short' })}
                  maxLength={2000}
                />
              </FormControl>
            )}
            {showContactPerson && (
              <AnotherContactPerson
                phoneInputDefaultCountry={contactInfo.countryCode}
                setFieldValue={formik.setFieldValue}
                formikHandleChange={formik.handleChange}
                formikHandleBlur={formik.handleBlur}
                contactPerson={formik.values.contactPerson}
                errors={formik.errors.contactPerson}
              />
            )}
            {isFieldVisible(FieldNames.FLOOR_NUMBER, hideFields) && (
              <FormControl
                isInvalid={!!formik.errors.floorNumber && !!formik.touched.floorNumber}
                mb="4"
              >
                <FormLabel>{formatMessage({ id: 'word.select.floor' })}</FormLabel>
                <FloorPicker
                  disabled={
                    isFieldDisabled(FieldNames.FLOOR_NUMBER, disableFields) &&
                    !!formik.values.floorNumber &&
                    fieldChangeState[FieldNames.FLOOR_NUMBER].unsavedChanges === false
                  }
                  error={formik.touched.floorNumber ? formik.errors.floorNumber : ''}
                  minValue={minFloor}
                  maxValue={maxFloor}
                  onChange={(number: number) => {
                    formik.setFieldValue(FieldNames.FLOOR_NUMBER, number)
                    setUnsavedStateOfField(FieldNames.FLOOR_NUMBER)
                    runOnNextTick(() => {
                      formik.setFieldTouched(FieldNames.FLOOR_NUMBER)
                    })
                  }}
                  value={formik.values.floorNumber}
                  ariaLabelDecrease={formatMessage({
                    id: 'carryingMethod.carrying.ariaDecreaseFloor',
                  })}
                  ariaLabelIncrease={formatMessage({
                    id: 'carryingMethod.carrying.ariaIncreaseFloor',
                  })}
                  displayValue={(number) => {
                    if (isNil(number)) return formatMessage({ id: 'word.select.floor' })
                    return `${formatMessage({ id: 'word.floor.no' })} ${number}`
                  }}
                />
              </FormControl>
            )}
            {isFieldVisible(FieldNames.ELEVATOR, hideFields) && (
              <FormControl
                isInvalid={!!formik.errors.elevator && !!formik.touched.elevator}
                onBlur={formik.handleBlur}
              >
                <CustomRadioBtnGroup
                  name={FieldNames.ELEVATOR}
                  options={[
                    {
                      isDisabled: isElevatorChoiceDisabled,
                      value: 'no',
                      displayLabel: formatMessage({ id: 'carryingMethod.carrying.noElevator' }),
                      ariaLabel: formatMessage({ id: 'carryingMethod.carrying.noElevator' }),
                    },
                    {
                      isDisabled: isElevatorChoiceDisabled,
                      value: 'yes',
                      displayLabel: formatMessage({ id: 'carryingMethod.carrying.elevator' }),
                      ariaLabel: formatMessage({ id: 'carryingMethod.carrying.elevator' }),
                    },
                  ]}
                  onChange={(nextValue: string) => {
                    formik.setFieldValue(FieldNames.ELEVATOR, nextValue === 'yes')
                    setUnsavedStateOfField(FieldNames.ELEVATOR)
                    runOnNextTick(() => {
                      formik.setFieldTouched(FieldNames.ELEVATOR)
                    })
                  }}
                  value={
                    isNil(formik.values.elevator)
                      ? undefined
                      : formik.values.elevator
                        ? 'yes'
                        : 'no'
                  }
                />
                {showItemsMustfit && (
                  <FormHelperText textAlign="center" alignItems="center">
                    {formatMessage({ id: 'address.form.elevator.helper.itemsMustFitInside' })}
                  </FormHelperText>
                )}
                {hasElevatorWarning ? (
                  <Box mt={5}>
                    <Notification
                      id="elevator-alert"
                      dismissible={false}
                      text={formatMessage(
                        { id: 'carryingMethod.carrying.needElevator' },
                        { floor: maxFloorWithoutElevator }
                      )}
                      type="alerts"
                      wide
                    />
                  </Box>
                ) : null}
                <FormErrorMessage justifyContent="center">
                  {formik.errors.elevator}
                </FormErrorMessage>
              </FormControl>
            )}
            {updateAddressRenderState && (
              <Box>
                {flexOneTimeOrderline && (
                  <Text mt="8" py="3" px="5" bg="orange.100" borderRadius="8">
                    {formatMessage(
                      {
                        id: 'flex.address.price.message',
                      },
                      { price: sanitizeStripeAmount(flexOneTimeOrderline.product.price) }
                    )}
                    /-
                  </Text>
                )}
                <Button
                  onClick={() => updateAddressRenderState(AddressRenderState.USER_DEFAULT_ADDRESS)}
                  width="100%"
                  variant="outline"
                  size="lg"
                  colorScheme="gray"
                  my="4"
                  fontWeight="medium"
                >
                  {formatMessage({ id: 'word.use.saved.address' })}
                </Button>
              </Box>
            )}
            <Button
              onClick={() => {
                runOnNextTick(() => {
                  const firstErrorField = Object.keys(formik.errors)[0]
                  const firstErrorElement = document.getElementById(firstErrorField)
                  if (firstErrorElement) {
                    firstErrorElement.scrollIntoView({
                      behavior: 'smooth',
                      block: 'end',
                    })
                  }
                })
              }}
              mt={hasElevatorWarning ? '0' : '5'}
              colorScheme="ctaBlack"
              width="100%"
              type="submit"
              size="lg"
              data-testid="continue-button"
              isLoading={isSaving}
              isDisabled={hasElevatorWarning || isSaving}
            >
              {submitBtnLabel || formatMessage({ id: 'word.continue' })}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
