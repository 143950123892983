import type { PriceWrapper } from 'interfaces'
import { useIntl } from 'react-intl'
import { type FormatMessage, capitalize } from 'utils'

const getAmount = (amount: number, currency = '') => {
  return `${amount.toFixed(2)} ${currency.toLowerCase()}`
}

export const getPickupSummary = (
  taasPrice: PriceWrapper,
  formatMessage: FormatMessage,
  key?: string
) => {
  const pickupSummary = formatMessage(
    { id: key || 'pickup.from.amount' },
    { amount: getAmount(taasPrice.amount, taasPrice.currency) }
  )

  return pickupSummary
}

export const getProgressIndicatorPriceSummary = (pickup: string, delivery: string) => {
  return capitalize(`${pickup}    |    ${delivery}`)
}

export const useProgressIndicatorPriceSummary = (
  taasPrice: PriceWrapper,
  estimatedDeliveryCost: PriceWrapper
) => {
  const { formatMessage } = useIntl()

  const pickupSummary = getPickupSummary(taasPrice, formatMessage)

  const returnSummary = `${formatMessage(
    { id: 'return.from.amount' },
    { amount: getAmount(estimatedDeliveryCost.amount, estimatedDeliveryCost.currency) }
  )}`

  return getProgressIndicatorPriceSummary(pickupSummary, returnSummary)
}

export function useProgressIndicatorDeliveryPriceSummary(price: PriceWrapper): string {
  const { formatMessage } = useIntl()
  const taasSummary = capitalize(
    formatMessage(
      { id: 'delivery.from.amount' },
      { amount: getAmount(price.amount, price.currency) }
    )
  )

  return taasSummary
}
