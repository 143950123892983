import type { Locale } from 'i18n'
import type {
  AccountResponseDto,
  RegisterUserRequestDto,
  User,
  UserAddress,
  UserV2,
} from 'interfaces/user'

import type { PaginatedResponseDto, PaginationQueryParams } from '@wanda-space/types'
import { request } from '../api-client'
import { userBaseUrl as baseUrl } from '../config'
import { defaults as defaultFeatureFlags } from './feature-flags'

export type UpdateUserAddressDto = Partial<UserAddress>

export async function registerUser(
  data: RegisterUserRequestDto,
  token: string,
  featureFlags = defaultFeatureFlags
) {
  return request<User>({
    method: 'POST',
    url: '/v1/user/register',
    body: data,
    baseUrl,
    token,
  })
}

export async function getPossibleDuplicateUsers(email: string) {
  const users = await request<User[]>({
    method: 'GET',
    baseUrl,
    url: '/v1/user/get-users-by-email',
  })

  return users.filter((user) => !user.userId.includes('planday'))
}

export async function mergeUsers(accessToken: string) {
  return request({
    method: 'POST',
    baseUrl,
    url: '/v1/user/merge-user',
    body: { accessToken },
  })
}

export async function putUserAddress(data: UpdateUserAddressDto) {
  return request<User>({
    method: 'PUT',
    url: '/v1/user/address',
    body: data,
    baseUrl,
  })
}

export async function fetchUser() {
  return request<User>({
    method: 'GET',
    url: '/v1/user',
    baseUrl,
  })
}

export async function fetchUserV2() {
  return request<UserV2>({
    method: 'GET',
    url: '/v2/users',
    baseUrl,
  })
}

export async function updateLocale(locale: Locale) {
  return request<User>({
    method: 'PUT',
    url: '/v1/user/locale',
    body: { locale },
    baseUrl,
  })
}

export async function changePassword(data: { password: string }) {
  return request({
    method: 'POST',
    url: '/v1/user/change-password',
    body: data,
    baseUrl,
  })
}

export async function resetPassword(data: { email: string }) {
  return request({
    method: 'POST',
    url: '/v1/user/reset-password',
    baseUrl,
    body: data,
  })
}

export async function logoutUser() {
  return request({
    method: 'POST',
    url: '/v1/user/logout',
    baseUrl,
    body: {},
  })
}

export async function fetchAccounts(params?: PaginationQueryParams) {
  return request<PaginatedResponseDto<AccountResponseDto>>({
    method: 'GET',
    url: '/v2/accounts',
    baseUrl,
    params,
  })
}

export async function switchAccount(accountId: string) {
  return request<AccountResponseDto>({
    method: 'PUT',
    url: '/v2/accounts/active',
    baseUrl,
    body: { accountId },
  })
}
