import { Box, Button, Flex, Skeleton, Text } from '@chakra-ui/react'
import { Alert, Calendar, CallOut, CustomRadioBtnGroup } from '@wanda-space/noelle'
import type { OrderType, StorageItemType } from '@wanda-space/types'
import { useDateLocale } from 'contexts/Intl'
import { format } from 'date-fns'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'reduxStore'
import { setEstimatedTimeslotCost } from 'reduxStore/ducks/ui'
import { countByItemType } from 'utils/item'
import { timeSlotFromString, timeSlotToString } from 'utils/timeslot-utils'

import { prop } from 'ramda'
import type { DateAndTime } from '../../api-client'
import { useActiveUserLocation } from '../../hooks/useActiveUserLocation'
import {
  type TimeslotAvailabilityAdjusted,
  useAvailableTimeslots,
} from '../../hooks/useAvailableTimeslots'
import {
  getSupportedCurrencyForCountryCode,
  isTimeSlotStillOpen,
  sanitizeStripeAmount,
} from '../../utils'

interface Props {
  items: { type: StorageItemType }[]
  orderType: OrderType
  onNext: (dateAndTime: DateAndTime) => void
  preSelectedDate?: string
  preSelectedTimeslot?: { from: string; to: string; productId?: string }
  hideFullDayTimeslot?: boolean
  hidePrices?: boolean
  buttonSave?: string
}

const SelectDateAndTimeBase = ({
  preSelectedDate,
  preSelectedTimeslot,
  items,
  orderType,
  onNext,
  hideFullDayTimeslot,
  hidePrices,
  buttonSave,
}: Props) => {
  const { formatMessage } = useIntl()
  const dateLocale = useDateLocale()
  const dispatch = useAppDispatch()
  const date = preSelectedDate ? new Date(preSelectedDate) : undefined
  const timeslot = preSelectedTimeslot

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(date)
  const [selectedTimeslot, setSelectedTimeslot] = useState<
    { from: string; to: string; productId?: string } | undefined
  >(timeslot)
  const [isDateBeingChecked, setIsDateBeingChecked] = useState<boolean>(true)

  const { country, serviceArea, postalCode } = useActiveUserLocation()

  const initialDate = date ? new Date(date) : new Date()
  const [{ year, month }, setCurrentMonth] = useState<{
    year: number
    month: number
  }>({
    year: initialDate.getFullYear(),
    month: initialDate.getMonth() + 1,
  })

  const { timeslotsProducts } = useProductsAndCategories()
  const { data: featureFlags } = useFeatureFlags()

  const [hasExceededTimeSlotAvailabilityCheck, setHasExceededTimeSlotAvailabilityCheck] =
    useState(false)

  const itemCount = useMemo(() => countByItemType(items), [items])

  /**
   * Helper to resolve price / reason for being disabled for a given timeslot
   */
  const getPriceOrStatus4Timeslot = (timeslot: TimeslotAvailabilityAdjusted): string => {
    if (!timeslot.isOpen) return formatMessage({ id: 'timeslot.status.full' })

    const timeslotProduct =
      timeslot?.productId && timeslotsProducts ? timeslotsProducts[timeslot.productId] : undefined
    const price = (timeslotProduct?.price && sanitizeStripeAmount(timeslotProduct?.price)) || 0
    return `${(price > 0 ? '+ ' : '') + price}.-`
  }

  /**
   * Helper to resolve label for given timeslot
   */
  const getLabel4Timeslot = (timeslot: TimeslotAvailabilityAdjusted): string =>
    `${timeslot.from.substring(0, 2)} - ${timeslot.to.substring(0, 2)}`

  const { data: timeslotsForDate } = useAvailableTimeslots(
    {
      year: date?.getFullYear() ?? new Date().getFullYear(),
      month: (date?.getMonth() ?? 0) + 1,
      itemCount,
      country,
      serviceArea: serviceArea!,
      postalCode: postalCode!,
      orderType,
    },
    { enabled: !!date && !!serviceArea }
  )
  const {
    isLoading,
    error,
    isError,
    data: timeslots,
  } = useAvailableTimeslots(
    {
      year,
      month,
      itemCount,
      country,
      serviceArea,
      postalCode,
      orderType,
    },
    { enabled: !!year && !!month }
  )

  const timeslotIsTaken = useMemo(() => {
    if (selectedDate && selectedTimeslot && timeslotsForDate) {
      if (!isTimeSlotStillOpen(timeslotsForDate, selectedDate, selectedTimeslot)) {
        return true
      }
    }
    return false
  }, [selectedDate, timeslot, timeslotsForDate])

  const setFirstAvailableInMonthOrChangeMonth = (
    timeslots: Record<string, TimeslotAvailabilityAdjusted[]>
  ) => {
    const dateStrings = Object.keys(timeslots).sort()
    for (const dateString of dateStrings) {
      const currentTimeslots: TimeslotAvailabilityAdjusted[] = timeslots[dateString]
      if (currentTimeslots) {
        const timeSlot = currentTimeslots.find(({ isOpen }) => isOpen)
        if (timeSlot) {
          const date = new Date(dateString)
          setSelectedDate(date)
          setSelectedTimeslot(timeSlot)
          setIsDateBeingChecked(false)

          if (
            featureFlags?.ENABLE_PRICED_TIMESLOTS &&
            timeslotsProducts &&
            timeSlot &&
            timeSlot.productId
          ) {
            const timeslotProduct = timeslotsProducts[timeSlot.productId]
            dispatch(
              setEstimatedTimeslotCost({
                amount: sanitizeStripeAmount(timeslotProduct?.price ?? 0),
                currency: getSupportedCurrencyForCountryCode(timeslotProduct.country),
              })
            )
          }

          return
        }
      }
    }

    const threeYearInFuture = new Date().getFullYear() + 3

    if (year <= threeYearInFuture) {
      if (month === 12) {
        setCurrentMonth({ year: year + 1, month: 1 })
      } else {
        setCurrentMonth({ year, month: month + 1 })
      }
    } else {
      setHasExceededTimeSlotAvailabilityCheck(true)
      setIsDateBeingChecked(false)
    }
  }

  if (timeslots && isDateBeingChecked) {
    if (date && timeslot) {
      if (isTimeSlotStillOpen(timeslots, date, timeslot)) {
        setSelectedDate(date)
        setSelectedTimeslot(timeslot)
      }
      setIsDateBeingChecked(false)
    } else if (!selectedDate) {
      setFirstAvailableInMonthOrChangeMonth(timeslots)
    }
  }

  const toggleDeliveryTimeSlot = (value: string) => {
    const slot =
      selectedDate &&
      timeslots &&
      (timeslots[format(selectedDate, 'yyyy-MM-dd')] || []).find(
        (slot) =>
          slot.from === timeSlotFromString(value).from && slot.to === timeSlotFromString(value).to
      )
    setSelectedTimeslot(slot)

    if (timeslotsProducts && slot && slot.productId) {
      const timeslotProduct = timeslotsProducts[slot.productId]
      dispatch(
        setEstimatedTimeslotCost({
          amount: sanitizeStripeAmount(timeslotProduct.price),
          currency: getSupportedCurrencyForCountryCode(timeslotProduct.country),
        })
      )
    }
  }

  const onMonthChangeHandler = (event: { month: number; year: number }) => {
    setSelectedDate(undefined)
    setSelectedTimeslot(undefined)
    setCurrentMonth(event)
  }

  const handleClickOnNextStep = () => {
    if (selectedDate && selectedTimeslot) {
      onNext({
        timeslot: selectedTimeslot,
        date: format(selectedDate, 'yyyy-MM-dd'),
      })
    }
  }

  const buttonDisabled = !(selectedTimeslot && selectedDate) || timeslotIsTaken

  const createTimeslotOptions = () => {
    if (selectedDate && timeslots && featureFlags?.ENABLE_PRICED_TIMESLOTS) {
      return (timeslots[format(selectedDate, 'yyyy-MM-dd')] || []).map((timeslot) => {
        const label = getLabel4Timeslot(timeslot)

        return {
          displayLabel: (
            <>
              <Text>{label}</Text>

              {!hideFullDayTimeslot && !hidePrices ? (
                <Text color="gray.700" fontSize="xs">
                  {getPriceOrStatus4Timeslot(timeslot)}
                </Text>
              ) : null}
            </>
          ),
          ariaLabel: label,
          value: timeSlotToString(timeslot),
          isDisabled: !timeslot.isOpen,
          dataTestId: `timeslot-${timeslot.from}-${timeslot.to}`,
        }
      })
    }

    return selectedDate && timeslots
      ? (timeslots[format(selectedDate, 'yyyy-MM-dd')] || []).map((timeslot) => {
          const label = getLabel4Timeslot(timeslot)

          return {
            displayLabel: <Text>{label}</Text>,
            ariaLabel: label,
            value: timeSlotToString(timeslot),
            isDisabled: !timeslot.isOpen,
            dataTestId: `timeslot-${timeslot.from}-${timeslot.to}`,
          }
        })
      : []
  }
  const timeslotsOptions = createTimeslotOptions()

  const showNoSlotsAvailable =
    !isDateBeingChecked &&
    featureFlags?.ENABLE_PRICED_TIMESLOTS &&
    hasExceededTimeSlotAvailabilityCheck

  return (
    <Flex direction="column" data-testid="select-time-and-time-base">
      <>
        {isError && error && (
          <Box mb={5} mt={3} data-testid="general-error-alert">
            <Alert
              duration={0}
              id="alert-error-id"
              status="error"
              text={formatMessage({ id: 'error.general' })}
            />
          </Box>
        )}
        {timeslotIsTaken && date && (
          <Box mb={5} mt={3} data-testid="warning-timeslot-taken">
            <Alert
              duration={0}
              id="alert-warning-id"
              status="error"
              text={formatMessage(
                { id: 'alert.order.state.timeslotTaken.text' },
                {
                  date: (
                    <b>
                      {format(date, 'EEEE dd MMMM', {
                        locale: dateLocale,
                      })}
                    </b>
                  ),
                }
              )}
            />
          </Box>
        )}
        <Calendar
          dateLocale={dateLocale}
          minDate={new Date()}
          monthsToRender={1}
          onMonthChange={onMonthChangeHandler}
          onSelectDay={(date) => {
            if (timeslots) {
              const dateString = format(date, 'yyyy-MM-dd')
              const timeslot = timeslots[dateString].find((timeslot) => timeslot.isOpen)
              setSelectedTimeslot(timeslot)
              setSelectedDate(date)

              if (
                timeslotsProducts &&
                timeslot &&
                timeslot.productId &&
                timeslotsProducts[timeslot.productId]
              ) {
                const timeslotProduct = timeslotsProducts[timeslot.productId]
                dispatch(
                  setEstimatedTimeslotCost({
                    amount: sanitizeStripeAmount(timeslotProduct.price ?? 0),
                    currency: getSupportedCurrencyForCountryCode(timeslotProduct.country),
                  })
                )
              }
            }
          }}
          selectedDate={selectedDate}
          tileDisabled={(tileDate) => {
            return timeslots
              ? !timeslots[format(tileDate, 'yyyy-MM-dd')]?.some(({ isOpen }) => isOpen)
              : false
          }}
          weekDays={[
            formatMessage({ id: 'word.monday.short' }),
            formatMessage({ id: 'word.tuesday.short' }),
            formatMessage({ id: 'word.wednesday.short' }),
            formatMessage({ id: 'word.thursday.short' }),
            formatMessage({ id: 'word.friday.short' }),
            formatMessage({ id: 'word.saturday.short' }),
            formatMessage({ id: 'word.sunday.short' }),
          ]}
          loading={isDateBeingChecked}
        />
        <Flex justifyContent="center" mb={4} mt={4}>
          {selectedDate && (
            <Text align="center" as="h4" fontSize="md" mr="2">
              {formatMessage({ id: 'booking.selectTime.summary' })}
            </Text>
          )}
          <Text align="right" ml={1} variant="h4" fontWeight="medium" data-testid="selected-date">
            {selectedDate ? format(selectedDate, 'PP', { locale: dateLocale }) : null}
          </Text>
        </Flex>
        {showNoSlotsAvailable && (
          <Alert
            duration={0}
            id="alert-error-id-no-slots"
            status="warning"
            text={formatMessage({ id: 'timeslot.not.found.message' })}
          />
        )}
        {isDateBeingChecked && <TimeSlotSkeleton isLoading={isLoading} />}
        <CustomRadioBtnGroup
          options={timeslotsOptions}
          onChange={toggleDeliveryTimeSlot}
          value={
            selectedTimeslot &&
            timeslotsOptions.map(prop('value')).includes(timeSlotToString(selectedTimeslot))
              ? timeSlotToString(selectedTimeslot)
              : ''
          }
          fillAvailableSpace
        />
        <Box mb={4} mt={selectedDate ? 10 : 4}>
          <CallOut
            title={formatMessage({ id: 'contactInformation.sms.title' })}
            text={formatMessage({ id: 'contactInformation.sms.text' })}
          />
        </Box>

        <Button
          onClick={handleClickOnNextStep}
          isDisabled={buttonDisabled || isLoading}
          size="lg"
          variant="solid"
          colorScheme="ctaBlack"
          width="100%"
          data-testid="continue-button"
        >
          {buttonSave ? buttonSave : formatMessage({ id: 'word.addContactInfo' })}
        </Button>
      </>
    </Flex>
  )
}

const TimeSlotSkeleton = (props: { isLoading: boolean }) => {
  const { isLoading } = props
  const arr = [1, 2, 3]
  return (
    <Flex justifyContent="center" width="100%" gap="4">
      {arr.map((a) => (
        <Box
          as="span"
          mb="5"
          borderRadius={10}
          border="2px solid"
          borderColor="purple.300"
          overflow="hidden"
          key={a}
        >
          <Skeleton
            startColor="purple.200"
            endColor="white"
            width={36}
            height={12}
            isLoaded={!isLoading}
            fadeDuration={1}
          />
        </Box>
      ))}
    </Flex>
  )
}

export { SelectDateAndTimeBase }
