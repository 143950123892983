import { Flex, type FlexProps, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'
import { hasDiscountedPrice } from 'utils'

export interface PriceDetails {
  title: string
  price: number
  oldPrice?: number
}

interface PriceSummaryRowDetailsProps extends Omit<PriceDetails, 'title'> {
  title: string | React.ReactNode
  itemProps?: FlexProps
  suffix?: string
}

export interface PriceSummaryRowProps extends PriceDetails {
  description?: string
  offerPrice?: string
  isLegend?: boolean
  children?: JSX.Element
}

export const PriceSummaryRow = ({
  title,
  description,
  price,
  offerPrice,
  oldPrice,
  isLegend = false,
}: PriceSummaryRowProps) => (
  <Flex direction="column" mb={4}>
    <SimpleGrid columns={3} spacing={2}>
      <Flex flex={2}>
        <Text fontSize={17} fontWeight={isLegend ? 'medium' : 'normal'}>
          {title}
        </Text>
      </Flex>
      <Flex justifyContent="end">
        {hasDiscountedPrice({ oldPrice, amount: price }) ? (
          <Text textDecoration="line-through">{oldPrice},-</Text>
        ) : null}
      </Flex>

      <Flex justifyContent="end">
        <Text fontSize={17} fontWeight={isLegend ? 'medium' : 'normal'}>
          {offerPrice && <>{offerPrice},-/ </>}
          {price},-
        </Text>
      </Flex>
    </SimpleGrid>
    {description && <Text fontSize={14}>{description}</Text>}
  </Flex>
)

export const MinimalPriceSummaryRow = (props: PriceDetails) => (
  <SimpleGrid columns={3} spacing={2} mb={4}>
    <PriceSummaryRowDetails {...props} />
  </SimpleGrid>
)

export const PriceSummaryRowDetails = ({
  title,
  oldPrice,
  price,
  itemProps = {},
  suffix,
}: PriceSummaryRowDetailsProps) => {
  return (
    <>
      <Flex>{typeof title === 'string' ? <Text>{title}</Text> : title}</Flex>
      <Flex {...itemProps}>
        {hasDiscountedPrice({ oldPrice, amount: price }) ? (
          <Text textDecoration="line-through">{oldPrice},-</Text>
        ) : null}
      </Flex>
      <Flex {...itemProps}>
        <Text>
          {price.toFixed(2)},-{suffix}
        </Text>
      </Flex>
    </>
  )
}
