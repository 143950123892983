import { type PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setUser } from '@sentry/react'
import { postalCodeFormatter } from '@wanda-space/noelle'
import {
  type UpdateUserAddressDto,
  fetchUser as fetchUserRequest,
  putUserAddress,
  updateLocale,
} from 'api-client/lib/routes/user'
import { mapStringToLocale } from 'i18n'
import type { RootState } from 'reduxStore'

import type { AccountResponseDto } from 'interfaces'
import { SliceNames } from '../../constants'
import { mapUserAddress } from './mappers'
import type { UserState } from './types'

export const fetchUser = createAsyncThunk('user/fetchUser', fetchUserRequest)
export const patchAddress = createAsyncThunk(
  'user/address',
  async (data: UpdateUserAddressDto, { getState }) => {
    let newData = { ...data }
    if (newData.postalCode) {
      newData = {
        ...newData,
        postalCode: postalCodeFormatter(newData.postalCode),
      }
    }

    const state = getState() as RootState
    return putUserAddress(mapUserAddress({ ...state.user.user?.address, ...newData }))
  }
)
export const setLocale = createAsyncThunk(
  'user/locale',
  async (unparsedLocale: string) => {
    const locale = mapStringToLocale(unparsedLocale)
    return updateLocale(locale)
  },
  {
    condition: (unparsedLocale, { getState }) => {
      const locale = mapStringToLocale(unparsedLocale)
      const state = getState() as RootState
      return state.user.user?.locale !== locale
    },
  }
)

const initialState: UserState = {
  loading: false,
  submitting: false,
  error: undefined,
  user: undefined,
}

const slice = createSlice({
  name: SliceNames.USER,
  initialState,
  reducers: {
    clearUser: (state) => {
      state.error = initialState.error
      state.loading = initialState.loading
      state.user = initialState.user
      setUser(null)
    },
    setActiveAccount: (state, action: PayloadAction<AccountResponseDto>) => {
      if (state.user) {
        state.user.accountId = action.payload.id
        state.user.email = action.payload.email
        if (action.payload.address && action.payload.city && action.payload.countryCode) {
          state.user.address = action.payload.address
          state.user.city = action.payload.city
          state.user.countryCode = action.payload.countryCode
          state.user.locale = action.payload.locale
        }
      }
    },
  },
  extraReducers: (builder) => {
    //fetch
    builder.addCase(fetchUser.pending, (state) => {
      state.error = undefined
      state.loading = true
    })
    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      setUser({ email: payload.email, id: payload.id })
      state.user = payload
      state.loading = false
      state.error = undefined
    })
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.error = action.error
      state.loading = false
    })
    //patch
    builder.addCase(patchAddress.pending, (state) => {
      state.error = undefined
      state.submitting = true
    })
    builder.addCase(patchAddress.fulfilled, (state, action) => {
      state.user = action.payload
      state.submitting = false
      state.error = undefined
    })
    builder.addCase(patchAddress.rejected, (state, action) => {
      state.error = action.error
      state.submitting = false
    })
    //locale
    builder.addCase(setLocale.pending, (state) => {
      state.error = undefined
      state.submitting = true
    })
    builder.addCase(setLocale.fulfilled, (state, action) => {
      state.user = action.payload
      state.submitting = false
      state.error = undefined
    })
    builder.addCase(setLocale.rejected, (state, action) => {
      state.error = action.error
      state.submitting = false
    })
  },
})

export const { clearUser, setActiveAccount } = slice.actions

export const user = slice.reducer
