import { Box, Wrap } from '@chakra-ui/react'
import { SelectCard, type SelectCardProps } from '@wanda-space/noelle'
import { ServiceLevelType } from '@wanda-space/types'
import React from 'react'
import { useIntl } from 'react-intl'

export interface SelectCarryingMethodProps {
  serviceLevel: ServiceLevelType
  onChange: (serviceLevel: ServiceLevelType) => void
  curbSidePrice: string
  carryingPrice: string
  firstDoorPrice?: string
  carryingCardProps?: Partial<SelectCardProps>
  curbsideCardProps?: Partial<SelectCardProps>
  firstDoorCardProps?: Partial<SelectCardProps>
}

const SelectCarryingMethod = ({
  serviceLevel,
  onChange,
  curbSidePrice,
  carryingPrice,
  firstDoorPrice,
  carryingCardProps,
  curbsideCardProps,
  firstDoorCardProps,
}: SelectCarryingMethodProps) => {
  const { formatMessage } = useIntl()

  return (
    <Box width="100%">
      <Wrap mb={6}>
        <SelectCard
          key={ServiceLevelType.CURBSIDE}
          title={formatMessage({ id: 'order.serviceLevel.curbside.title' })}
          description={formatMessage({ id: 'order.serviceLevel.curbside.description' })}
          active={serviceLevel === ServiceLevelType.CURBSIDE}
          onClick={() => onChange(ServiceLevelType.CURBSIDE)}
          badge={`${curbSidePrice},-`}
          data-testid="curbside"
          showCheckMark
          {...curbsideCardProps}
        />
      </Wrap>
      {firstDoorPrice ? (
        <Wrap mb={6}>
          <SelectCard
            key={ServiceLevelType.FIRST_DOOR}
            title={formatMessage({ id: 'order.serviceLevel.firstDoor.title' })}
            description={formatMessage({ id: 'order.serviceLevel.firstDoor.description' })}
            active={serviceLevel === ServiceLevelType.FIRST_DOOR}
            onClick={() => onChange(ServiceLevelType.FIRST_DOOR)}
            badge={`${firstDoorPrice},-`}
            openBtnText={formatMessage({ id: 'word.open' })}
            closeBtnText={formatMessage({ id: 'word.close' })}
            data-testid="firstDoor"
            showCheckMark
            {...firstDoorCardProps}
          />
        </Wrap>
      ) : null}

      <Wrap mb={6}>
        <SelectCard
          key={ServiceLevelType.CARRYING}
          title={formatMessage({ id: 'order.serviceLevel.carrying.title' })}
          description={formatMessage({ id: 'order.serviceLevel.carrying.description' })}
          active={serviceLevel === ServiceLevelType.CARRYING}
          onClick={() => onChange(ServiceLevelType.CARRYING)}
          badge={`${carryingPrice},-`}
          openBtnText={formatMessage({ id: 'word.open' })}
          closeBtnText={formatMessage({ id: 'word.close' })}
          data-testid="carrying"
          showCheckMark
          {...carryingCardProps}
        />
      </Wrap>
    </Box>
  )
}

export { SelectCarryingMethod }
