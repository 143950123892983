import { Box, Container, Flex, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { Spinner } from '@wanda-space/noelle'
import { WarningModalContent } from 'components/Modals/WarningModal/WarningModal'
import { Routes as routes } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import type { Stepable } from 'interfaces'
import type { ListingForPreview } from 'interfaces/listing'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useNavigate } from 'react-router'
import { useAppDispatch } from 'reduxStore'
import {
  getListingForPreview,
  getSelectedItemsFromStorageToSell,
} from 'reduxStore/ducks/sell/selectors'
import { storageActions } from 'reduxStore/ducks/sell/sellFlow'
import { ForYourInformationBox } from 'routes/BuySellFlow/common/ForYourInformationBox'
import { InfoNotification } from 'routes/BuySellFlow/common/InfoNotification'
import { ListingPreview } from 'routes/BuySellFlow/common/ListingPreview'
import { useListingConfig } from 'routes/BuySellFlow/common/hooks'
import { getListingAmountDetails } from 'utils'
import { getListingItemsFromItem } from 'utils/item'
import { createListingForStorageAndUploadImages } from './createListing'

export const ListingCreateConfirmForStorage = ({ onNextStep, nextPath }: Stepable) => {
  const listingDetails = useAppSelector(getListingForPreview(false))
  const storageItems = useAppSelector(getSelectedItemsFromStorageToSell)
  const [isLoading, setIsLoading] = useState(false)
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isLargerThanmedium] = useMediaQuery('(min-width: 768px)')
  const { isOpen, onClose } = useDisclosure()
  const {
    data: listingConfig,
    isInitialLoading: isListingConfigLoading,
    error,
  } = useListingConfig(storageItems?.[0]?.countryCode ?? undefined)

  const goToPublish = (id: string) => {
    setIsLoading(false)
    dispatch(storageActions.resetAll())
    const nextPathWithSimpleId = nextPath && generatePath(nextPath, { simpleId: id })
    onNextStep({ nextPath: nextPathWithSimpleId, replace: true })
  }

  const listingCreationMutation = useMutation(
    ['createListingForStorage'],
    async () => createListingForStorageAndUploadImages(listingDetails, storageItems, dispatch),
    {
      onSuccess: (listing) => {
        goToPublish(listing.simpleId)
      },
    }
  )

  const handleDeleteListing = () => {
    dispatch(storageActions.resetAll())
    navigate(routes.Sell)
  }

  if (error) {
    throw error
  }

  if (isListingConfigLoading || !listingConfig) {
    return <Spinner />
  }

  const handleCreateListing = () => {
    if (listingDetails.addons?.length) {
      return onNextStep()
    }
    setIsLoading(true)
    return listingCreationMutation.mutate()
  }

  // update name of selected items as they will be updated in the backend later
  // update image for the preview, item image will be updated along with listing image after confirming
  const selectedItemsWithListingNamePrefix = storageItems.map((item) => {
    const image = !item.image ? listingDetails.images?.[0] : undefined
    const _item = getListingItemsFromItem(item)
    return {
      ..._item,
      listingName: `${listingDetails.name} : ${item.name}`,
      image,
    }
  })

  const { sellerAmount, feeFlat, feePercent } = getListingAmountDetails(
    listingDetails.price,
    listingConfig
  )

  return (
    <>
      <Flex direction={['column', 'column', 'row']} maxWidth="inherit">
        <Container pt="0">
          {!isLargerThanmedium && (
            <Box mb={2}>
              <InfoNotification
                handleClickOnNextStep={handleCreateListing}
                isBuyableListing={false}
                sellingFromHome={false}
                isLoading={isLoading}
              />
            </Box>
          )}
          <ListingPreview
            listing={listingDetails as unknown as ListingForPreview}
            listingConfig={listingConfig}
            selectedItems={selectedItemsWithListingNamePrefix}
          />
        </Container>{' '}
        <Container maxW={['100%', '100%', 'xs']} p={['auto', 'auto', '0']}>
          <Flex gap={4} direction="column">
            {isLargerThanmedium && (
              <InfoNotification
                handleClickOnNextStep={handleCreateListing}
                isBuyableListing={false}
                sellingFromHome={false}
                isLoading={isLoading}
              />
            )}
            <ForYourInformationBox
              sellerAmount={sellerAmount}
              feeFlat={feeFlat}
              feePercent={feePercent}
              currency={listingDetails.price.currency}
            />
          </Flex>
        </Container>
      </Flex>
      <WarningModalContent
        title={formatMessage({ id: 'delete.sales.page.confirmation.title' })}
        description={formatMessage(
          { id: 'delete.sales.page.confirmation.description' },
          { name: listingDetails.name, bold: (value) => <b>{value}</b> }
        )}
        onClose={onClose}
        isOpen={isOpen}
        handleClickYes={handleDeleteListing}
      />
    </>
  )
}
