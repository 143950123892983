import { Flex, Grid, GridItem, Heading, Tag } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { ImagePreviewMode, ImageViewer, MoreInfoPopper } from '@wanda-space/noelle'
import type { ServiceProviderResponseDto } from '@wanda-space/types'
import React from 'react'
import { useIntl } from 'react-intl'

import {
  fetchServiceProviderImage,
  fetchServiceProviderImageIds,
} from '../../../api-client/lib/routes/serviceProviders'

const MAX_IMAGES_COUNT = 4

export const ServiceProviderIntro = ({
  serviceProvider,
}: {
  serviceProvider?: ServiceProviderResponseDto
}) => {
  const { formatMessage } = useIntl()

  const { data: photos = [] } = useQuery(
    ['service-provider-photos', [serviceProvider?.id]],
    async () => {
      if (serviceProvider) {
        const fullImageIds = await fetchServiceProviderImageIds(serviceProvider.id)
        const imagesToFetch = fullImageIds.slice(0, MAX_IMAGES_COUNT)
        const imagePromises = imagesToFetch.map((imageIdPath) => {
          const imageId = imageIdPath.split('/').pop()
          return fetchServiceProviderImage(serviceProvider.id, imageId!)
        })
        const imageBlobs = await Promise.all(imagePromises)
        const imageUrls = imageBlobs.map((blob) => URL.createObjectURL(blob))
        return imageUrls
      }
      return []
    },
    {
      enabled: !!serviceProvider?.id,
    }
  )

  if (!serviceProvider) {
    return null
  }

  return (
    <Flex direction="column" mb={4}>
      <Flex>
        <Heading as="h3" pb={2}>
          {formatMessage({ id: `${serviceProvider.localizationKey}.name` })}
        </Heading>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <MoreInfoPopper
          moreInfoText={formatMessage({ id: `${serviceProvider.localizationKey}.aboutUs` })}
          moreInfoLinkText={formatMessage({ id: 'word.readMore' })}
          triggerButtonProps={{
            color: 'black',
          }}
        />
        <Flex flexWrap="wrap" gap={2} maxWidth="150px">
          {serviceProvider.tags.map((tag) => (
            <Tag
              bg="paleBlue.400"
              key={tag}
              height="26px"
              alignItems="center"
              display="flex"
              fontWeight="400"
              fontSize="14px"
              textTransform="none"
              borderRadius="full"
              p={2}
            >
              {formatMessage({ id: tag })}
            </Tag>
          ))}
        </Flex>
      </Flex>
      <Grid
        gap={2}
        gridTemplateColumns={`repeat(${photos.length}, calc(25% - 6px))`}
        margin="auto"
        py={4}
      >
        {photos.map((photo) => (
          <GridItem key={photo}>
            <ImageViewer
              src={photo}
              imageInactiveProps={{ borderRadius: '20px' }}
              imageUrls={photos}
              previewMode={ImagePreviewMode.modal}
            />
          </GridItem>
        ))}
      </Grid>
    </Flex>
  )
}
