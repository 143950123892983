import type { PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js'
import type {
  CategoryLeadTime,
  CategoryType,
  CreateItemDto,
  DiscountResponseDto,
  OrderContext,
  OrderResponseWithItems,
  OrderState,
  OrderType,
  PlaceOrderRequestDto,
  ProductResponseDto,
  StorageItemType,
  SupportedCountries,
} from '@wanda-space/types'

export type SelectedOrderType = {
  id: string
  type: OrderType
  state: OrderState
  storageItemIds: string[]
  orderDetails: OrderDetailsDto
  orderLines: OrderLineWithFullProductAndDiscount[]
}

export type OrderlineInOrderResponse = OrderResponseWithItems['orderLines'][number]

export interface ListingServiceFees {
  buySideCut: number
  sellSideCut: number
  sellSideMinimum: number
}

export interface CategoryRange {
  from: number
  to: number
  type: StorageItemType
}

export interface Category {
  type: CategoryType
  leadTimeDays: CategoryLeadTime
  ranges: CategoryRange[]
  minimumStorageMonths?: number
}

export type OrderLinePayload = NonNullable<PlaceOrderRequestDto['orderLines']>[number]
export type OrderDetailsDto = PlaceOrderRequestDto['orderDetails']

export interface OrderlineDiscount {
  discountAmount: number
  description: string | null
}

export type OrderLineWithFullProductAndDiscount = Pick<
  OrderLinePayload,
  'quantity' | 'comment' | 'accountId'
> & {
  item?: {
    id: string
    name: string
    type: StorageItemType
  }
  product: Product
  discount?: OrderlineDiscount
}

export interface Product extends ProductResponseDto {
  discount?: DiscountResponseDto
  originalPrice?: number
}

export interface ProductCollection {
  products: ProductResponseDto[]
  userProducts: Product[]
  itemProducts: Product[]
  storageProducts: Product[]
  serviceProducts: Product[]
  baseStorageProductByItemType: Record<StorageItemType, Product>
  addOnProducts: Product[]
  storageProductsByCategoryId: Record<string, Product[]>
  productsByCategoryId: Record<string, Product[]>
  productsById: Record<string, Product>
  timeslotsProducts: Record<string, Product>
  packagingProducts: Product[]
  shippingProducts: Product[]
}

export interface ServiceLevelsOrderlinesResponse {
  orderline: OrderLineForNewItems
}

export interface OrderLineForNewItems {
  carrying: OrderLineWithFullProductAndDiscount[]
  curbside: OrderLineWithFullProductAndDiscount[]
  firstDoor: OrderLineWithFullProductAndDiscount[]
}

// TODO: Remove this when we have a proper type for the response
export interface ServiceLevelsOrderlines {
  storageItems: CreateItemDto[]
  countryCode: SupportedCountries
  orderType: OrderType
  floorNumber?: number
  elevator: boolean
  orderContext?: OrderContext
}

export enum InvoiceDtoStatus {
  OPEN = 'open',
  DRAFT = 'draft',
  PAID = 'paid',
  UNCOLLECTIBLE = 'uncollectible',
  VOID = 'void',
}

export interface InvoiceDto {
  id: string
  invoiceNumber: string
  dueDate?: number | null
  total: number
  amountRemaining: number
  upcoming: boolean
  url: string
  status: InvoiceDtoStatus
}

export interface CouponDto {
  id: string
  valid: boolean
  amountOff: number
  percentOff: number
}

export interface DateAndTime {
  timeslot?: { from: string; to: string; productId?: string }
  date?: string
}

export interface CustomerPaymentMethod extends StripePaymentMethod {
  default: boolean
}
