import { useQuery } from '@tanstack/react-query'

import type { SupportedCities } from '@wanda-space/types'
import { getWarehouseForVisit } from 'api-client/lib/routes/warehouses'

export function useWarehouseForVisit(
  params: { serviceArea: SupportedCities },
  options?: { refetchOnMount?: boolean; enabled?: boolean; suspense?: boolean }
) {
  return useQuery(
    ['warehouse-visit', params],
    async () => {
      const response = await getWarehouseForVisit({
        serviceArea: params.serviceArea,
      })
      return response
    },
    { staleTime: 1000 * 60 * 60, ...options }
  )
}
