import { useQueries } from '@tanstack/react-query'
import {
  type DiscountResponseDto,
  type ListingAddonServiceOrderlinesRequest,
  type ListingAddonServiceOrderlinesResponse,
  type ListingAddonType,
  ProductTarget,
  type ProductType,
  type SortOrderOptionsType,
  type SupportedCountries,
} from '@wanda-space/types'
import type { Product } from 'api-client'
import { fetchDiscounts } from 'api-client/lib/routes/discount'
import { fetchListingAddonServiceOrderlines } from 'api-client/lib/routes/serviceOrders'
import { useMemo } from 'react'
import { mapProductToDiscountedProduct } from 'utils'
import { useAppSelector } from './useAppSelector'

const getProductsWithDiscount = (
  discounts: DiscountResponseDto[],
  listingAddonOrderlinesResponse: ListingAddonServiceOrderlinesResponse
): Partial<ListingAddonServiceOrderlinesResponse> => {
  const decoratedResponse: Partial<ListingAddonServiceOrderlinesResponse> = {}

  for (const [key, value] of Object.entries(listingAddonOrderlinesResponse)) {
    if (value) {
      decoratedResponse[key as ListingAddonType] = value.map((orderline) => ({
        ...orderline,
        product: mapProductToDiscountedProduct(orderline.product, discounts),
      }))
    }
  }
  return decoratedResponse
}
/**
 * The response from the BE is a bit complex and unsuitable for the FE's needs,
 * so we normalize it to a more suitable structure.
 */
interface listingAddonOrderlinesNormalized {
  /** The total number of relevant addon products */
  count: number
  /** We need to keep hold on the localization keys for relevant addons, easily accessible for lookup */
  addonLocalizationKeys: Partial<Record<ListingAddonType, string>>
  /** A map of relevant addon Products, only those with target === ProductTarget.ORDER */
  byListingAddonType: Record<ListingAddonType, Product> | Record<string, never>
  /** Sorted array (by sortOrder) of relevant addon Products (with target === ProductTarget.ORDER) */
  asSortedArray: Product[]
  /** Accumulated prices for orderline products with same ListingAddonType, taking any discount into account */
  pricesByListingAddonType: Record<string, never> | Record<ListingAddonType, number>
}

/**
 * This is a bit of a hack, but we get a datastructure from BE that isn't suited
 * for the FE's current needs. So we reduce complexity by normalizing the data
 * to a more suitable structure.
 */
const normalizeAndSort = (
  be_resp: Partial<ListingAddonServiceOrderlinesResponse>
): listingAddonOrderlinesNormalized => {
  const resp: listingAddonOrderlinesNormalized = {
    count: 0,
    addonLocalizationKeys: {},
    byListingAddonType: {},
    pricesByListingAddonType: {},
    asSortedArray: [],
  }
  Object.keys(be_resp).forEach((key) => {
    const listingAddonType = key as ListingAddonType

    const addon = be_resp?.[listingAddonType]?.find(
      ({ product }) => product.target === ProductTarget.ORDER
    )?.product as Product

    if (addon?.active) {
      resp.byListingAddonType[listingAddonType] = addon
      resp.count++
      resp.addonLocalizationKeys[listingAddonType] = addon.localizationKey

      resp.pricesByListingAddonType[listingAddonType] = 0
      be_resp[listingAddonType]?.forEach(({ product, quantity }) => {
        const discountAmount = (product as Product).discount?.amount || 0
        resp.pricesByListingAddonType[listingAddonType] +=
          (product.price - discountAmount) * quantity
      })
    }
  })

  resp.asSortedArray = Object.values(resp.byListingAddonType).sort(
    (a, b) => a.sortOrder - b.sortOrder
  )
  return resp
}

export const useListingAddonServiceOrderlines = (
  storageItems: ListingAddonServiceOrderlinesRequest['storageItems'] = [],
  options?: {
    metadataByType?: Partial<Record<ProductType, Record<string, string | number | boolean>>>
    enabled?: boolean
    sortOption?: SortOrderOptionsType
    couponCode?: string
  }
): useListingAddonsServiceOrderlinesData => {
  const { enabled = true } = options || {}
  const country = useAppSelector((state) => state.ui.country)
  const city = useAppSelector((state) => state.ui.city)

  const [listingAddonOrderlinesQuery, discountsQuery] = useQueries({
    queries: [
      {
        queryKey: ['listingAddonOrderlines', country /*, city*/],
        queryFn: async () => {
          const listingAddonOrderlines: ListingAddonServiceOrderlinesResponse =
            await fetchListingAddonServiceOrderlines({
              countryCode: country as SupportedCountries,
              storageItems: storageItems,
            })

          // Filter out empty arrays, as the BE also returns empty arrays for non-relevant addons
          return Object.fromEntries(
            Object.entries(listingAddonOrderlines ?? {}).filter(([_, value]) => value.length > 0)
          )
        },
        enabled: enabled && !!city && !!country,
      },
      {
        queryKey: ['products-with-discount', country, city, options?.couponCode],
        queryFn: async () => {
          const discounts = await fetchDiscounts({
            countryCode: country,
            city: city!,
            code: options?.couponCode ?? undefined,
          })
          return discounts
        },
        enabled: !!city && !!country,
      },
    ],
  })

  const isSuccess =
    listingAddonOrderlinesQuery.data &&
    discountsQuery.data &&
    listingAddonOrderlinesQuery.isSuccess &&
    discountsQuery.isSuccess
  const isLoading = listingAddonOrderlinesQuery.isInitialLoading || discountsQuery.isInitialLoading
  const isError = listingAddonOrderlinesQuery.isError || discountsQuery.isError
  const error = listingAddonOrderlinesQuery.error ?? discountsQuery.error

  const formattedResponse = useMemo(() => {
    if (!isSuccess) return
    const responseWithDiscounts = getProductsWithDiscount(
      discountsQuery.data,
      listingAddonOrderlinesQuery.data
    )
    return {
      normalizedResponseWithDiscount: normalizeAndSort(responseWithDiscounts),
      responseWithDiscounts,
    }
  }, [discountsQuery.data, listingAddonOrderlinesQuery.data])

  if (isSuccess && listingAddonOrderlinesQuery.data) {
    return {
      listingAddonOrderlinesNormalized: formattedResponse?.normalizedResponseWithDiscount,
      listingAddonOrderlines: formattedResponse?.responseWithDiscounts,
      isLoading,
      isError,
      isSuccess,
      error,
    }
  }
  return {
    listingAddonOrderlinesNormalized: undefined,
    isLoading,
    isError,
    isSuccess: false,
    error,
  }
}

export type useListingAddonsServiceOrderlinesData = {
  listingAddonOrderlinesNormalized?: listingAddonOrderlinesNormalized
  listingAddonOrderlines?: ListingAddonServiceOrderlinesResponse
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  error: unknown
}
