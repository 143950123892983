import { ConditionType } from '@wanda-space/types'
import { Routes } from 'consts'
import { CURRENCIES_BY_COUNTRY } from 'consts/Currency'
import { useAppSelector } from 'hooks/useAppSelector'
import { useSellableItems } from 'hooks/useSellableItems'
import React, { useRef } from 'react'
import { Navigate, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch } from 'reduxStore'
import {
  getListingFormState,
  getSelectedItemsFromStorageToSell,
} from 'reduxStore/ducks/sell/selectors'
import {
  type ListingFormValues,
  getDefaultExtraDescriptiveInfo,
  storageActions,
} from 'reduxStore/ducks/sell/sellFlow'
import { CreateListingBase } from 'routes/BuySellFlow/common/CreateListingBase'
import { toSupportedCountry } from 'utils/postalCode'

import type { Stepable } from 'interfaces'
import { selectItemsRoute } from './SellingFlowForStorage'

export const CreateListing = ({ onNextStep }: Stepable) => {
  const selectedItems = useAppSelector(getSelectedItemsFromStorageToSell)
  const country = useAppSelector((state) => state.ui.country)
  const dispatch = useAppDispatch()
  const listing = useAppSelector(getListingFormState(false))
  const navigate = useNavigate()
  const sellableItemsResponseQueryResult = useSellableItems()
  const isResetFormDispatchedRef = useRef(false)
  const [searchParams] = useSearchParams()
  const user = useAppSelector((state) => state.user.user)

  const removeSelectedItem = (id: string) => {
    const preservedItems = selectedItems.filter((item) => item.id !== id)
    dispatch(storageActions.toggleStorageItems(preservedItems))
  }

  const handleSubmit = (data: ListingFormValues) => {
    dispatch(
      storageActions.updateListingFormValues({
        ...data,
      })
    )

    onNextStep()
  }

  if (sellableItemsResponseQueryResult.isSuccess && !isResetFormDispatchedRef.current && user) {
    const { data: sellableItemsResponse } = sellableItemsResponseQueryResult

    const sellableItems = sellableItemsResponse?.items ?? []
    const selectedItemIds = searchParams.getAll('itemId')

    const selectedSellableItems = sellableItems.filter(({ id }) => selectedItemIds.includes(id))

    if (selectedSellableItems.length === 0 && selectedItems.length === 0) {
      return <Navigate to={Routes.Home} />
    }

    if (selectedSellableItems.length) {
      const countryCode =
        toSupportedCountry(selectedSellableItems[0].countryCode ?? user?.countryCode) ?? country
      const currency = CURRENCIES_BY_COUNTRY[countryCode]
      dispatch(storageActions.toggleStorageItems(selectedSellableItems))
      dispatch(
        storageActions.updateListingFormValues({
          name: '',
          description: '',
          price: {
            amount: 0,
            currency: currency,
          },
          images: [],
          showOwnerName: true,
          conditionType: ConditionType.USED,
          dimensionLength: undefined,
          dimensionHeight: undefined,
          dimensionWidth: undefined,
          dimensionFreeText: '',
          extraDescriptiveInfo: getDefaultExtraDescriptiveInfo(),
        })
      )
    }
    isResetFormDispatchedRef.current = true
  }

  const handleAddMoreItems = () => navigate(selectItemsRoute)

  const canMoreItemsBeAdded =
    (sellableItemsResponseQueryResult.data?.items.length ?? 0) > selectedItems.length

  return (
    <CreateListingBase
      isPickup={false}
      selectedItems={selectedItems}
      country={country}
      removeSelectedItem={removeSelectedItem}
      handleSubmit={handleSubmit}
      listing={listing}
      handleAddMoreItems={canMoreItemsBeAdded ? handleAddMoreItems : undefined}
    />
  )
}
