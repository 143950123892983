import type {
  AvaiableTimeslotShopBodyDto,
  AvaiableTimeslotShopResponseDto,
  PaginatedResponseDto,
  PlaceShopOrderWithOrderlines,
  PlaceShopOrderWithOrderlinesResponseDto,
  ShopOrderResponseDto,
} from '@wanda-space/types'

import { request } from '../api-client'

export async function fetchAvailableTimeslots(
  data: AvaiableTimeslotShopBodyDto
): Promise<AvaiableTimeslotShopResponseDto> {
  return request({
    method: 'POST',
    url: 'shop/available-timeslots',
    body: data,
  })
}

export async function placePackagingOrderWithOrderLines(
  payload: PlaceShopOrderWithOrderlines
): Promise<PlaceShopOrderWithOrderlinesResponseDto> {
  return request({
    method: 'POST',
    url: 'shop/placeOrder/with-orderlines',
    body: payload,
  })
}

export async function fetchPackagingOrder(id: string) {
  return request<ShopOrderResponseDto>({
    method: 'GET',
    url: `shop/orders/${id}`,
  })
}

export async function fetchPackagingOrders() {
  return request<PaginatedResponseDto<ShopOrderResponseDto>>({
    method: 'GET',
    url: 'shop/orders',
  })
}
