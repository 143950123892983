import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  type AccountId,
  type DiscountResponseDto,
  type ItemResponseDto,
  OrderType,
  type PlacedOrderResponseDto,
  type ServiceLevelType,
} from '@wanda-space/types'
import type { DateAndTime, OrderLineWithFullProductAndDiscount, Product } from 'api-client'
import type { ContactInfoData, ContactPerson } from 'components/contact-info/types'
import type { FlattenedDeliveryInfo } from 'interfaces'
import type { UserAddress } from 'interfaces/user'
import { clone } from 'ramda'
import type { RootState } from 'reduxStore'
import { mapItemToStorageOrderLine, mapUserProductToOrderLine } from 'reduxStore/commonMappers'
import { getAllOrderlinesWithDiscounts } from 'utils/product-utils'

import { SliceNames } from '../../constants'
import type { ReturnFlowState } from './types'

const initialState: ReturnFlowState = {
  orderType: OrderType.DELIVERY,
  dateAndTime: {},
  items: [],
  address: {} as ContactInfoData,
  orderLines: {
    storage: [],
    addons: [],
    taas: [],
    timeslot: [],
  },
  orderSuccessPayload: null,
}

const returnFlowSlice = createSlice({
  name: SliceNames.RETURN,
  initialState,
  reducers: {
    setOrderType: (state, { payload }: PayloadAction<OrderType>) => {
      state.orderType = payload
    },
    setDateAndTime: (state, { payload }: PayloadAction<DateAndTime>) => {
      state.dateAndTime = payload
    },
    setItems: (state, { payload }: PayloadAction<ItemResponseDto[]>) => {
      state.items = payload
      state.orderLines.storage = payload.map(mapItemToStorageOrderLine)
    },
    setTaasOrderLines: (
      state,
      { payload }: PayloadAction<OrderLineWithFullProductAndDiscount[]>
    ) => {
      state.orderLines.taas = payload
    },
    setServiceLevelType: (state, { payload }: PayloadAction<ServiceLevelType>) => {
      state.serviceLevelType = payload
    },
    updateAddress: (state, { payload }: PayloadAction<Partial<FlattenedDeliveryInfo>>) => {
      state.address = { ...state.address, ...payload }
    },
    initializeAddress: (state, { payload }: PayloadAction<UserAddress>) => {
      state.address = { ...state.address, ...payload }
    },
    setOrderSuccessPayload: (state, { payload }: PayloadAction<PlacedOrderResponseDto>) => {
      state.orderSuccessPayload = payload
    },
    setContactPerson: (state, { payload }: PayloadAction<ContactPerson>) => {
      state.contactPerson = payload
    },
    resetAll: () => {
      return clone(initialState)
    },
    addAddons: (state, action: PayloadAction<{ product: Product; accountId: AccountId }>) => {
      state.orderLines.addons = [
        mapUserProductToOrderLine(action.payload.product, action.payload.accountId),
      ]
    },
    removeAddon: (state, action: PayloadAction<{ product: Product }>) => {
      state.orderLines.addons = state.orderLines.addons.filter(
        (a) => a.product.id !== action.payload.product.id
      )
    },
    setTimeslotOrderlinesReturn: (
      state,
      action: PayloadAction<OrderLineWithFullProductAndDiscount[]>
    ) => {
      state.orderLines.timeslot = action.payload
    },
    applyDiscounts: (
      state,
      action: PayloadAction<{ coupon?: string; discounts: DiscountResponseDto[] }>
    ) => {
      if (action.payload.coupon) {
        state.coupon = action.payload.coupon
      }

      const { addons, storage, taas, timeslot } = getAllOrderlinesWithDiscounts(
        state.orderLines.addons,
        [],
        state.orderLines.storage,
        state.orderLines.taas,
        state.orderLines.timeslot,
        [],
        action.payload.discounts
      )
      state.orderLines = { addons, storage, taas, timeslot }
    },
  },
})

export const {
  setOrderType,
  setItems,
  setServiceLevelType,
  setDateAndTime,
  resetAll,
  initializeAddress,
  updateAddress,
  addAddons,
  removeAddon,
  setOrderSuccessPayload,
  setContactPerson,
  setTaasOrderLines,
  setTimeslotOrderlinesReturn,
  applyDiscounts,
} = returnFlowSlice.actions

export const serviceLevelTypeSelector = (state: RootState) => state.returnFlow.serviceLevelType
export const dateTimeSelector = (state: RootState) => state.returnFlow.dateAndTime
export const flowState = (state: RootState) => state.returnFlow
export const currentUserAddressSelector = (state: RootState) => state.returnFlow.address
export const orderSuccessPayloadSelector = (state: RootState) =>
  state.returnFlow.orderSuccessPayload
export const orderContactPersonSelector = (state: RootState) => state.returnFlow.contactPerson

export const returnFlow = returnFlowSlice.reducer
