import { Box, Button, Container, Text } from '@chakra-ui/react'
import { CallOut, ItemPicker, StorageItemType } from '@wanda-space/noelle'
import type {
  CategoryItemCounter,
  Item,
  ItemPickerCategory,
  ItemPickerData,
} from '@wanda-space/noelle'
import type { ItemResponseDto } from '@wanda-space/types'
import { Routes } from 'consts/Routes'
import { useAuth } from 'hooks/useAuth'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import type { UseProductsAndCategoriesData } from 'hooks/useProductsAndCategories'
import { useTaasCategories } from 'hooks/useTaasCategories'
import React, { useEffect, useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { Outlet } from 'react-router-dom'
import type { ItemPayloadWithProduct } from 'reduxStore/commonMappers'
import { countByItemType, generateCounterFromItems, generateItemsAndOrderLines } from 'utils/item'
import { thresholdMetMap } from 'utils/product-utils'

import { type GetProductDescription, formatCategory, formatProducts } from './helper'
export interface Props {
  selectedItems: ItemPayloadWithProduct[]
  onSelect: (items: ItemPayloadWithProduct[], counter?: CategoryItemCounter) => void
  selectedExistingItems?: ItemResponseDto[]
  bookedItems?: ItemPayloadWithProduct[]
  onSubmit: () => void
  productsAndCategories: UseProductsAndCategoriesData
  isLoading?: boolean
  MaxCapcityReachedCallout?: ReactElement
  hideSelectExistingItem?: boolean
  hasPaymentCommitment?: boolean
  getProductDescription: GetProductDescription
}
const ItemSelector = ({
  selectedItems,
  selectedExistingItems,
  onSelect,
  onSubmit,
  productsAndCategories,
  isLoading,
  bookedItems,
  hideSelectExistingItem = false,
  MaxCapcityReachedCallout,
  hasPaymentCommitment = true,
  getProductDescription,
}: Props) => {
  const { formatMessage, messages } = useIntl()
  const { data: featureFlags } = useFeatureFlags()
  const { isAuthenticated } = useAuth()
  const [counter, setCounter] = useState<CategoryItemCounter | undefined>(
    selectedItems.length > 0 ? generateCounterFromItems(selectedItems, formatMessage) : undefined
  )
  const [commitmentMsgVisible, setCommitmentMsgVisible] = useState<boolean>(false)
  const {
    storageProductsByCategoryId,
    productCategories,
    isSuccess,
    productsById,
    isError,
    error,
  } = productsAndCategories
  const taasCategories = useTaasCategories()
  const isApiCallSuccess = isSuccess && taasCategories.isSuccess
  const totalCount = (selectedExistingItems?.length ?? 0) + selectedItems.length
  const thresholdMap = thresholdMetMap(
    countByItemType([...selectedItems, ...(selectedExistingItems ?? []), ...(bookedItems ?? [])]),
    taasCategories.data?.find((t) => t.leadTimeDays === 'ASK')?.ranges
  )

  const data: ItemPickerData = {
    standard: {
      title: formatMessage({ id: 'word.standard' }),
      categories: [] as ItemPickerCategory[],
    },
    moreOptions: {
      title: formatMessage({ id: 'word.moreOptions' }),
      categories: [] as ItemPickerCategory[],
    },
  }
  if (isSuccess) {
    // format products and categories
    productCategories.forEach((category) => {
      const formattedProducts = formatProducts({
        products: storageProductsByCategoryId[category.id] ?? [],
        categoryId: category.id,
        formatMessage,
        messages,
        getProductDescription,
      }) as Item[] // TODO Update type in Noelle to picker item
      if (formattedProducts.length > 0) {
        if (data.standard.categories.length < 6) {
          data.standard.categories.push({
            ...formatCategory({ category, formatMessage, messages }),
            items: formattedProducts,
          })
        } else {
          data.moreOptions.categories.push({
            ...formatCategory({ category, formatMessage, messages }),
            items: formattedProducts,
          })
        }
      }
    })
  }

  useEffect(() => {
    const { SMALL, LARGE, BOX } = countByItemType([
      ...selectedItems,
      ...(selectedExistingItems ?? []),
    ])
    const smallAndBox = SMALL + BOX

    const shouldShowMessage = taasCategories.data?.some((cat) => {
      return (
        cat.minimumStorageMonths &&
        cat.ranges.some((range) => {
          return (
            (range.type === StorageItemType.LARGE && LARGE && LARGE >= range.from) ||
            (range.type === StorageItemType.SMALL && smallAndBox && smallAndBox >= range.from)
          )
        })
      )
    })
    setCommitmentMsgVisible(shouldShowMessage!)
  }, [featureFlags, selectedExistingItems, selectedItems])

  const updateCounter = (counter: CategoryItemCounter) => {
    setCounter(counter)

    if (productsById) {
      const { pickerItems } = generateItemsAndOrderLines(counter, productsById)
      onSelect(pickerItems, counter)
    }
  }

  if (isError) {
    throw error
  }

  return (
    <Container p="0">
      {isApiCallSuccess &&
        (data.standard.categories.length === 0 && data.moreOptions.categories.length === 0 ? (
          <Box mt="5" borderRadius="md" bg="gray.200" p="5">
            {formatMessage({ id: 'select.items.categories.missing' })}
          </Box>
        ) : (
          <Box>
            <ItemPicker
              counter={counter}
              data={data}
              addMoreBtnText={formatMessage({ id: 'word.addMore' })}
              countUnitText={formatMessage({ id: 'short.pieces' })}
              onUpdate={updateCounter}
              thresholdMap={thresholdMap}
              thresholdMessage={`${formatMessage({
                id: 'word.taasReachedMaximum',
              })}. ${formatMessage({ id: 'word.taasLimitAmount' })}`}
              existingItems={
                hideSelectExistingItem
                  ? undefined
                  : {
                      path: Routes.StorageSelectExistingItems + location.search,
                      count: selectedExistingItems?.length ?? 0,
                      title: isAuthenticated
                        ? undefined
                        : formatMessage({ id: 'word.loginTo' }).toUpperCase(),
                    }
              }
              ariaLabelIncrease="increase"
              ariaLabelDecrease="decrease"
            />
            {commitmentMsgVisible && hasPaymentCommitment && (
              <Box mb={2}>
                <CallOut
                  title={formatMessage({ id: 'payment.commitment.title' })}
                  text={formatMessage({ id: 'payment.commitment.text' })}
                />
              </Box>
            )}

            {MaxCapcityReachedCallout}
            <Text fontSize="sm" marginY={4} paddingX={5}>
              {formatMessage({ id: 'booking.selectItems.description' })}
            </Text>
            <Button
              isDisabled={totalCount === 0 || isLoading}
              mt="2"
              width="100%"
              variant="solid"
              colorScheme="ctaBlack"
              onClick={() => {
                onSubmit()
              }}
              size="lg"
              isLoading={isLoading}
              data-testid="continue-button"
            >
              {formatMessage({ id: 'phrase.continueWithThings' }, { totalCount })}
            </Button>
          </Box>
        ))}
      <Outlet />
    </Container>
  )
}
export { ItemSelector }
