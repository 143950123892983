import { useQuery } from '@tanstack/react-query'

import { getTranslations } from '../api-client/lib/routes/translations'
import { mapLocaleToShortString } from '../i18n'
import { useAppSelector } from './useAppSelector'

export const useTranslations = () => {
  const locale = useAppSelector((state) => state.ui.language)
  const languageShortString = mapLocaleToShortString(locale ?? 'no')
  return useQuery(['translations', locale], () => getTranslations(languageShortString), {
    suspense: true,
  })
}
