import { useQueries } from '@tanstack/react-query'
import type {
  ProductCategoryResponseDto,
  ProductType,
  SortOrderOptionsType,
} from '@wanda-space/types'
import type { ProductCollection } from 'api-client'
import { fetchDiscounts } from 'api-client/lib/routes/discount'
import { fetchProducts, getProductCategoriesByCountry } from 'api-client/lib/routes/product'
import { indexBy } from 'ramda'
import { useMemo } from 'react'

import { getProductCollection, getProductsWithDiscount } from '../utils/product-utils'
import { useAppSelector } from './useAppSelector'

export const useProductsAndCategories = (options?: {
  metadataByType?: Partial<Record<ProductType, Record<string, string | number | boolean>>>
  enabled?: boolean
  sortOption?: SortOrderOptionsType
  couponCode?: string
}): UseProductsAndCategoriesData => {
  const { metadataByType, enabled = true, sortOption } = options || {}
  const country = useAppSelector((state) => state.ui.country)
  const city = useAppSelector((state) => state.ui.city)

  const [productsQuery, discountsQuery, categoryQuery] = useQueries({
    queries: [
      {
        queryKey: ['products', country, city],
        queryFn: async () => {
          const products = await fetchProducts(country, city, {
            page: 0,
            itemsPerPage: 2000,
          })
          return products ?? []
        },
        enabled: enabled && !!city && !!country,
      },

      {
        queryKey: ['products-with-discount', country, city, options?.couponCode],
        queryFn: async () => {
          const discounts = await fetchDiscounts({
            countryCode: country,
            city: city!,
            code: options?.couponCode ?? undefined,
          })
          return discounts
        },
        enabled: !!city && !!country,
      },

      {
        queryKey: ['product-categories', country],
        queryFn: async () =>
          getProductCategoriesByCountry(country, {
            itemsPerPage: 1000,
          }),
        enabled: !!country,
      },
    ],
  })

  const categoryCollection = useMemo(() => {
    let productCategories = categoryQuery.data?.items ?? []
    if (sortOption) {
      productCategories = productCategories.sort(
        (a, b) => (a.sortOrderOptions[sortOption] || 1) - (b.sortOrderOptions[sortOption] || 1)
      )

      productCategories = productCategories.filter((category) => {
        const sortOrder = category.sortOrderOptions[sortOption]
        return sortOrder && sortOrder > 0
      })
    }

    const productCategoriesById = indexBy(({ id }) => id, productCategories)
    return { productCategories, productCategoriesById }
  }, [categoryQuery.data])

  const productCollection = useMemo(() => {
    if (!productsQuery.data || !discountsQuery.data) {
      return
    }

    const discountedProducts = getProductsWithDiscount(productsQuery.data, discountsQuery.data)
    return getProductCollection(discountedProducts, metadataByType)
  }, [productsQuery.data, discountsQuery.data, metadataByType])

  const isSuccess = categoryQuery.isSuccess && productsQuery.isSuccess
  const isLoading =
    productsQuery.isInitialLoading ||
    categoryQuery.isInitialLoading ||
    discountsQuery.isInitialLoading
  const isError = productsQuery.isError || categoryQuery.isError
  const error = productsQuery.error ?? categoryQuery.error

  if (isSuccess && categoryQuery.data && productCollection) {
    return {
      ...categoryCollection,
      ...productCollection,
      isLoading,
      isError,
      isSuccess,
      error,
    }
  }
  return {
    isLoading,
    isError,
    isSuccess: false,
    error,
  }
}

export type UseProductsAndCategoriesData =
  | (ProductCollection & {
      productCategories: ProductCategoryResponseDto[]
      productCategoriesById: Record<string, ProductCategoryResponseDto>
      isLoading: boolean
      isError: boolean
      isSuccess: true
      error: unknown
    })
  | {
      products?: undefined
      userProducts?: undefined
      itemProducts?: undefined
      storageProducts?: undefined
      serviceProducts?: undefined
      addOnProducts?: undefined
      timeslotsProducts?: undefined
      packagingProducts?: undefined
      shippingProducts?: undefined
      baseStorageProductByItemType?: undefined
      productsByCategoryId?: undefined
      storageProductsByCategoryId?: undefined
      productsById?: undefined
      productCategories?: undefined
      productCategoriesById?: undefined
      isLoading: boolean
      isError: boolean
      isSuccess: false
      error: unknown
    }
