import type { CategoryItemCounter } from '@wanda-space/noelle'
import {
  type ProductCategoryResponseDto,
  StorageItemType,
  SupportedCurrencies,
} from '@wanda-space/types'
import type { Product } from 'api-client/lib/types'
import type { PriceWrapper } from 'interfaces'
import type { IntlFormatters, MessageFormatElement } from 'react-intl'
import { type FormatMessage, productToPrice } from 'utils'

export type GetProductDescriptionArgs = {
  product: Product
  formatMessage: IntlFormatters['formatMessage']
  messages: Record<string, string> | Record<string, MessageFormatElement[]>
}

export type GetProductDescription = (args: GetProductDescriptionArgs) => string

export const getCounts = (counter: CategoryItemCounter | undefined) => {
  let totalCount = 0
  const sizes = {
    [StorageItemType.BOX]: 0,
    [StorageItemType.SMALL]: 0,
    [StorageItemType.LARGE]: 0,
  }

  if (counter) {
    for (const categoryId in counter) {
      if (Object.prototype.hasOwnProperty.call(counter, categoryId)) {
        const category = counter[categoryId]
        for (const productId in category) {
          const categoryProductCount = category[productId].count as number
          totalCount += categoryProductCount

          if (category[productId].itemType === StorageItemType.BOX) {
            sizes[StorageItemType.BOX] += categoryProductCount
          } else if (category[productId].itemType === StorageItemType.SMALL) {
            sizes[StorageItemType.SMALL] += categoryProductCount
          } else if (category[productId].itemType === StorageItemType.LARGE) {
            sizes[StorageItemType.LARGE] += categoryProductCount
          }
        }
      }
    }
  }

  return { totalCount, sizes }
}

export const formatProducts = ({
  products,
  formatMessage,
  messages,
  getProductDescription,
}: {
  products: Product[]
  categoryId: string
  formatMessage: FormatMessage
  messages: Record<string, string> | Record<string, MessageFormatElement[]>
  getProductDescription: GetProductDescription
}) => {
  return products.map((product) => {
    let itemType = ''
    const metadata = product.metadata
    const moreInfo = product.discount?.description

    if (metadata) {
      itemType = metadata.itemType as string
    }

    const nameKey = `${product.localizationKey}.name`
    const description = getProductDescription({ messages, formatMessage, product })

    return {
      id: product.id,
      name: formatMessage({ id: nameKey }),
      itemType,
      description,
      moreInfo,
    }
  })
}

export const formatCategory = ({
  category: { id, localizationKey, iconName },
  formatMessage,
  messages,
}: {
  category: ProductCategoryResponseDto
  formatMessage: FormatMessage
  messages: Record<string, string> | Record<string, MessageFormatElement[]>
}) => {
  const nameKey = `${localizationKey}.name`
  const descriptionKey = `${localizationKey}.description`
  const moreInfoKey = `${localizationKey}.moreInfo`

  let description: string | undefined
  let moreInfo: string | undefined

  if (messages[descriptionKey]) {
    description = formatMessage({ id: descriptionKey })
  }

  if (messages[moreInfoKey]) {
    moreInfo = formatMessage({ id: moreInfoKey })
  }

  return {
    id,
    name: formatMessage({ id: nameKey }),
    icon: iconName as string,
    description,
    moreInfo,
  }
}

export const getProductDescriptionForStorage: GetProductDescription = (args) => {
  const { product, formatMessage, messages } = args
  const mainPartKey = `${product.localizationKey}.description`
  const mainPart = messages[mainPartKey] ? formatMessage({ id: mainPartKey }) : ''
  let price: PriceWrapper = { amount: 0, currency: SupportedCurrencies.NOK }
  price = productToPrice(product)
  const priceText = `${price.amount} ${price.currency}`
  const suffixKey = 'storage.product.description.suffix'
  const suffix = messages[suffixKey]
    ? formatMessage({ id: suffixKey }, { price: priceText })
    : `${priceText}/${formatMessage({ id: 'word.monthShort' })}`

  if (mainPart) {
    return `${mainPart}, ${suffix}`
  }

  return suffix
}
