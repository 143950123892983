import { Box, Button, Center, Flex, Heading, Link, Text } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { Spinner } from '@wanda-space/noelle'
import WandaLogoLongIcon from '@wanda-space/noelle/illustrations/wanda+space.svg'
import type { PostalCodeDto } from '@wanda-space/ops-types'
import { AuthForm } from 'components/auth-page-components/AuthForm'
import type { AuthData } from 'components/auth-page-components/AuthForm'
import { RocketImage } from 'components/auth-page-components/RocketImage'
import { Routes } from 'consts'
import type { FormikHelpers } from 'formik'
import { useDocumentTitle } from 'hooks'
import { useActiveUserLocation } from 'hooks/useActiveUserLocation'
import { type UserSignupDetails, useAuth } from 'hooks/useAuth'
import { usePostalCodes } from 'hooks/usePostalCodes'
import { isNil } from 'ramda'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { linkFormatter } from 'utils/linkFormatter'

interface Proptypes {
  loginButtonHandler?: () => void
  hasHeroImage?: boolean
  userSignupDetails?: UserSignupDetails
}

export const RegisterForm = ({
  loginButtonHandler,
  hasHeroImage = true,
  userSignupDetails,
}: Proptypes) => {
  const { formatMessage } = useIntl()
  const [socialAuthError, setSocialAuthError] = useState<string>()
  const [authError, setAuthError] = useState<string>()
  const { register, registerSocial } = useAuth()
  const navigate = useNavigate()
  const { postalCode } = useActiveUserLocation()
  const _registerSocial = (postalCode: PostalCodeDto) =>
    registerSocial('google-oauth2', { postalCode })
  const {
    data: postalCodes,
    isLoading: isPostCodesLoading,
    error: postalCodeError,
  } = usePostalCodes({ postalCode, enableCondition: !!postalCode })

  const { mutate: registerMutate, isLoading } = useMutation(register)
  const { mutate: registerSocialMutate, isLoading: isLoadingSocial } = useMutation(_registerSocial)

  useDocumentTitle(formatMessage({ id: 'word.register' }))

  if (postalCodeError) {
    throw postalCodeError
  }

  if (isPostCodesLoading || !postalCodes) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  const validPostalCode = postalCodes.find((i) => i.postalCode === postalCode)

  if (isNil(validPostalCode) && postalCode) {
    setAuthError(formatMessage({ id: 'postal.empty' }))
  }

  const handleSubmit = (
    { email, password }: AuthData,
    { setFieldError }: FormikHelpers<AuthData>
  ) => {
    if (isNil(validPostalCode)) {
      return setAuthError(formatMessage({ id: 'postal.empty' }))
    }

    registerMutate(
      {
        email,
        password,
        postalCode: validPostalCode,
      },
      {
        onError: (err: any) => {
          if (err.code === 'user_exists') {
            setFieldError('email', formatMessage({ id: 'word.existingUser' }))
          } else {
            setAuthError(err.message || err.description || err.privacy)
          }
        },
      }
    )
  }

  const handleGoogleSignup = () => {
    if (isNil(validPostalCode)) {
      return setAuthError(formatMessage({ id: 'postal.empty' }))
    }

    registerSocialMutate(validPostalCode, {
      onError: (err: any) => {
        setSocialAuthError(err.message || err.description || err.privacy)
      },
    })
  }

  return (
    <Flex width="100%" gap="10" px={hasHeroImage ? '8' : 0} py="0">
      <Box width={hasHeroImage ? ['100%', '100%', '50%'] : '100%'}>
        <Flex alignItems="center" justifyContent="space-between" height="10" mb={6}>
          <Heading as="h2" fontWeight="medium" fontSize="2xl">
            {formatMessage({ id: 'create.account' })}
          </Heading>
          <img alt="wanda logo long" src={WandaLogoLongIcon} width="115px" height="auto" />
        </Flex>
        <AuthForm
          context="register"
          isLoading={isLoading}
          isLoadingSocial={isLoadingSocial}
          socialAuthError={socialAuthError}
          authError={authError}
          submitBtnLokalizeId="create.account"
          onSocialClick={handleGoogleSignup}
          onAuthFormSubmit={handleSubmit}
          userSignpDetails={
            userSignupDetails
              ? {
                  ...userSignupDetails,
                  postalCode: userSignupDetails?.postalCode ?? validPostalCode?.postalCode,
                }
              : undefined
          }
        />

        <Flex direction="row" alignItems="center" gap={2} my={8} justifyContent="center">
          <Text fontWeight="500" fontSize="medium" color="ctaBlack.800">
            {formatMessage({ id: 'word.alreadyHaveAccount' })}
          </Text>
          <Button
            data-testid="goto-login-button"
            variant="link"
            as={Link}
            onClick={loginButtonHandler ? loginButtonHandler : () => navigate(Routes.Login)}
            size="small"
            textDecoration="underline"
            color="purple.700"
            fontWeight="500"
            p={0}
          >
            {formatMessage({ id: 'word.login' })}
          </Button>
        </Flex>
        <Text textAlign="center" color="blackAlpha.500" mt="7" px="5">
          <Text mr="1" as="span">
            {formatMessage({ id: 'register.terms' })}
          </Text>
          <Link
            textDecoration="underline"
            href={linkFormatter(formatMessage, { navigation: 'terms' })}
            rel="noopener noreferrer"
            target="_blank"
          >
            {formatMessage({ id: 'word.termsAndConditions' }).toLowerCase()}
          </Link>
          <Text mx="1" as="span">
            {formatMessage({ id: 'word.and' }).toLowerCase()}
          </Text>
          <Link
            textDecoration="underline"
            href={linkFormatter(formatMessage, { navigation: 'privacy' })}
            rel="noopener noreferrer"
            target="_blank"
          >
            {formatMessage({ id: 'word.privacyPolicy' }).toLowerCase()}
          </Link>
        </Text>
      </Box>
      {hasHeroImage && <RocketImage />}
    </Flex>
  )
}
